import * as React from "react";
import { I_CONTEXTUAL_MENU_PROPS } from "../../02_Interfaces/ICONTEXTUAL_MENU";

import "../../99_Styles/contextualMenu.css";
import { GetIconFromType } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";

export class CONTEXTUEAL_MENU_ITEM extends React.Component<I_CONTEXTUAL_MENU_PROPS, {}> {
    render(): JSX.Element {
        return <span className="CM_ZONE">

            {
                this.props.menus
                    .filter(m => !m.subMenu && m.description !== undefined)
                    .map((m, index) => {
                        return <span title={m.description !== undefined ? m.description : undefined} key={index} className="CM_ZONE_ICON" dangerouslySetInnerHTML={{ __html: m.icon }} onClick={() => { m.F_Menu_Action() }}>
                        </span>
                    })
            }


            {
                this.props.menus
                    .filter(m => m.subMenu && m.description !== undefined).length > 0 &&
                <span className="CM_SUBMENU_ZONE">
                    <span>&nbsp;</span>
                    <span className="CM_ZONE_ICON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.menuPlus, 16) }}></span>
                    <div className="CM_SUBMENU_BOX">
                        {
                            this.props.menus
                                .filter(m => m.subMenu && m.description !== undefined)
                                .map((m, index) => {
                                    return <div title={m.description !== undefined ? m.description : undefined} key={index} className="CM_ZONE_ICON" style={{ fontSize: "11px", fontWeight: "bold" }} onClick={() => { m.F_Menu_Action() }}>
                                        <div>
                                            <span dangerouslySetInnerHTML={{ __html: m.icon }} ></span>
                                            <span className="SPAN_SEP"></span>
                                            <span>{m.description as string}</span>
                                        </div>
                                    </div>
                                })
                        }
                    </div>
                </span>
            }


        </span>
    }
}