import * as React from 'react';
import { I_CUST_FORM, I_CUST_FORM_ITEM, I_CUST_FORM_PROPS, I_CUST_FORM_PROPS_STATE } from '../../02_Interfaces/I_CUST_FORM';
import { GetDateStringFromObject, GetDistinctListOfString, GetFormatedDate, GetIconFromType, GetNoteCustFormData, GetNoteCustParentIdFromTitle, IsCustForm, decodeUtf8 } from '../../04_TOOLS/tools';
import { FIELD_TEXT } from '../Fields/FIELD_TEXT';
import { FIELD_CHECK_BOX } from '../Fields/FIELD_CHECK_BOX';
import { NOTE_CUST_FORM_EMPTY_PARENT, NOTE_CUST_FORM_EMPTY_TEMPLATE, NOTE_CUST_FORM_EMPTY_TITLE } from '../../01_APPDATA/DB_APP';
import { I_NOTE_ITEM, I_TASK_ITEM } from '../../02_Interfaces/INOTE';
import { Guid } from 'guid-typescript';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { ENU_ICON_TYPE } from '../../02_Interfaces/IGLOBAL';
import { data } from 'jquery';
import { I_MSG_OPTION } from '../../02_Interfaces/IMSGBOX';

export class CUST_FORM_BOX extends React.Component<I_CUST_FORM_PROPS, I_CUST_FORM_PROPS_STATE> {
    constructor(props: I_CUST_FORM_PROPS) {
        super(props);

        this.state = {
            tmpBodyContener: this.props.note.description,
            lineToDrop: undefined,
            taskToConvert: []
        }

        this.onAddField = this.onAddField.bind(this);
        this.onDeleteFormLine = this.onDeleteFormLine.bind(this);
        this.onMoveFormLine = this.onMoveFormLine.bind(this);
        this.onSelectElementToDrop = this.onSelectElementToDrop.bind(this);
        this.onControleFormTask = this.onControleFormTask.bind(this);
        this.onConvertFormToString = this.onConvertFormToString.bind(this);
    }

    private ref_rte = React.createRef<RichTextEditorComponent>();
    private EmptyFieldTemplate: string = "<p>&amp;&amp;FORM:[IDFIELD];[FIELDTYPE];[FIELDTITLE];[FIELDDESCRIPTION];[REQUIRED];[VALUES];[DEFAULTVALUE];&amp;&amp;</p>";
    private EmptyFieldDataTemplate: string = "<p>&amp;&amp;DATA:[IDFIELD];[VALUE];&amp;&amp;</p>";
    private EmptyFieldConfigTemplate: string = "<p>&amp;&amp;CONFIG:[IDFIELD];[VALUE_CONFIG];[ID_NOTE_ITEM];&amp;&amp;</p>";

    onSelectElementToDrop(line?: I_CUST_FORM_ITEM) {
        this.setState({ lineToDrop: line });
    }

    onAddField = (type: string) => {
        let tmpFinalFormString: string = "";
        let tmpFinalFormDataString: string = "";
        const currentItemId: string = Guid.create().toString();

        tmpFinalFormString = this.EmptyFieldTemplate
            .replace("[IDFIELD]", currentItemId)
            .replace("[FIELDTYPE]", type)
            .replace("[FIELDTITLE]", "")
            .replace("[FIELDDESCRIPTION]", "")
            .replace("[REQUIRED]", "0")
            .replace("[VALUES]", "")
            .replace("[DEFAULTVALUE]", "");

        tmpFinalFormDataString = this.EmptyFieldDataTemplate
            .replace("[IDFIELD]", currentItemId)
            .replace("[VALUE]", "");

        tmpFinalFormDataString = this.EmptyFieldConfigTemplate
            .replace("[IDFIELD]", currentItemId)
            .replace("[VALUE_CONFIG]", "0")
            .replace("[ID_NOTE_ITEM]", "");

        this.setState({ tmpBodyContener: this.state.tmpBodyContener + tmpFinalFormString + tmpFinalFormDataString }, () => {
            let tmpCurrentNote: I_NOTE_ITEM = this.props.note;
            tmpCurrentNote.description = this.state.tmpBodyContener;
            tmpCurrentNote.descriptionraw = this.state.tmpBodyContener;

            (this.props.GLOBAL_FUNCTION.updateNote as Function)(tmpCurrentNote);


            this.setState({ tmpBodyContener: this.props.note.description }, () => {

                (this.props.GLOBAL_FUNCTION.updateNote as Function)(tmpCurrentNote);
                (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
            })
        })
    }

    onDeleteFormLine = (line: I_CUST_FORM_ITEM) => {


        let tmpFormObj: I_CUST_FORM = GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote);
        let finalString: string = NOTE_CUST_FORM_EMPTY_TEMPLATE.replace("PARENTNOTEID", tmpFormObj.id.toString());

        let lines: I_CUST_FORM_ITEM[] = (tmpFormObj.lines.filter(l => l.id !== line.id));
        for (let x = 0; x < lines.length; x++) {
            let line: I_CUST_FORM_ITEM = lines[x];
            finalString = finalString + "\n" + this.EmptyFieldTemplate
                .replace("[IDFIELD]", line.id)
                .replace("[FIELDTYPE]", line.type)
                .replace("[FIELDTITLE]", line.title)
                .replace("[FIELDDESCRIPTION]", line.description !== undefined ? line.description : "")
                .replace("[REQUIRED]", line.required ? "1" : "0")
                .replace("[VALUES]", line.allProposals !== undefined ? line.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
                .replace("[DEFAULTVALUE]", line.defaultValue !== undefined ? line.defaultValue : "");

            finalString = finalString + "\n" + this.EmptyFieldDataTemplate
                .replace("[IDFIELD]", line.id)
                .replace("[VALUE]", line.value !== undefined ? line.value.toString() : line.defaultValue as string);

            finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
                .replace("[IDFIELD]", line.id)
                .replace("[VALUE_CONFIG]", line._config_value !== undefined && line._config_value.toString() !== "undefined" ? line._config_value.toString() : "0")
                .replace("[ID_NOTE_ITEM]", line._config_id_note !== undefined ? line._config_id_note.toString() : "");
        }

        // (tmpFormObj.lines.filter(l => l.id !== line.id)).forEach(line => {
        //     finalString = finalString + "\n" + this.EmptyFieldTemplate
        //         .replace("[IDFIELD]", line.id)
        //         .replace("[FIELDTYPE]", line.type)
        //         .replace("[FIELDTITLE]", line.title)
        //         .replace("[FIELDDESCRIPTION]", line.description !== undefined ? line.description : "")
        //         .replace("[REQUIRED]", line.required ? "1" : "0")
        //         .replace("[VALUES]", line.allProposals !== undefined ? line.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
        //         .replace("[DEFAULTVALUE]", line.defaultValue !== undefined ? line.defaultValue : "");

        //     finalString = finalString + "\n" + this.EmptyFieldDataTemplate
        //         .replace("[IDFIELD]", line.id)
        //         .replace("[VALUE]", line.value !== undefined ? line.value.toString() : line.defaultValue as string);

        //     finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
        //         .replace("[IDFIELD]", line.id)
        //         .replace("[VALUE_CONFIG]", line._config_value !== undefined && line._config_value.toString() !== "undefined" ? line._config_value.toString() : "0")
        //         .replace("[ID_NOTE_ITEM]", line._config_id_note !== undefined ? line._config_id_note.toString() : "");

        // });

        this.setState({ tmpBodyContener: finalString });
    }

    onMoveFormLine = (targetIndex: number) => {


        let tmpFormObj: I_CUST_FORM = GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote);
        let finalString: string = NOTE_CUST_FORM_EMPTY_TEMPLATE.replace("PARENTNOTEID", tmpFormObj.id.toString());

        if (this.state.lineToDrop !== undefined) {
            let currentline: I_CUST_FORM_ITEM = this.state.lineToDrop;

            for (let index = 0; index < tmpFormObj.lines.length; index++) {
                if (index === targetIndex) {
                    finalString = finalString + "\n" + this.EmptyFieldTemplate
                        .replace("[IDFIELD]", currentline.id)
                        .replace("[FIELDTYPE]", currentline.type)
                        .replace("[FIELDTITLE]", currentline.title)
                        .replace("[FIELDDESCRIPTION]", currentline.description !== undefined ? currentline.description : "")
                        .replace("[REQUIRED]", currentline.required ? "1" : "0")
                        .replace("[VALUES]", currentline.allProposals !== undefined ? currentline.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
                        .replace("[DEFAULTVALUE]", currentline.defaultValue !== undefined ? currentline.defaultValue : "");

                    finalString = finalString + "\n" + this.EmptyFieldDataTemplate
                        .replace("[IDFIELD]", currentline.id)
                        .replace("[VALUE]", currentline.value !== undefined ? currentline.value.toString() : currentline.defaultValue as string);

                    finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
                        .replace("[IDFIELD]", currentline.id)
                        .replace("[VALUE_CONFIG]", currentline._config_value !== undefined ? currentline._config_value.toString() !== "" ? currentline._config_value.toString() : "0" : "0")
                        .replace("[ID_NOTE_ITEM]", currentline._config_id_note !== undefined ? currentline._config_id_note.toString() : "");
                }
                if (tmpFormObj.lines[index].id !== currentline.id) {
                    finalString = finalString + "\n" + this.EmptyFieldTemplate
                        .replace("[IDFIELD]", tmpFormObj.lines[index].id)
                        .replace("[FIELDTYPE]", tmpFormObj.lines[index].type)
                        .replace("[FIELDTITLE]", tmpFormObj.lines[index].title)
                        .replace("[FIELDDESCRIPTION]", tmpFormObj.lines[index].description as string)
                        .replace("[REQUIRED]", tmpFormObj.lines[index].required ? "1" : "0")
                        .replace("[VALUES]", (tmpFormObj.lines[index].allProposals as string[]).filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|"))
                        .replace("[DEFAULTVALUE]", tmpFormObj.lines[index].defaultValue as string);

                    finalString = finalString + "\n" + this.EmptyFieldDataTemplate
                        .replace("[IDFIELD]", tmpFormObj.lines[index].id)
                        .replace("[VALUE]", tmpFormObj.lines[index].value !== undefined ? tmpFormObj.lines[index].value.toString() : tmpFormObj.lines[index].defaultValue as string);

                    finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
                        .replace("[IDFIELD]", tmpFormObj.lines[index].id)
                        .replace("[VALUE_CONFIG]", tmpFormObj.lines[index]._config_value !== undefined && tmpFormObj.lines[index]._config_value.toString() !== "undefined" ? tmpFormObj.lines[index]._config_value.toString() : "0")
                        .replace("[ID_NOTE_ITEM]", tmpFormObj.lines[index]._config_id_note !== undefined ? tmpFormObj.lines[index]._config_id_note.toString() : "");
                }

            }


        }

        this.setState({ tmpBodyContener: finalString }, () => {

            this.onSelectElementToDrop(undefined);
        });
    }

    onConvertFormToString = (updateNote: boolean, lineToUpdate?: I_CUST_FORM_ITEM) => {
        let tmpFormObj: I_CUST_FORM = GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote);

        let finalString: string = NOTE_CUST_FORM_EMPTY_TEMPLATE.replace("PARENTNOTEID", tmpFormObj.id.toString());

        //Ajout des champs et des données
        let lines: I_CUST_FORM_ITEM[] = tmpFormObj.lines;
        for (let x = 0; x < lines.length; x++) {
            let line: I_CUST_FORM_ITEM = lines[x];
            if (lineToUpdate === undefined || (lineToUpdate !== undefined && lineToUpdate.id !== line.id)) {
                // console.log("ici ça marche aussi");
                finalString = finalString + "\n" + this.EmptyFieldTemplate
                    .replace("[IDFIELD]", line.id)
                    .replace("[FIELDTYPE]", line.type)
                    .replace("[FIELDTITLE]", line.title)
                    .replace("[FIELDDESCRIPTION]", line.description !== undefined ? line.description : "")
                    .replace("[REQUIRED]", line.required ? "1" : "0")
                    .replace("[VALUES]", line.allProposals !== undefined ? line.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
                    .replace("[DEFAULTVALUE]", line.defaultValue !== undefined ? line.defaultValue : "");

                finalString = finalString + "\n" + this.EmptyFieldDataTemplate
                    .replace("[IDFIELD]", line.id)
                    .replace("[VALUE]", line.value !== undefined && (line.value as string).length > 0 ? line.value as string : line.defaultValue !== undefined && (line.defaultValue as string).length > 0 ? line.defaultValue as string : "");

                finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
                    .replace("[IDFIELD]", line.id)
                    .replace("[VALUE_CONFIG]", line._config_value !== undefined ? line._config_value.toString() !== "" && line._config_value.toString() !== "undefined" ? line._config_value.toString() : "0" : "0")
                    .replace("[ID_NOTE_ITEM]", line._config_id_note !== undefined ? line._config_id_note.toString() : "");

            } else if (lineToUpdate !== undefined && lineToUpdate.id === line.id) {
                // console.log("ici ça marche");
                finalString = finalString + "\n" + this.EmptyFieldTemplate
                    .replace("[IDFIELD]", lineToUpdate.id)
                    .replace("[FIELDTYPE]", lineToUpdate.type)
                    .replace("[FIELDTITLE]", lineToUpdate.title)
                    .replace("[FIELDDESCRIPTION]", lineToUpdate.description !== undefined ? lineToUpdate.description : "")
                    .replace("[REQUIRED]", lineToUpdate.required ? "1" : "0")
                    .replace("[VALUES]", lineToUpdate.allProposals !== undefined ? lineToUpdate.allProposals.map(p => { return p }).toString().split(",").join("|") : "")
                    .replace("[DEFAULTVALUE]", lineToUpdate.defaultValue !== undefined ? lineToUpdate.defaultValue : "");

                finalString = finalString + "\n" + this.EmptyFieldDataTemplate
                    .replace("[IDFIELD]", lineToUpdate.id)
                    .replace("[VALUE]", lineToUpdate.value !== undefined && (lineToUpdate.value as string).length > 0 ? lineToUpdate.value : lineToUpdate.defaultValue !== undefined && (lineToUpdate.defaultValue as string).length > 0 ? lineToUpdate.defaultValue : "");

                // console.log(finalString);

                finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
                    .replace("[IDFIELD]", lineToUpdate.id)
                    .replace("[VALUE_CONFIG]", lineToUpdate._config_value !== undefined && lineToUpdate._config_value.toString() !== "undefined" ? lineToUpdate._config_value.toString() : "0")
                    .replace("[ID_NOTE_ITEM]", lineToUpdate._config_id_note !== undefined ? lineToUpdate._config_id_note.toString() : "");
            }
        }


        // (tmpFormObj.lines).forEach(line => {
        //     if (lineToUpdate === undefined || (lineToUpdate !== undefined && lineToUpdate.id !== line.id)) {
        //         // console.log("ici ça marche aussi");
        //         finalString = finalString + "\n" + this.EmptyFieldTemplate
        //             .replace("[IDFIELD]", line.id)
        //             .replace("[FIELDTYPE]", line.type)
        //             .replace("[FIELDTITLE]", line.title)
        //             .replace("[FIELDDESCRIPTION]", line.description !== undefined ? line.description : "")
        //             .replace("[REQUIRED]", line.required ? "1" : "0")
        //             .replace("[VALUES]", line.allProposals !== undefined ? line.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
        //             .replace("[DEFAULTVALUE]", line.defaultValue !== undefined ? line.defaultValue : "");

        //         finalString = finalString + "\n" + this.EmptyFieldDataTemplate
        //             .replace("[IDFIELD]", line.id)
        //             .replace("[VALUE]", line.value !== undefined && (line.value as string).length > 0 ? line.value as string : line.defaultValue !== undefined && (line.defaultValue as string).length > 0 ? line.defaultValue as string : "");

        //         finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
        //             .replace("[IDFIELD]", line.id)
        //             .replace("[VALUE_CONFIG]", line._config_value !== undefined ? line._config_value.toString() !== "" && line._config_value.toString() !== "undefined" ? line._config_value.toString() : "0" : "0")
        //             .replace("[ID_NOTE_ITEM]", line._config_id_note !== undefined ? line._config_id_note.toString() : "");

        //     }else if (lineToUpdate !== undefined && lineToUpdate.id === line.id) {
        //         // console.log("ici ça marche");
        //         finalString = finalString + "\n" + this.EmptyFieldTemplate
        //             .replace("[IDFIELD]", lineToUpdate.id)
        //             .replace("[FIELDTYPE]", lineToUpdate.type)
        //             .replace("[FIELDTITLE]", lineToUpdate.title)
        //             .replace("[FIELDDESCRIPTION]", lineToUpdate.description !== undefined ? lineToUpdate.description : "")
        //             .replace("[REQUIRED]", lineToUpdate.required ? "1" : "0")
        //             .replace("[VALUES]", lineToUpdate.allProposals !== undefined ? lineToUpdate.allProposals.map(p => { return p }).toString().split(",").join("|") : "")
        //             .replace("[DEFAULTVALUE]", lineToUpdate.defaultValue !== undefined ? lineToUpdate.defaultValue : "");

        //         finalString = finalString + "\n" + this.EmptyFieldDataTemplate
        //             .replace("[IDFIELD]", lineToUpdate.id)
        //             .replace("[VALUE]", lineToUpdate.value !== undefined && (lineToUpdate.value as string).length > 0 ? lineToUpdate.value : lineToUpdate.defaultValue !== undefined && (lineToUpdate.defaultValue as string).length > 0 ? lineToUpdate.defaultValue : "");

        //         // console.log(finalString);

        //         finalString = finalString + "\n" + this.EmptyFieldConfigTemplate
        //             .replace("[IDFIELD]", lineToUpdate.id)
        //             .replace("[VALUE_CONFIG]", lineToUpdate._config_value !== undefined && lineToUpdate._config_value.toString() !== "undefined" ? lineToUpdate._config_value.toString() : "0")
        //             .replace("[ID_NOTE_ITEM]", lineToUpdate._config_id_note !== undefined ? lineToUpdate._config_id_note.toString() : "");
        //     }

        // });


        

        this.setState({ tmpBodyContener: finalString }, () => {
            // console.log(finalString);
            // console.log(this.state.tmpBodyContener);

            //    if(!updateNote){
            //     (this.props.computeSugestion as Function)()
            //    }

            if (updateNote) {
                let note: I_NOTE_ITEM = this.props.note;
                note.title = this.props.note.title;
                note.description = this.state.tmpBodyContener;
                note.descriptionraw = this.state.tmpBodyContener;
                note.modified = GetDateStringFromObject(new Date(), true);

                (this.props.GLOBAL_FUNCTION.updateNote as Function)(note,undefined,undefined,undefined, true,true);
            }
        })

    }

    onControleFormTask = () => {
        //Contrôle des taches associées
        //-----------------------------
        // console.log(this.props.note.title);
        if (GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(l => l.type === "F_BOOLEAN" && (l._config_value as string) === "1").length > 0 &&
            !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent) {


            //Parcour de toutes les lignes du formulaire
            (GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(l => l.type === "F_BOOLEAN" && l._config_value !== undefined ? (l._config_value as string) === "1" : false)).forEach(l => {
                if (
                    (
                        this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.title.indexOf(l.id) > -1 && t.idnote === this.props.note.idnote).length === 0
                    ) && this.state.taskToConvert.filter(_t => _t.key as string === l.id).length === 0
                ) {
                    setTimeout(async () => {
                        if (this.state.taskToConvert.filter(ttc => ttc.key as string === l.id).length === 0) {
                            (this.props.GLOBAL_FUNCTION.addNewTaskInNoteGroup as Function)(this.props.note, "[" + l.id + "]:" + l.title);
                            // (this.props.GLOBAL_FUNCTION.onLoadNoteData as Function)(this.props.note.idnote);
                            this.setState({
                                taskToConvert: this.state.taskToConvert.concat([{
                                    key: l.id,
                                    value: undefined,
                                    data: undefined
                                }])
                            })
                        }
                    }, 30);
                }
            });
            //------------------------------------------

            //Mise à jour des statuts des taches si liée au champ "OUI/NON"
            //-------------------------------------------------------------

        }
    }

    componentDidUpdate(prevProps: Readonly<I_CUST_FORM_PROPS>, prevState: Readonly<I_CUST_FORM_PROPS_STATE>, snapshot?: any): void {
        this.onControleFormTask();

        if (this.props.note.description !== this.state.tmpBodyContener && !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent) {
            if (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                !this.props.editMode)
                this.setState({ tmpBodyContener: this.props.note.description },
                    () => {
                        //-->
                        // alert("test");

                    }
                )
        }


    }

    componentDidMount(): void {

        //Chargement des taches liées
        //---------------------------


    }

    onQuickSaveNote = (e: React.KeyboardEvent) => {
        // if (e.ctrlKey && e.key === "s" && this.props.editMode) {
        //     e.preventDefault();

        //     if (
        //         this.state.tmpBodyContener.length>0 &&
        //         new Blob([this.state.tmpBodyContener]).size / 1000000 >
        //         this.props.GLOBAL_DATA.noteMaxSize
        //     ) {
        //         let msgOption: I_MSG_OPTION = {
        //             allMSG: [
        //                 {
        //                     key: "Taille maximale de saisie dépassée...",
        //                     value:
        //                         "Vous avez atteint la taille maximale de saisie dans la note, limitée à " +
        //                         this.props.GLOBAL_DATA.noteMaxSize +
        //                         "Kb.",
        //                     data: ENU_ICON_TYPE.note,
        //                 },
        //             ],
        //             showCloseButton: false,
        //             buttons: [
        //                 {
        //                     title: "OK",
        //                     F_BUTTON_ACTION: () => {
        //                         (this.props.GLOBAL_FUNCTION.closePopup as Function)();
        //                     },
        //                 },
        //             ],
        //         };

        //         (this.props.GLOBAL_FUNCTION.showMessage as Function)(msgOption);
        //     } else {
        //         if (this.state.tmpBodyContener.length>0) {
        //             let tmpNote: I_NOTE_ITEM = this.props.note;

        //             //Nettoyage
        //             if (IsCustForm(this.props.note.description)) {
        //                 tmpNote.title = NOTE_CUST_FORM_EMPTY_TITLE + NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustFormData(this.props.note.description, this.props.note.idnote).id.toString()) + this.props.note.title;
        //             } else {
        //                 tmpNote.title = this.props.note.title;
        //             }


        //             tmpNote.description = this.state.tmpBodyContener;
        //             tmpNote.descriptionraw = this.state.tmpBodyContener;
        //             tmpNote.modified = GetFormatedDate(new Date(), true);
        //             //---------

        //             (this.props.GLOBAL_FUNCTION.updateNote as Function)(
        //                 tmpNote,
        //                 undefined,
        //                 tmpNote.displayUnity,
        //                 false
        //             );

        //             //Inscription de l'id la pile
        //             (this.props.GLOBAL_FUNCTION.onEditNoteMode as Function)(
        //                 this.props.note.idnote
        //             );
        //         }
        //     }

        //     //   if (
        //     //     this.ref_comptaForm.current !== null &&
        //     //     IsComptaForm(this.props.note.description)
        //     //   )
        //     //     this.ref_comptaForm.current.onGenerateNoteBody();
        // }
        if (e.ctrlKey && e.key === "s" && this.props.editMode) {
            e.preventDefault();
            this.onConvertFormToString(true);
        }

    }

    render(): JSX.Element {
        return <form className='CUST_FORM_BOX' onKeyDown={(e) => {
            this.onQuickSaveNote(e)
        }} 
        onMouseLeave={()=>{
            this.props.editMode &&
            this.state.tmpBodyContener!==this.props.note.description&&
            this.onConvertFormToString(true);
        }}
        >
            {
                //Info formulaire parent
                //----------------------
                (IsCustForm(this.props.note.title) && GetNoteCustParentIdFromTitle(this.props.note.title) !== this.props.note.idnote.toString() &&
                    this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(GetNoteCustParentIdFromTitle(this.props.note.title))).length > 0) &&
                <div className='CUST_FORM_PARENT_INFORMATION_BOX'>
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}></span>
                    <span>N°{GetNoteCustParentIdFromTitle(this.props.note.title)}</span>
                    <span className='SPAN_SEP'></span>
                    <span>{
                        this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)))[0].title
                            .replace(NOTE_CUST_FORM_EMPTY_TITLE + (NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustParentIdFromTitle(this.props.note.title))), '')
                    }</span>
                    <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)))[0])


                    }}>
                        Voir le formulaire parent
                    </span>
                </div>
            }

            <div style={{ width: "100%" }}>

                <div>
                    {
                        this.props.editMode &&
                        GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote).parent &&
                        <div className='F_CUST_FORM_PRINCIPAL_MENU'>
                            <span>Ajouter un champ</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_TXT");
                            }}>Texte</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_ML_TXT");
                            }}>Zone de saisie</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_NUM");
                            }}>Numérique</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_BOOLEAN");
                            }}>CheckBox</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_CHOICE");
                            }}>Liste déroulante</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                this.onAddField("F_LINK");
                            }}>Liste Hypertexte</span>
                            {
                                GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote).lines.filter(l => l.type === "F_RTX").length === 0 &&
                                <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                    this.onAddField("F_RTX");
                                }}>Zone de texte riche</span>
                            }

                        </div>
                    }
                </div>

                {
                    //Tous les champs
                    //---------------
                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                    GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote).lines.map((line, index) => {
                        return <div key={line.id}>
                            {
                                //Mise à jour du titre du champ
                                //-----------------------------
                                this.props.editMode &&
                                GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote).parent &&
                                (line.type === "F_TXT" ||
                                    line.type === "F_ML_TXT" ||
                                    line.type === "F_NUM" ||
                                    line.type === "F_BOOLEAN" ||
                                    line.type === "F_RTX" ||
                                    line.type === "F_LINK" ||
                                    line.type === "F_CHOICE") &&
                                <div >

                                    {
                                        <div className='CUST_DRAG_ZONE' id={"index"}
                                            onDragOver={(ev) => {
                                                ev.preventDefault();
                                            }} onDrop={(ev) => {
                                                ev.preventDefault();
                                                this.onMoveFormLine(index);
                                            }}>

                                        </div>
                                    }


                                    <div className='CUST_FORM_EDIT_FIELD_BOX' draggable={true} onDragStart={(ev) => {
                                        ev.dataTransfer.setData("text", (ev.target as HTMLDivElement).id);
                                        this.onSelectElementToDrop(line);
                                    }}>

                                        <FIELD_TEXT F_Action={(fieldValue: string) => {
                                            let tmpLineObj: I_CUST_FORM_ITEM = line;
                                            tmpLineObj.title = fieldValue;
                                            this.onConvertFormToString(false, tmpLineObj);

                                        }} title={"Titre du champ [ " +
                                            (line.type === "F_TXT" ? "Texte" : (line.type === "F_NUM" ? "Numérique" : (line.type === "F_BOOLEAN" ? "OUI/NON" : (line.type === "F_CHOICE" ? "Liste" : (line.type === "F_ML_TXT" ? "Zone de saisie" : (line.type === "F_RTX" ? "Zone de texte riche" : (line.type === "F_LINK" ? "Hypertexte" : ""))))))) +
                                            " ]"} asDisplayMode={!this.props.editMode} value={line.title} required={line.required} />

                                        <div >
                                            {
                                                (line.type === "F_TXT" ||
                                                    line.type === "F_NUM" ||
                                                    line.type === "F_CHOICE") &&
                                                <span onClick={() => {
                                                    if (this.props.canEditInLive) {
                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj.required = !tmpLineObj.required;
                                                        this.onConvertFormToString(false, tmpLineObj);
                                                    }
                                                }}><FIELD_CHECK_BOX title='Requis' defaultValue={line.required} /></span>
                                            }
                                        </div>
                                        <div>
                                            {
                                                (line.type === "F_CHOICE") &&
                                                <FIELD_TEXT F_Action={(fieldValue: string) => {
                                                    let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                    tmpLineObj.allProposals = fieldValue.split(";");
                                                    this.onConvertFormToString(false, tmpLineObj);

                                                }} title="Valeurs séparées par ';'" required={false} asDisplayMode={!this.props.editMode} value={line.allProposals !== undefined ? line.allProposals.toString().split(",").join(";") : ""} />
                                            }
                                        </div>
                                        <div>
                                            {
                                                (line.type === "F_CHOICE") &&
                                                <FIELD_TEXT F_Action={(fieldValue: string) => {
                                                    let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                    tmpLineObj.defaultValue = fieldValue;
                                                    this.onConvertFormToString(false, tmpLineObj);

                                                }} title="Valeurs par défaut" required={false} asDisplayMode={!this.props.editMode} value={line.allProposals !== undefined ? line.allProposals[0] : ""} />
                                            }
                                        </div>

                                        <div>
                                            {
                                                (line.type === "F_BOOLEAN") &&
                                                <div className='FIELD_ZONE'>
                                                    <FIELD_CHECK_BOX F_Action={() => {
                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj._config_value = ((tmpLineObj._config_value as string) === "1" ? "0" : "1");
                                                        this.onConvertFormToString(false, tmpLineObj);
                                                    }} defaultValue={line._config_value as any === "1" ? true : false} title={"Convertir en tache de la note"} />

                                                    <div>
                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.information, 11) }}></span>&nbsp;&nbsp;&nbsp;
                                                        <span style={{ textAlign: "left", fontStyle: "italic", fontSize: "11px", fontWeight: "600" }}>
                                                            En activant cette option, une tache portant le titre du champ sera créée.
                                                        </span>
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                        <div>
                                            {
                                                (line.type === "F_CHOICE") &&
                                                <FIELD_CHECK_BOX F_Action={() => {
                                                    let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                    tmpLineObj._config_value = ((tmpLineObj._config_value as string) === "1" ? "0" : "1");
                                                    this.onConvertFormToString(false, tmpLineObj);
                                                }} defaultValue={line._config_value as any === "1" ? true : false} title={"Multi-sélection"} />
                                            }
                                        </div>

                                        <div style={{ textAlign: "right", padding: "14px" }}>
                                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                                this.onDeleteFormLine(line)
                                            }}>Supprimer le champ</span>
                                        </div>

                                    </div>
                                </div>
                            }

                            {
                                (!this.props.editMode ||
                                    (
                                        this.props.editMode &&
                                        !GetNoteCustFormData(this.state.tmpBodyContener, this.props.note.idnote).parent
                                    )
                                ) &&
                                <div>
                                    <div className='CUST_FIELD_TYPE_ZONE TEXT_TICKET'>
                                        &nbsp;&nbsp;&nbsp;<span dangerouslySetInnerHTML={{
                                            __html: GetIconFromType(
                                                (line.type === "F_TXT" ? ENU_ICON_TYPE.field_text : (line.type === "F_NUM" ? ENU_ICON_TYPE.field_number : (line.type === "F_BOOLEAN" ? ENU_ICON_TYPE.field_check : (line.type === "F_CHOICE" ? ENU_ICON_TYPE.field_list : (line.type === "F_ML_TXT" ? ENU_ICON_TYPE.field_brut_text : (line.type === "F_RTX" ? ENU_ICON_TYPE.field_rich_text : (line.type === "F_LINK" ? ENU_ICON_TYPE.field_link : ENU_ICON_TYPE.empty)))))))
                                                , 16
                                                , "e1e1e1")
                                        }}>

                                        </span>&nbsp;&nbsp;&nbsp;
                                    </div>

                                    {
                                        //Champs de type texte
                                        //---------------------
                                        line.type === "F_TXT" &&
                                        <FIELD_TEXT F_Action={(fieldValue: string) => {
                                            let tmpLineObj: I_CUST_FORM_ITEM = line;
                                            tmpLineObj.value = fieldValue;
                                            this.onConvertFormToString(false, tmpLineObj);
                                        }} title={line.title} asDisplayMode={!this.props.editMode} value={line.value !== undefined ? line.value : ""} required={line.required} />
                                    }



                                    {
                                        //Champs de type numérique
                                        //---------------------
                                        line.type === "F_NUM" &&
                                        <FIELD_TEXT F_Action={(fieldValue: string) => {
                                            let tmpLineObj: I_CUST_FORM_ITEM = line;
                                            tmpLineObj.value = fieldValue;
                                            this.onConvertFormToString(false, tmpLineObj);
                                        }} title={line.title} asDisplayMode={!this.props.editMode} value={line.value !== undefined ? line.value : ""} numberOnly={true} required={line.required} />
                                    }

                                    {
                                        //Champ Oui / Non
                                        //----------------------------
                                        line.type === "F_BOOLEAN" &&
                                        <div >
                                            <FIELD_CHECK_BOX
                                                F_Action={async (check: boolean) => {
                                                    if (this.props.canEditInLive) {
                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj.value = (check ? "1" : "0");
                                                        this.onConvertFormToString(true, tmpLineObj);

                                                        //Recherche de la tache associée et mise à jour de a tache
                                                        //--------------------------------------------------------
                                                        if (!GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent && this.props.GLOBAL_DATA.DB_TASKS !== undefined) {
                                                            const tmpLinkedTask: I_TASK_ITEM[] =
                                                                this.props.GLOBAL_DATA.DB_TASKS.filter(_t => _t.title.indexOf("[" + line.id + "]:") > -1 && _t.idnote === this.props.note.idnote);

                                                            if (tmpLinkedTask.length > 0)
                                                                (this.props.GLOBAL_FUNCTION.checkTaskNote as Function)(tmpLinkedTask[0].idtask, check);

                                                        }
                                                    }
                                                }}
                                                title={line.title}
                                                forceDefaultValue={true}
                                                defaultValue={
                                                    (line.value !== undefined ?
                                                        (line.value as string === "1" ? true : false) :
                                                        false)
                                                }
                                            />


                                        </div>
                                    }

                                    {
                                        //Champ sélection de choix
                                        //----------------------------
                                        line.type === "F_CHOICE" &&
                                        <div className='FIELD_ZONE'>

                                            <span className={line.required ? "FIELD_LABEL FIELD_LABEL_REQUIRED" : "FIELD_LABEL"}>{line.title}</span>
                                            <span className='FIELD_INPUT' style={{ display: parseInt(line._config_value) === 1 ? "block" : "inline-block", width: parseInt(line._config_value) === 1 ? "auto" : "initial" }}>
                                                {
                                                    parseInt(line._config_value) === 1 &&
                                                    <div>
                                                        {
                                                            (line.allProposals as string[]).map(option => {
                                                                return <span className='CHOICE_ITEM_BOX'>
                                                                    <span>{option}</span>
                                                                    <span><input onChange={(e) => {
                                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;

                                                                        let allSelectedValues: string[] = (line.value as string).split("|");
                                                                        if ((e.target as HTMLInputElement).checked) {
                                                                            allSelectedValues.push(option);
                                                                        } else {
                                                                            allSelectedValues = allSelectedValues.filter(sv => sv !== option);
                                                                        }

                                                                        tmpLineObj.value = allSelectedValues.map(sv => { return sv }).toString().split(",").join("|");
                                                                        console.log(tmpLineObj.value);

                                                                        this.onConvertFormToString(false, tmpLineObj);
                                                                    }} style={{ width: "auto" }} type={"checkbox"}
                                                                        checked={(line.value as string).split("|").filter(c => c === option).length > 0 ? true : false} /></span>
                                                                </span>
                                                            })
                                                        }
                                                    </div>
                                                }


                                                {
                                                    parseInt(line._config_value) === 0 &&
                                                    <select onChange={(e) => {
                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj.value = ((e as React.FormEvent).target as HTMLSelectElement).value;
                                                        this.onConvertFormToString(false, tmpLineObj);
                                                    }} disabled={!this.props.editMode} value={line.value !== undefined ? line.value as string : undefined} className='FIELD_INPUT_COMPONENT' style={{ width: "60%" }}>
                                                        <option value={line.defaultValue}>{line.defaultValue !== undefined ? line.defaultValue : "Sélectionnez une valeur ..."}</option>
                                                        {
                                                            (line.allProposals as string[]).map(option => {
                                                                return <option value={option} >{option}</option>
                                                            })
                                                        }
                                                    </select>
                                                }

                                            </span>
                                        </div>
                                    }

                                    {
                                        //Champ multilines texte brute
                                        //----------------------------
                                        line.type === "F_ML_TXT" &&
                                        <div className='FIELD_ZONE'>
                                            <span className={line.required ? "FIELD_LABEL FIELD_LABEL_REQUIRED" : "FIELD_LABEL"}>{line.title}</span>
                                            <div className='FIELD_INPUT' style={{ display: "block" }}>
                                                <textarea onChange={(e) => {
                                                    let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                    tmpLineObj.value = encodeURIComponent(((e as React.FormEvent).target as HTMLTextAreaElement).value);
                                                    this.onConvertFormToString(false, tmpLineObj);
                                                }} disabled={!this.props.editMode} className='FIELD_INPUT_COMPONENT' style={{ width: "60%", height: "125px" }} value={decodeURIComponent(line.value as string)} />
                                            </div>
                                        </div>
                                    }

                                    {
                                        //Champs de type texte
                                        //---------------------
                                        line.type === "F_LINK" &&
                                        <div className='CUST_LINK_FIELD_ZONE'>
                                            {
                                                !this.props.editMode &&
                                                <span>
                                                    <span className={line.required ? "FIELD_LABEL FIELD_LABEL_REQUIRED" : "FIELD_LABEL"}>{line.title}</span>
                                                    <span className='SPAN_SEP'></span>
                                                    <a href={line.value !== undefined && (line.value as string).split(":").length > 1 ? decodeURIComponent((line.value as string).split(":")[1]) : ""} target='_blank'>
                                                        <span>{line.value !== undefined && (line.value as string).split(":").length > 0 ? (line.value as string).split(":")[0] : ""}</span>
                                                    </a>
                                                </span>
                                            }

                                            {
                                                this.props.editMode &&
                                                <span >
                                                    <FIELD_TEXT F_Action={(fieldValue: string) => {

                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj.value = fieldValue + ":" + (line.value !== undefined && (line.value as string).split(":").length > 1 ? (line.value as string).split(":")[1] : "un lien");
                                                        this.onConvertFormToString(false, tmpLineObj);

                                                    }} title={"Titre du champ '" + line.title + "'"} asDisplayMode={!this.props.editMode} value={line.value !== undefined && (line.value as string).split(":").length > 0 ? (line.value as string).split(":")[0] : ""} required={line.required} />
                                                    <FIELD_TEXT F_Action={(fieldValue: string) => {

                                                        let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                        tmpLineObj.value = (line.value !== undefined && (line.value as string).split(":").length > 0 ? (line.value as string).split(":")[0] : "Lien") + ":" + encodeURIComponent(fieldValue);
                                                        this.onConvertFormToString(false, tmpLineObj);

                                                    }} title={line.title} asDisplayMode={!this.props.editMode} value={line.value !== undefined && (line.value as string).split(":").length > 1 ? decodeURIComponent((line.value as string).split(":")[1]) : ""} required={line.required} />
                                                </span>
                                            }

                                        </div>
                                    }

                                    {
                                        line.type === "F_RTX" &&
                                        <div className='FIELD_ZONE'>
                                            <span className={line.required ? "FIELD_LABEL FIELD_LABEL_REQUIRED" : "FIELD_LABEL"}>{line.title}</span>

                                            <span className='FIELD_INPUT'>
                                                {
                                                    !this.props.editMode &&
                                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(line.value) }}>
                                                    </div>
                                                }

                                                {
                                                    this.props.editMode &&
                                                    <RichTextEditorComponent autoSaveOnIdle={false}
                                                        onBlur={() => {
                                                            if (this.ref_rte.current !== null) {
                                                                let tmpLineObj: I_CUST_FORM_ITEM = line;
                                                                tmpLineObj.value = encodeURIComponent(this.ref_rte.current.getHtml());

                                                                // console.log(tmpLineObj.value);

                                                                this.onConvertFormToString(false, tmpLineObj);
                                                            }

                                                        }}
                                                        ref={this.ref_rte}
                                                        toolbarSettings={{
                                                            items: [
                                                                "Bold",
                                                                "Italic",
                                                                "Underline",
                                                                "StrikeThrough",
                                                                "FontName",
                                                                "FontSize",
                                                                "FontColor",
                                                                "BackgroundColor",
                                                                "LowerCase",
                                                                "UpperCase",
                                                                "|",
                                                                "Formats",
                                                                "Alignments",
                                                                "OrderedList",
                                                                "UnorderedList",
                                                                "Outdent",
                                                                "Indent",
                                                                "|",
                                                                "CreateLink",
                                                                "Image",
                                                                "|",
                                                                "ClearFormat",
                                                                "Print",
                                                                "SourceCode",
                                                                "|",
                                                                "Undo",
                                                                "Redo",
                                                            ],
                                                        }}
                                                        insertImageSettings={{
                                                            saveFormat: "Base64",
                                                        }}
                                                    >
                                                        {/* INTERNAL MESSAGE */}
                                                        <div id="INTERNAL_MESSAGE"></div>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: line.value !== undefined && (line.value as string).length > 0 ?
                                                                    decodeURIComponent(line.value) :
                                                                    '<div id="INTERNAL_MESSAGE"></div><p>...</p><div id="INTERNAL_MESSAGE"></div>',
                                                            }}
                                                        ></p>
                                                        <div id="INTERNAL_MESSAGE"></div>
                                                        {/* INTERNAL MESSAGE */}

                                                        <Inject
                                                            services={[
                                                                Toolbar,
                                                                Image,
                                                                Link,
                                                                HtmlEditor,
                                                                QuickToolbar,
                                                            ]}
                                                        />
                                                    </RichTextEditorComponent>
                                                }

                                            </span>

                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    })
                }
            </div>
        </form>
    }
}