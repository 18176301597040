import * as React from "react";
import "../../99_Styles/helper.css"
import { I_HELP_SHOWER_PROPS } from "../../02_Interfaces/I_HELPER";
import { GETHELPER_IMG } from "../../01_APPDATA/DB_IMG";
import { FIELD_CHECK_BOX } from "../Fields/FIELD_CHECK_BOX";

export class HELP_SHOWER extends React.Component<I_HELP_SHOWER_PROPS, {}>{
    
    render(): JSX.Element {
        return <div className="HELP_BOX">
            <div className="HELP_TITLE">
                {
                    GETHELPER_IMG(this.props.IMG) !== undefined &&
                    GETHELPER_IMG(this.props.IMG)?.value
                }
            </div>
            <img className="HELP_PICTURE" src={GETHELPER_IMG(this.props.IMG)?.data} />
            {
                this.props.actionTitle !== undefined &&
                <div>
                    <span onClick={() => {
                        (this.props.F_ACTION as Function)()
                    }}>
                        <FIELD_CHECK_BOX title={this.props.actionTitle} defaultValue={false} />
                    </span>
                </div>
            }

        </div>
    }
}