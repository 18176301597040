import * as React from "react";
import { DB_APP_CONFIG } from "../../01_APPDATA/DB_APP_CONFIG";
import { I_APP_CONFIG_PROPS, I_APP_CONFIG_PROPS_STATE } from "../../02_Interfaces/IAPPCONFIG";
import { ENU_ICON_TYPE, I_CONFIG_ITEM } from "../../02_Interfaces/IGLOBAL";
import { GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/configuration.css";
import "../../99_Styles/formGlobal.css";
import { FIELD_CHECK_BOX } from "../Fields/FIELD_CHECK_BOX";

export class MSG_CONFIGURATION extends React.Component<I_APP_CONFIG_PROPS, I_APP_CONFIG_PROPS_STATE>{

    constructor(props: I_APP_CONFIG_PROPS) {
        super(props);

        this.state = {

        }

        this.onSelectOption = this.onSelectOption.bind(this);
        this.onSelectParamGroup = this.onSelectParamGroup.bind(this);
    }

    //Les références
    private refLoadGroupNoteByDefault = React.createRef<FIELD_CHECK_BOX>();
    //===============

    onSelectOption(configItem: I_CONFIG_ITEM) {
        (this.props.APP_GLOBAL_FUNCTION.onUpdateOrCreateParam as Function)(configItem)
    }

    onSelectParamGroup(paramId: number) {
        (DB_APP_CONFIG).forEach(c => {
            if (c.id === paramId)
                c.selected = true;
            else
                c.selected = false;
        });
        this.setState({});//La variable est mise à jour dans le global, aors 
    }


    render(): JSX.Element {
        return <div className="POPUP_BOX">
            {/* font blanc non opaque  */}
            <div className="POPUP_LAYER">
            </div>


            {/* interface de gestion */}
            <div className="POPUP_BODY_BOX">
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.appConfig, 18) }}></span>
                        <span>Configuration</span>
                    </span>


                    {/* Bouton de fermeture de la popup */}
                    <div
                        onClick={() => { (this.props.APP_GLOBAL_FUNCTION.onShowConfig as Function)() }}
                        className="POPUP_CLOSE_BUTTON"
                        dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18) }}>
                    </div>
                </div>

                <div className="CONFIGURATION_POPUP_CONTAIN">
                    <div className="CONFIGURATION_POPUP_MENU">
                        {
                            DB_APP_CONFIG.map(conf => {
                                return <div key={"GRP_"+conf.id} className={conf.selected ? "CONFIGURATION_POPUP_MENU_TITLE CONFIGURATION_POPUP_MENU_TITLE_SELECTED" : "CONFIGURATION_POPUP_MENU_TITLE"}
                                    onClick={() => {
                                        this.onSelectParamGroup(conf.id)
                                    }}>
                                    {
                                        conf.groupName
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className="CONFIGURATION_POPUP_BODY">
                        {
                            DB_APP_CONFIG.filter(conf => conf.selected)[0].config.map(conf => {
                                return <div key={conf.key}>
                                    <div>
                                        {
                                            conf.value
                                        }
                                    </div>
                                    {
                                        (conf.data as string[]).map((confVal,index) => {
                                            return <span key={index} className="CONFIGURATION_VALUE_ITEM" onClick={() => {
                                                let tmpConf: I_CONFIG_ITEM;
                                                if (this.props.APP_GLOBAL_DATA.DB_CONFIG !== undefined && this.props.APP_GLOBAL_DATA.DB_CONFIG.filter(_conf => _conf.configName === conf.key as string).length > 0) {
                                                    tmpConf = this.props.APP_GLOBAL_DATA.DB_CONFIG.filter(_conf => _conf.configName === conf.key)[0];
                                                    tmpConf.configValue = confVal;
                                                } else {
                                                    tmpConf = {
                                                        idConfig: -1,
                                                        configName: conf.key,
                                                        configValue: confVal
                                                    }
                                                }

                                                this.onSelectOption(tmpConf);
                                            }}>
                                                <span><input
                                                    checked={this.props.APP_GLOBAL_DATA.DB_CONFIG !== undefined && this.props.APP_GLOBAL_DATA.DB_CONFIG.filter(_conf => _conf.configName === conf.key && _conf.configValue === confVal).length > 0 ? true : false}
                                                    type={"radio"} name={conf.key as string} radioGroup={conf.key as string} onChange={()=>{}} /></span>
                                                {
                                                    confVal
                                                }
                                            </span>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    }
}