import * as React from "react";
import { ENU_EYE_STATUS, I_EYE_PROPS } from "../../02_Interfaces/I_EYE";
import "../../99_Styles/eye.css";

export class EYE_DYN extends React.Component<I_EYE_PROPS, {}>{
    render(): JSX.Element {
        return <div className={this.props.allwaysOnTop?"EYE_BOX EYE_BOX_AOT":"EYE_BOX"} style={{opacity:this.props.allwaysOnTop?"1!imporant":undefined}}>
            {/* Partie haute de l'oeil */}
            <div className={
                this.props.eye_status === ENU_EYE_STATUS.close ?
                    "EYE_POP_TOP EYE_POP_TOP_CLOSE" :
                    this.props.eye_status === ENU_EYE_STATUS.open ?
                        "EYE_POP_TOP EYE_POP_TOP_OPEN" : "EYE_POP_TOP"
            }></div>

            {/* Iris */}
            <div className="EYE_IRIS">

            </div>


            {/* Partie basse de l'oeil */}
            <div className={
                this.props.eye_status === ENU_EYE_STATUS.close ?
                    "EYE_POP_BOTTOM EYE_POP_BOTTOM_CLOSE" :
                    this.props.eye_status === ENU_EYE_STATUS.open ?
                        "EYE_POP_BOTTOM EYE_POP_BOTTOM_OPEN" : "EYE_POP_BOTTOM"
            }></div>

        </div>
    }
}