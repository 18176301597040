import * as React from "react";
import { DB_MONTH_NAME } from "../../01_APPDATA/DB_APP";
import { ENU_ACCOUNT_TYPE, ENU_LINE_TYPE, I_COMPTA_ACCOUNT_ITEM, I_COMPTA_FORM_ITEM, I_COMPTA_FORM_NOTE, I_COMPTA_STAT_PROPS, I_COMPTA_STAT_PROPS_STATE } from "../../02_Interfaces/I_COMPTA_FORM";
import { GetDistinctListOfString, GetIconFromType } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { getIndex } from "@syncfusion/ej2-react-richtexteditor";

export class COMPTA_STAT extends React.Component<I_COMPTA_STAT_PROPS, I_COMPTA_STAT_PROPS_STATE>{


    constructor(props: I_COMPTA_STAT_PROPS) {
        super(props);

        this.state = {
            totalBenefitAmount: 0,
            totalDeficiteAmount: 0,
            selectedMonth:(new Date).getMonth()-1,
            selectedYear:(new Date).getFullYear()
        }
    }

    computeAccountAmount(filteredComptaForm: I_COMPTA_FORM_NOTE[], ac: I_COMPTA_ACCOUNT_ITEM) {

        let finalAmount: number = 0;
        for (let x = 0; x < filteredComptaForm.length; x++) {
            let line: I_COMPTA_FORM_NOTE = filteredComptaForm[x];
            let tmpValue_D: number = (parseInt(((line.lines.filter(l => l.source === ac.id).map(l => {
                return line.lines
                    .filter(_l => l.idLigne === _l.idLigne)
                    .map(a => a.amount)
                    .reduce((a, b) => {
                        return a + b
                    }, 0)
            })).map(amount => { return amount })
                .reduce((a, b) => {
                    return a + b
                }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) ?
                - ((line.lines.filter(l => l.source === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) -
                    (line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0)) :
                ((line.lines.filter(l => l.destination === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) -
                    (line.lines.filter(l => l.source === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0))).toFixed(2)) < 0 ?
                ((line.lines.filter(l => l.source === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) ?
                    - ((line.lines.filter(l => l.source === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) -
                        (line.lines.filter(l => l.destination === ac.id).map(l => {
                            return line.lines
                                .filter(_l => l.idLigne === _l.idLigne)
                                .map(a => a.amount)
                                .reduce((a, b) => {
                                    return a + b
                                }, 0)
                        })).map(amount => { return amount })
                            .reduce((a, b) => {
                                return a + b
                            }, 0)) :
                    ((line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) -
                        (line.lines.filter(l => l.source === ac.id).map(l => {
                            return line.lines
                                .filter(_l => l.idLigne === _l.idLigne)
                                .map(a => a.amount)
                                .reduce((a, b) => {
                                    return a + b
                                }, 0)
                        })).map(amount => { return amount })
                            .reduce((a, b) => {
                                return a + b
                            }, 0))).toFixed(2)/*.replace(".", ",")*/ as string : 0) as number;
            let tmpValue_C = (parseInt(((line.lines.filter(l => l.source === ac.id).map(l => {
                return line.lines
                    .filter(_l => l.idLigne === _l.idLigne)
                    .map(a => a.amount)
                    .reduce((a, b) => {
                        return a + b
                    }, 0)
            })).map(amount => { return amount })
                .reduce((a, b) => {
                    return a + b
                }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) ?
                - ((line.lines.filter(l => l.source === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) -
                    (line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0)) :
                ((line.lines.filter(l => l.destination === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) -
                    (line.lines.filter(l => l.source === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0))).toFixed(2)) > 0 ?
                ((line.lines.filter(l => l.source === ac.id).map(l => {
                    return line.lines
                        .filter(_l => l.idLigne === _l.idLigne)
                        .map(a => a.amount)
                        .reduce((a, b) => {
                            return a + b
                        }, 0)
                })).map(amount => { return amount })
                    .reduce((a, b) => {
                        return a + b
                    }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) ?
                    - ((line.lines.filter(l => l.source === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) -
                        (line.lines.filter(l => l.destination === ac.id).map(l => {
                            return line.lines
                                .filter(_l => l.idLigne === _l.idLigne)
                                .map(a => a.amount)
                                .reduce((a, b) => {
                                    return a + b
                                }, 0)
                        })).map(amount => { return amount })
                            .reduce((a, b) => {
                                return a + b
                            }, 0)) :
                    ((line.lines.filter(l => l.destination === ac.id).map(l => {
                        return line.lines
                            .filter(_l => l.idLigne === _l.idLigne)
                            .map(a => a.amount)
                            .reduce((a, b) => {
                                return a + b
                            }, 0)
                    })).map(amount => { return amount })
                        .reduce((a, b) => {
                            return a + b
                        }, 0) -
                        (line.lines.filter(l => l.source === ac.id).map(l => {
                            return line.lines
                                .filter(_l => l.idLigne === _l.idLigne)
                                .map(a => a.amount)
                                .reduce((a, b) => {
                                    return a + b
                                }, 0)
                        })).map(amount => { return amount })
                            .reduce((a, b) => {
                                return a + b
                            }, 0))).toFixed(2) : 0) as number;
            finalAmount = parseInt(finalAmount.toString()) + (parseInt(tmpValue_D.toString()) + parseInt(tmpValue_C.toString()));
        }

        return finalAmount;
    }



    componentDidMount(): void {
        //Détermintation du tag relatif à l'année la plus ressente saisie
        //---------------------------------------------------------------
        let lastYear: number = 0;
        if (this.props.GLOBAL_DATA._DB_COMPTA !== undefined) {
            (this.props.GLOBAL_DATA._DB_COMPTA).forEach(cf => {
                if (lastYear < cf.year && cf.year <= (new Date()).getFullYear()) {
                    lastYear = cf.year
                }
            });
        }

        (this.props.GLOBAL_FUNCTION.onSelectTag as Function)(lastYear.toString());

        //Forçage de a sélection de menu note
        //-----------------------------------
        (this.props.GLOBAL_FUNCTION.onSelectNoteMenu as Function)(ENU_ICON_TYPE.euroDevise)
    }

    render(): JSX.Element {


        return <div style={{ paddingLeft: "75px" }}>

            <div>
                {
                    this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                    GetDistinctListOfString(this.props.GLOBAL_DATA._DB_COMPTA.map(fc => { return fc.year.toString() }))
                        .map(y => {
                            return <span className="CALENDAR_YEAR_SELECTOR_BUTTON">
                                {
                                    y
                                }
                            </span>
                        })
                }
            </div>

            <div>
                {
                    this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                    GetDistinctListOfString(
                        this.props.GLOBAL_DATA._DB_COMPTA
                            .map(fc => { return fc.month.toString() })
                    ).map(m => {
                        return <span className="CALENDAR_MONTH_SELECTOR_BUTTON">
                            {
                                DB_MONTH_NAME[parseInt(m) - 1]
                            }
                        </span>
                    })
                }
            </div>

            <div style={{ textAlign: "center" }} className="PANEL_SYNTHESE_ZONE">
                <div className="PANEL_TITLE_ZONE_BOX">
                    Synthèse des comptes
                </div>
                {
                    this.props.GLOBAL_DATA._DB_COMPTA_ACCOUNT !== undefined &&
                    this.props.GLOBAL_DATA._DB_COMPTA_ACCOUNT.map(c => {
                        return <div className="COMPTE_FORM_ACCOUNT_SYNTHESE" >
                            <div>
                                <span style={{fontSize:"18px"}} className={
                                    this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                        this.computeAccountAmount(this.props.GLOBAL_DATA._DB_COMPTA, c) > 0 ? "COMPTA_STAT_INFO COMPTA_STAT_CREDIT" : "COMPTA_STAT_INFO COMPTA_STAT_DEBIT"}>{
                                        this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                        this.computeAccountAmount(this.props.GLOBAL_DATA._DB_COMPTA, c)
                                    }
                                    &nbsp;
                                    <span>€</span>
                                </span>

                            </div>
                            <div className="COMPTE_FORM_ACCOUNT_TITLE">
                                <span>{c.title}</span>
                            </div>
                        </div>
                    })
                }
            </div>



            {
                this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                GetDistinctListOfString(this.props.GLOBAL_DATA._DB_COMPTA.map(fc => { return fc.year.toString() }))
                    .map(y => {
                        return <div>
                            <div>
                                {
                                    this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                    GetDistinctListOfString(
                                        this.props.GLOBAL_DATA._DB_COMPTA
                                            .filter(fc => fc.year === parseInt(y))
                                            .map(fc => { return fc.month.toString() })
                                    )
                                        .sort((a, b) => { return parseInt(a) - parseInt(b) })
                                        .map(m => {
                                            return <div>
                                                <div><span className="CALENDAR_MONTH">{
                                                    DB_MONTH_NAME[parseInt(m) - 1]
                                                }</span>
                                                    <span className="CALENDAR_YEAR">{
                                                        y
                                                    }</span>
                                                </div>

                                                <div>
                                                    {
                                                        this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                                        this.props.GLOBAL_DATA._DB_COMPTA.filter(fc => fc.month === parseInt(m) && fc.year === parseInt(y))
                                                            .map(line => {
                                                                return <table style={{ width: "100%", textAlign: "left" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>
                                                                                Source
                                                                            </th>
                                                                            <th>
                                                                                type compte
                                                                            </th>
                                                                            <th>
                                                                                <span>Débit</span>
                                                                                &nbsp;
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.euroDevise, 11) }}></span>
                                                                            </th>
                                                                            <th>
                                                                                <span>Crédit</span>
                                                                                &nbsp;
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.euroDevise, 11) }}></span>
                                                                            </th>
                                                                        </tr>
                                                                        {
                                                                            line.accounts.map(ac => {
                                                                                return <tr>
                                                                                    <td style={{ width: "40%" }}>
                                                                                        {
                                                                                            ac.title
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            ac.typeAccount
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="COMPTA_STAT_INFO COMPTA_STAT_DEBIT">
                                                                                            {
                                                                                                parseInt(((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                    return line.lines
                                                                                                        .filter(_l => l.idLigne === _l.idLigne)
                                                                                                        .map(a => a.amount)
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0)
                                                                                                })).map(amount => { return amount })
                                                                                                    .reduce((a, b) => {
                                                                                                        return a + b
                                                                                                    }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) ?
                                                                                                    - ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) -
                                                                                                        (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)) :
                                                                                                    ((line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) -
                                                                                                        (line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0))).toFixed(2)) < 0 ?
                                                                                                    ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) ?
                                                                                                        - ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) -
                                                                                                            (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                                return line.lines
                                                                                                                    .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                    .map(a => a.amount)
                                                                                                                    .reduce((a, b) => {
                                                                                                                        return a + b
                                                                                                                    }, 0)
                                                                                                            })).map(amount => { return amount })
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)) :
                                                                                                        ((line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) -
                                                                                                            (line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                                return line.lines
                                                                                                                    .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                    .map(a => a.amount)
                                                                                                                    .reduce((a, b) => {
                                                                                                                        return a + b
                                                                                                                    }, 0)
                                                                                                            })).map(amount => { return amount })
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0))).toFixed(2) : 0

                                                                                            }
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="COMPTA_STAT_INFO COMPTA_STAT_CREDIT">
                                                                                            {
                                                                                                parseInt(((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                    return line.lines
                                                                                                        .filter(_l => l.idLigne === _l.idLigne)
                                                                                                        .map(a => a.amount)
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0)
                                                                                                })).map(amount => { return amount })
                                                                                                    .reduce((a, b) => {
                                                                                                        return a + b
                                                                                                    }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) ?
                                                                                                    - ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) -
                                                                                                        (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)) :
                                                                                                    ((line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) -
                                                                                                        (line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0))).toFixed(2)) > 0 ?
                                                                                                    ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                        return line.lines
                                                                                                            .filter(_l => l.idLigne === _l.idLigne)
                                                                                                            .map(a => a.amount)
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0)
                                                                                                    })).map(amount => { return amount })
                                                                                                        .reduce((a, b) => {
                                                                                                            return a + b
                                                                                                        }, 0) > (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) ?
                                                                                                        - ((line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) -
                                                                                                            (line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                                return line.lines
                                                                                                                    .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                    .map(a => a.amount)
                                                                                                                    .reduce((a, b) => {
                                                                                                                        return a + b
                                                                                                                    }, 0)
                                                                                                            })).map(amount => { return amount })
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)) :
                                                                                                        ((line.lines.filter(l => l.destination === ac.id).map(l => {
                                                                                                            return line.lines
                                                                                                                .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                .map(a => a.amount)
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0)
                                                                                                        })).map(amount => { return amount })
                                                                                                            .reduce((a, b) => {
                                                                                                                return a + b
                                                                                                            }, 0) -
                                                                                                            (line.lines.filter(l => l.source === ac.id).map(l => {
                                                                                                                return line.lines
                                                                                                                    .filter(_l => l.idLigne === _l.idLigne)
                                                                                                                    .map(a => a.amount)
                                                                                                                    .reduce((a, b) => {
                                                                                                                        return a + b
                                                                                                                    }, 0)
                                                                                                            })).map(amount => { return amount })
                                                                                                                .reduce((a, b) => {
                                                                                                                    return a + b
                                                                                                                }, 0))).toFixed(2) : 0

                                                                                            }
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        })
                                }
                            </div>
                        </div>
                    })
            }
        </div>
    }
}