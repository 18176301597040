import * as React from "react";
import { I_GLOBAL_SEARCH_PROPS } from "../../../02_Interfaces/I_GLOBAL_SEARCH";
import { CupBoardCode, FormatTextWithKey, GetDateObjectFromString, GetDistinctListOfString, GetIconFromType, GetNoteComptaData, GetNoteCustFormData, GetNoteCustParentIdFromTitle, GoToElement, IsComptaForm, IsCustForm, IsTypeNoteIsAnCupBord } from "../../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../../02_Interfaces/IGLOBAL";
import { CUST_FORM_BOX } from "../CUST_FORM";
import { COMPTA_FORM } from "../COMPTA_FORM";
import { NOTE_COMPTA_EMPTY_TEMPLATE } from "../../../01_APPDATA/DB_APP";

export class GLOBAL_SEARCH_BOX extends React.Component<I_GLOBAL_SEARCH_PROPS, {}>{
    render(): JSX.Element {
        return <div className='APP_NO_FOLDER_SELECTED_FOLDER_MAIN_BOX' style={{ textAlign: "left" }}>
            {
                this.props.GLOBAL_DATA.savedGlobalSearchKeyValue !== undefined &&
                <div className='APP_NO_FOLDER_SELECTED_STAT_SEARCH_GLOBAL_BOX'>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.search, 11) }}></span>
                        <span className='SPAN_SEP'></span>
                        <span>
                            {this.props.GLOBAL_DATA.savedGlobalSearchKeyValue.split("&&").map(sk => { return sk + ", " })}
                        </span>
                    </div>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 11) }}></span>
                        <span className='SPAN_SEP'></span>
                        <span>
                            {GetDistinctListOfString(this.props.GLOBAL_DATA.globalSearchResult
                                .filter(gs => this.props.GLOBAL_DATA.DB_GROUPS !== undefined && this.props.GLOBAL_DATA.globalSearchResult.length > 0 && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.selected as boolean && this.props.GLOBAL_DATA.selectedGroup === undefined).length > 0 ? this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === gs.idgroupnote && g.selected as boolean).length > 0 : true)
                                .map(gs => { return gs.idgroupnote.toString() })).length}
                        </span>
                        <span className='SPAN_SEP'></span>
                        <span>

                            {
                                GetDistinctListOfString(this.props.GLOBAL_DATA.globalSearchResult
                                    .filter(gs => this.props.GLOBAL_DATA.DB_GROUPS !== undefined && this.props.GLOBAL_DATA.globalSearchResult.length > 0 && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.selected as boolean && this.props.GLOBAL_DATA.selectedGroup === undefined).length > 0 ? this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === gs.idgroupnote && g.selected as boolean).length > 0 : true)
                                    .map(gs => {
                                        return this.props.GLOBAL_DATA.DB_GROUPS !== undefined && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => gs.idgroupnote === g.id).length > 0 && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => gs.idgroupnote === g.id)[0] !== undefined ? this.props.GLOBAL_DATA.DB_GROUPS.filter(g => gs.idgroupnote === g.id)[0].title : ""
                                    }))
                                    .sort((a, b) => { return a.localeCompare(b) })
                                    .map(gs => {
                                        return <span key={gs}>
                                            <span title={"Naviger vers'" + gs + "'"} className="GLOBAL_SEARCH_DINCT_GROUP_ITEM" style={{ cursor: "pointer" }} onClick={() => {
                                                GoToElement(gs);
                                            }}>{
                                                    gs
                                                }</span>
                                            <span>, </span>
                                        </span>
                                    })
                            }
                        </span>
                    </div>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 11) }}></span>
                        <span className='SPAN_SEP'></span>
                        <span>
                            {this.props.GLOBAL_DATA.globalSearchResult
                                .filter(gs => this.props.GLOBAL_DATA.DB_GROUPS !== undefined && this.props.GLOBAL_DATA.globalSearchResult.length > 0 && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.selected as boolean && this.props.GLOBAL_DATA.selectedGroup === undefined).length > 0 ? this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === gs.idgroupnote && g.selected as boolean).length > 0 : true)
                                .length}
                        </span>
                    </div>
                </div>
            }

            {
                GetDistinctListOfString(this.props.GLOBAL_DATA.globalSearchResult
                    .filter(gs => this.props.GLOBAL_DATA.DB_GROUPS !== undefined && this.props.GLOBAL_DATA.globalSearchResult.length > 0 && this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.selected as boolean && this.props.GLOBAL_DATA.selectedGroup === undefined).length > 0 ? this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === gs.idgroupnote && g.selected as boolean).length > 0 : true)
                    .map(gs => { return gs.idgroupnote.toString() })).map(gsIdGroup => {
                        return <div key={gsIdGroup} id={
                            this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gsIdGroup)).length > 0 ?
                                this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gsIdGroup))[0].title : undefined
                        }>
                            <div className='APP_NO_FOLDER_SELECTED_TITLE_ZONE' id={"SR_" + gsIdGroup}>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 22) }}></span>
                                &nbsp;&nbsp;&nbsp;
                                <span>{
                                    this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gsIdGroup)).length > 0 &&
                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gsIdGroup))[0].title
                                }</span>
                            </div>
                            <div className='APP_NO_FOLDER_SELECTED_RESULT_ITEM'>
                                {
                                    this.props.GLOBAL_DATA.globalSearchResult
                                        .sort((a, b) => { return GetDateObjectFromString(b.modified).dateObj.getTime() - GetDateObjectFromString(a.modified).dateObj.getTime() })
                                        .filter(_gsr => _gsr.idgroupnote === parseInt(gsIdGroup)).map((result, index) => {
                                            return <div key={index} className='APP_NO_FOLDER_SELECTED_RESULT_ITEM_DETAIL_BOX'>
                                                <div>

                                                    {/* Indicateur divers */}
                                                    {
                                                        IsCustForm(result.title) &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{
                                                                __html: GetIconFromType(
                                                                    parseInt(GetNoteCustParentIdFromTitle(result.title)) === result.idnote ?
                                                                        ENU_ICON_TYPE.form_parent :
                                                                        ENU_ICON_TYPE.form_child, 14)
                                                            }}></span>
                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }

                                                    {
                                                        IsComptaForm(result.description) &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 14) }}></span>
                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }

                                                    <span className='APP_NO_FOLDER_SELECTED_RESULT_TYPE' style={{ backgroundColor: result.typeColor !== undefined ? "#" + result.typeColor : undefined }}>
                                                        <span className='TEXT_TICKET'>
                                                            {
                                                                result.typeTitle !== undefined &&
                                                                IsTypeNoteIsAnCupBord(result.typeTitle) &&
                                                                <span>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}>

                                                                    </span>
                                                                    <span className='SPAN_SEP'></span>
                                                                </span>
                                                            }
                                                            <span>{
                                                                result.typeTitle?.replace(CupBoardCode, "")
                                                            }</span>
                                                        </span>
                                                    </span>
                                                    <span className='SPAN_SEP'></span>
                                                    <span>
                                                        {
                                                            this.props.GLOBAL_DATA.savedGlobalSearchKeyValue !== undefined &&
                                                            this.props.GLOBAL_DATA.savedGlobalSearchKeyValue.split("&&").filter(gs => result.title.toUpperCase().indexOf(gs.toUpperCase()) > -1).length > 0 &&
                                                            <span>
                                                                <span dangerouslySetInnerHTML={{ __html: FormatTextWithKey(result.title, this.props.GLOBAL_DATA.savedGlobalSearchKeyValue.split("&&")) }}></span>
                                                            </span>
                                                        }

                                                        {
                                                            this.props.GLOBAL_DATA.savedGlobalSearchKeyValue !== undefined &&
                                                            this.props.GLOBAL_DATA.savedGlobalSearchKeyValue.split("&&").filter(gs => result.title.toUpperCase().indexOf(gs.toUpperCase()) > -1).length === 0 &&
                                                            <span >
                                                                {
                                                                    IsCustForm(result.title) ?
                                                                        result.title.split("]-")[1] :
                                                                        result.title
                                                                }
                                                            </span>
                                                        }
                                                    </span>
                                                    <span className='SPAN_SEP'></span>
                                                    <span>
                                                        {
                                                            result.nbAttachment_t !== undefined &&
                                                            result.nbAttachment_t as number > 0 &&
                                                            <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM'>
                                                                <span>{result.nbAttachment_t}</span>&nbsp;
                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.attachment, 11) }}></span>
                                                            </span>
                                                        }

                                                        {
                                                            result.nbTask_unfinished !== undefined &&
                                                            result.nbTask_t !== undefined &&
                                                            result.nbTask_t as number > 0 &&
                                                            (result.nbTask_unfinished as number) <= (result.nbTask_t as number) &&
                                                            <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM'>
                                                                <span>{result.nbTask_t - result.nbTask_unfinished}/{result.nbTask_t}</span>&nbsp;
                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType((result.nbTask_t as number - result.nbTask_unfinished as number) === (result.nbTask_t as number) ? ENU_ICON_TYPE.checkedTask : ENU_ICON_TYPE.pendingtasks, 11) }}></span>
                                                            </span>
                                                        }

                                                        {
                                                            parseInt((result.pined as any) as string) === 1 &&
                                                            <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM'>
                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.markBook, 11) }}></span>
                                                            </span>
                                                        }
                                                    </span>
                                                    <span className='SPAN_SEP'></span>
                                                    <span>
                                                        <span>{
                                                            result.editor
                                                        }</span>
                                                        (&nbsp;<span dangerouslySetInnerHTML={{ __html: GetIconFromType(result.isAuthor ? ENU_ICON_TYPE.authorItem : ENU_ICON_TYPE.community, 11) }}></span>&nbsp;)
                                                    </span>
                                                    <span>
                                                        <span className='SPAN_SEP'></span>
                                                        <span>
                                                            {
                                                                result.modified
                                                            }
                                                        </span>
                                                    </span>
                                                </div>

                                                <div className='APP_NO_FOLDER_SELECTED_RESULT_STATS'>
                                                    <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM' style={{ textDecoration: this.props.GLOBAL_DATA.savedGlobalSearchKeyValue !== undefined && this.props.GLOBAL_DATA.savedGlobalSearchKeyValue.split("&&").filter(gs => result.title.toUpperCase().indexOf(gs.toUpperCase()) > -1).length === 0 ? "line-through" : undefined }}>
                                                        dans le titre de la note
                                                    </span>
                                                    <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM' style={{ textDecoration: result.nbAttachment_s !== undefined && result.nbAttachment_s === 0 ? "line-through" : undefined }}>
                                                        dans le titre de la pièce jointe
                                                    </span>
                                                    <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM' style={{ textDecoration: result.nbTask_s !== undefined && result.nbTask_s === 0 ? "line-through" : undefined }}>
                                                        dans une tache
                                                    </span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM' onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.onLoadSearchData as Function)(result.idgroupnote, result.idnote)
                                                    }}>
                                                        <span className='APP_FOLDER_TABLE_BUTTON' >
                                                            {result.description.length > 0 ? "Masquer la prévisualisation" : "prévisualiser la note"}
                                                        </span>
                                                    </span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    {
                                                        !this.props.GLOBAL_DATA.linkItemMode &&
                                                        <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM'>
                                                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                                                (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(result)
                                                            }}>
                                                                Afficher la note
                                                            </span>
                                                        </span>
                                                    }



                                                    {
                                                        this.props.GLOBAL_DATA.linkItemMode &&
                                                        <span className='APP_NO_FOLDER_SELECTED_RESULT_STATS_ITEM' onClick={() => {
                                                            if (this.props.GLOBAL_DATA.selectedNoteToLink !== undefined && this.props.GLOBAL_DATA.selectedGroup !== undefined)
                                                                (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(result.idnote, this.props.GLOBAL_DATA.selectedNoteToLink, result.idgroupnote)
                                                        }}>
                                                            <span className='APP_FOLDER_TABLE_BUTTON'>
                                                                {
                                                                    this.props.GLOBAL_DATA.selectedNoteToLink !== undefined &&
                                                                        this.props.GLOBAL_DATA.selectedNoteToLink.associations.split(";").filter(a => a.length > 0 && a !== "NaN" && parseInt(a) === result.idnote).length > 0 ? "Délier la note" : "Lier la note"
                                                                }
                                                            </span>
                                                        </span>
                                                    }


                                                </div>

                                                <div>
                                                    {
                                                        result.description.length > 0 &&
                                                        !IsCustForm(result.description) &&
                                                        !(result.description.indexOf("WASP-CMTA-FORM") > -1) &&
                                                        <div className='APP_NO_FOLDER_SELECTED_RESULT_NOTE_BODY' dangerouslySetInnerHTML={{
                                                            __html:
                                                                result.description

                                                        }}>

                                                        </div>
                                                    }

                                                    {
                                                        result.description.length > 0 &&
                                                        (
                                                            IsCustForm(result.description)
                                                        ) &&
                                                        <div className='APP_NO_FOLDER_SELECTED_RESULT_NOTE_BODY' >
                                                            <CUST_FORM_BOX canEditInLive={false} note={result} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} editMode={false} />
                                                        </div>
                                                    }

                                                    {
                                                        result.description.length > 0 &&
                                                        (
                                                            (result.description.indexOf("WASP-CMTA-FORM") > -1)
                                                        ) &&
                                                        <div className='APP_NO_FOLDER_SELECTED_RESULT_NOTE_BODY' >
                                                            <COMPTA_FORM APP_DATA={this.props.GLOBAL_DATA} APP_FUNCTION={this.props.GLOBAL_FUNCTION} note={result} editMode={false} showTotalOnly={false} />
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        })
                                }
                            </div>
                        </div>
                    })
            }
        </div>
    }
}