import * as React from "react";
import { I_TYPENOTE_ITEM_PROPS, I_TYPENOTE_ITEM_PROPS_STATE } from "../../../02_Interfaces/I_TYPENOTE_ITEM";
import { ENU_COLOR_TYPE, ENU_ICON_TYPE, ENU_SHARE_TYPE } from "../../../02_Interfaces/IGLOBAL";
import { CupBoardCode, GetIconFromType, IsTypeNoteIsAnCupBord } from "../../../04_TOOLS/tools";
import { FIELD_TEXT } from "../../Fields/FIELD_TEXT";
import { FIELD_CHECK_BOX } from "../../Fields/FIELD_CHECK_BOX";
import { CLS_COLOR_PALETTE, ColorPickerInput } from "@syncfusion/ej2-react-richtexteditor";
import { ColorsPicker } from "../../../01_APPDATA/DB_APP";
import { I_TYPENOTE } from "../../../02_Interfaces/INOTE";
import { I_USER_PROFIL_ITEM } from "../../../02_Interfaces/IUSERPROFIL";


export class TYPENOTE_ITEM extends React.Component<I_TYPENOTE_ITEM_PROPS, I_TYPENOTE_ITEM_PROPS_STATE> {

    constructor(props: I_TYPENOTE_ITEM_PROPS) {
        super(props);

        this.state = {
            selectedColor: this.props.typeNote.colorString !== undefined ? this.props.typeNote.colorString : "ffffff"
        }

        this.onChangeColorType = this.onChangeColorType.bind(this);
    }

    onChangeColorType(color: string) {
        this.setState({ selectedColor: color });
    }


    private ref_Field_Text = React.createRef<FIELD_TEXT>();
    private ref_Field_Check = React.createRef<FIELD_CHECK_BOX>();


    render(): JSX.Element {
        return <div key={this.props.typeNote.id} style={{ textAlign: "right" }} className="TN_LINE_ZONE"
            title={
                this.props.GLOBAL_DATA.selectedType !== undefined &&
                    this.props.GLOBAL_DATA.selectedType.filter(t => t === this.props.typeNote.id).length > 0 ?
                    (IsTypeNoteIsAnCupBord(this.props.typeNote.title) ?
                        "Cliquez sur le classeur '" + this.props.typeNote.title + "' pour le retirer du filtre courant ..." :
                        "Cliquez sur l'intercalaire '" + this.props.typeNote.title + "' pour le retirer du filtre courant ...") :
                    (IsTypeNoteIsAnCupBord(this.props.typeNote.title) ?
                        "Cliquez sur le classeur '" + this.props.typeNote.title + "' pour l'ajouter au filtre courant ..." :
                        "Cliquez sur l'intercalaire '" + this.props.typeNote.title + "' pour l'ajouter au filtre courant ...")}>
            <span className={this.props.GLOBAL_DATA.selectedType !== undefined && this.props.GLOBAL_DATA.selectedType.filter(_tn => _tn === this.props.typeNote.id).length > 0 ? 'TN_BOX TN_BOX_SELECTED' : "TN_BOX"}
                style={{ backgroundColor: "#" + (this.props.GLOBAL_DATA.editType !== undefined ? this.state.selectedColor : this.props.typeNote.colorString) }}>
                {
                    //menu d'action du type
                    (this.props.GLOBAL_DATA.editType === undefined) &&
                    <div className='TN_CONTEXTUEL_MENU' style={{ top: "-15px" }}>
                        <div>

                            {
                                (
                                    (
                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            siwm.canEdit &&
                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (
                                                (
                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                    (siwm.idType === null || siwm.idType === undefined)
                                                ) ||
                                                (
                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.typeNote.id === siwm.idType)
                                                )
                                            )
                                        ).length > 0
                                    )
                                    ||
                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                ) === true &&
                                <span className='TN_CONTEXTUEL_MENU_ITEM'
                                    onClick={() => {
                                        (this.props.GLOBAL_FUNCTION.onEditTypeNote as Function)(this.props.typeNote.id)
                                    }}
                                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.edit, 11) }}></span>
                            }



                            <span className='TN_CONTEXTUEL_MENU_ITEM'
                                onClick={() => {
                                    (this.props.GLOBAL_FUNCTION.onSelectTypeNotes as Function)([this.props.typeNote.id])
                                }}
                                dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.PutInFront, 16) }}></span>
                        </div>
                        <div>
                            {
                                (
                                    (
                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            siwm.canEdit &&
                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (siwm.idNote === null || siwm.idNote === undefined) &&
                                            (siwm.idType === null || siwm.idType === undefined)
                                        ).length > 0
                                    )
                                    ||
                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                ) === true &&
                                <span className='TN_CONTEXTUEL_MENU_ITEM'
                                    onClick={() => {
                                        (this.props.GLOBAL_FUNCTION.deleteTypeNote as Function)(this.props.typeNote.id)
                                    }}
                                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.dash, 11) }}></span>
                            }



                            {
                                this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                (
                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.typeNote.idGroupNote === g.id && g.isAuthor).length > 0
                                ) &&
                                <span className='TN_CONTEXTUEL_MENU_ITEM'
                                    onClick={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowShareBox as Function)(ENU_SHARE_TYPE.type, this.props.typeNote)
                                    }}
                                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 16) }}></span>
                            }


                        </div>
                    </div>
                }


                {
                    (this.props.GLOBAL_DATA.editType === undefined ||
                        (this.props.GLOBAL_DATA.editType !== undefined && this.props.GLOBAL_DATA.editType !== this.props.typeNote.id)) &&
                    <span className='TEXT_TICKET' onClick={() => {
                        (this.props.GLOBAL_FUNCTION.onSelectTypeNote as Function)(this.props.typeNote.id)
                    }}>

                        {
                            (this.props.GLOBAL_DATA.allNotifs.filter(notif =>
                                notif.typeId === this.props.typeNote.id
                            ).length > 0) &&
                            <span className="TN_MENU_SELECTED_INDICATOR" style={{ top: "7px", left: "-14px" }}>

                            </span>
                        }


                        {
                            <span>


                                {
                                    (
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        this.props.GLOBAL_DATA.selectedGroup.isAuthor === 1 &&
                                        this.props.GLOBAL_DATA.sharedItems.filter(si =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            si.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (si.idNote === null || si.idNote === undefined) &&
                                            (si.idType !== undefined && si.idType === this.props.typeNote.id)
                                        ).length > 0
                                    ) &&
                                    <span>
                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 11) }}>
                                        </span>
                                        <span className="SPAN_SEP">
                                        </span>
                                    </span>
                                }

                                {
                                    (
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        this.props.GLOBAL_DATA.selectedGroup.isAuthor === 0 &&
                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            si.canEdit &&
                                            si.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (si.idNote === null || si.idNote === undefined) &&
                                            (si.idType !== undefined && si.idType === this.props.typeNote.id)
                                        ).length > 0
                                    ) &&
                                    <span>
                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.edit, 11) }}>
                                        </span>
                                        <span className="SPAN_SEP">
                                        </span>
                                    </span>
                                }

                                {
                                    (
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        this.props.GLOBAL_DATA.selectedGroup.isAuthor === 0 &&
                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                            !si.canEdit &&
                                            si.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (si.idNote === null || si.idNote === undefined) &&
                                            (si.idType !== undefined && si.idType === this.props.typeNote.id)
                                        ).length > 0
                                    ) &&
                                    <span>
                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.see, 11) }}>
                                        </span>
                                        <span className="SPAN_SEP">
                                        </span>
                                    </span>
                                }
                            </span>
                        }
                        {
                            IsTypeNoteIsAnCupBord(this.props.typeNote.title) &&
                            <span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}>
                                </span>
                                <span className='SPAN_SEP'></span>
                            </span>

                        }

                        {
                            !IsTypeNoteIsAnCupBord(this.props.typeNote.title) &&
                            <span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 11) }}>
                                </span>
                                <span className='SPAN_SEP'></span>
                            </span>

                        }

                        <span >{
                            IsTypeNoteIsAnCupBord(this.props.typeNote.title) ? this.props.typeNote.title.replace(CupBoardCode, "") : this.props.typeNote.title
                        }</span>


                        <span className='SPAN_SEP'></span>
                        <span style={{ fontSize: "11px" }}>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === this.props.typeNote.id).length
                            }
                        </span>


                        {

                            this.props.GLOBAL_DATA.allNotifs.filter(notif => notif.typeId === this.props.typeNote.id).length > 0 &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.notif, 11) }}>
                                </span>
                            </span>

                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                }

                {
                    ((this.props.GLOBAL_DATA.editType !== undefined && this.props.GLOBAL_DATA.editType === this.props.typeNote.id)) &&
                    <div className='TEXT_TICKET' style={{ textAlign: "left" }}>
                        <div>
                            <FIELD_TEXT ref={this.ref_Field_Text} title='Titre' placeholder='Titre de la note' value={IsTypeNoteIsAnCupBord(this.props.typeNote.title) ? this.props.typeNote.title.replace(CupBoardCode, "") : this.props.typeNote.title} F_Action={() => {

                            }} required={true} />
                        </div>
                        <div>
                            <FIELD_CHECK_BOX ref={this.ref_Field_Check} title='Classeur' defaultValue={IsTypeNoteIsAnCupBord(this.props.typeNote.title)} />
                        </div>
                        <div className="COLOR_BOX">
                            {
                                ColorsPicker.filter(c => c.colorType === ENU_COLOR_TYPE.moderne)
                                    .sort((a, b) => { return a.color.localeCompare(b.color) })
                                    .map(c => {
                                        return <span key={c.color} className="COLOR_ITEM" onClick={() => {
                                            this.onChangeColorType(c.color);
                                        }}>
                                            <span className="COLOR_VALUE" style={{ backgroundColor: "#" + c.color }}></span>
                                        </span>
                                    })
                            }
                        </div>
                        <div>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                let tmpTN: I_TYPENOTE = this.props.typeNote;
                                tmpTN.title = this.ref_Field_Text.current !== null ? this.ref_Field_Text.current.state.value : this.props.typeNote.title;

                                if (this.ref_Field_Check.current !== null && this.ref_Field_Check.current.state.checked) {
                                    tmpTN.title = CupBoardCode + tmpTN.title.replace(CupBoardCode, "")
                                } else {
                                    tmpTN.title = tmpTN.title.replace(CupBoardCode, "");
                                }

                                tmpTN.colorString = this.state.selectedColor;

                                (this.props.GLOBAL_FUNCTION.onUpdateTypeNote as Function)(this.props.typeNote)
                            }}>Sauvegarder</span>
                            <span className='APP_FOLDER_TABLE_BUTTON' onClick={() => {
                                (this.props.GLOBAL_FUNCTION.onCancelEditTypeNote as Function)()
                            }}>Annuler</span>
                        </div>
                    </div>
                }

            </span>
        </div>
    }
}