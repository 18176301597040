import * as React from "react";
import { I_CALENDAR_ITEM, I_TASK_CALENDAR_PROPS, I_TASK_CALENDAR_PROPS_STATE } from "../../../02_Interfaces/I_TASK_CALENDAR";
import { GetDateObjectFromString, GetDistinctListOfString, GetIconFromType, GetNoteCustParentIdFromTitle, GoToElement, IsCustForm } from "../../../04_TOOLS/tools";
import { I_GROUPE_NOTE_ITEM, I_NOTE_ITEM, I_TASK_ITEM } from "../../../02_Interfaces/INOTE";
import { DB_DAY_NAME, DB_MONTH_NAME } from "../../../01_APPDATA/DB_APP";
import { ENU_ICON_TYPE } from "../../../02_Interfaces/IGLOBAL";
import { I_MSG_OPTION } from "../../../02_Interfaces/IMSGBOX";


export class TASK_CALENDAR extends React.Component<I_TASK_CALENDAR_PROPS, I_TASK_CALENDAR_PROPS_STATE> {

    constructor(props: I_TASK_CALENDAR_PROPS) {
        super(props);

        this.state = {
            calendarItems: [],
            selectedMonth: [(new Date()).getMonth()],
            selectedYear: [(new Date()).getFullYear()],
        }

        this.onGenerateCalendar = this.onGenerateCalendar.bind(this);
        this.onSelectYear = this.onSelectYear.bind(this);
        this.onSelectMonth = this.onSelectMonth.bind(this);
        this.onSelectAllYear = this.onSelectAllYear.bind(this);
        this.onSelectAllMonth = this.onSelectAllMonth.bind(this);
    }

    onSelectAllYear(value: boolean) {
        if (value) {
            this.setState({ selectedYear: (GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.year.toString() })).map(y => { return parseInt(y) })) });
        } else {
            this.setState({ selectedYear: [] });
        }
    }

    onSelectAllMonth(value: boolean) {
        if (value) {
            this.setState({ selectedMonth: (GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.month.toString() })).map(m => { return parseInt(m) })) });
        } else {
            this.setState({ selectedMonth: [] });
        }
    }

    onSelectYear(year: number) {
        if (this.state.selectedYear.filter(y => y === year).length > 0) {
            this.setState({ selectedYear: this.state.selectedYear.filter(y => y !== year) });
        } else {
            this.setState({ selectedYear: this.state.selectedYear.concat([year]) });
        }
    }

    onSelectMonth(month: number) {
        if (this.state.selectedMonth.filter(m => m === month).length > 0) {
            this.setState({ selectedMonth: this.state.selectedMonth.filter(m => m !== month) });
        } else {
            this.setState({ selectedMonth: this.state.selectedMonth.concat([month]) });
        }
    }

    getEmptyCalendarItem = (count: number) => {
        let emptyCalendarItem: I_CALENDAR_ITEM[] = [];
        for (let x = 0; x < count; x++) {
            emptyCalendarItem.push({
                year: 0,
                month: 0,
                date: 0,
                day: 0,
                relatedNote: [],
                tasks: []
            })
        }

        return emptyCalendarItem;
    }

    onGenerateCalendar = () => {

        // (this.props.GLOBAL_FUNCTION.onClearAllFilterOnNotes as Function)();
        (this.props.GLOBAL_FUNCTION.onUpdateLocalThread as Function)();

        if (
            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined
        ) {

            let tmpCalendar: I_CALENDAR_ITEM[] = [];
            (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(true);

            for (let w = 0; w < this.props.GLOBAL_DATA.DB_FILTERED_NOTES.length; w++) {
                let n: I_NOTE_ITEM = this.props.GLOBAL_DATA.DB_FILTERED_NOTES[w];

                if (this.props.GLOBAL_DATA.DB_TASKS !== undefined) {
                    let tmpTask: I_TASK_ITEM[] = this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === n.idnote);

                    for (let x = 0; x < tmpTask.length; x++) {
                        let t: I_TASK_ITEM = tmpTask[x];
                        if (tmpCalendar.filter(c => c.year === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getFullYear() : GetDateObjectFromString(n.created).dateObj.getFullYear()) &&
                            c.month === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getMonth() : GetDateObjectFromString(n.created).dateObj.getMonth()) &&
                            c.date === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getDate() : GetDateObjectFromString(n.created).dateObj.getDate())
                        ).length > 0) {
                            tmpCalendar.filter(c =>

                                c.year === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getFullYear() : GetDateObjectFromString(n.created).dateObj.getFullYear()) &&
                                c.month === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getMonth() : GetDateObjectFromString(n.created).dateObj.getMonth()) &&
                                c.date === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getDate() : GetDateObjectFromString(n.created).dateObj.getDate()))[0].tasks.push(
                                    {
                                        idtask: t.idtask,
                                        idnote: t.idnote,
                                        title: t.title,
                                        taskdate: t.taskdate.length > 0 ? t.taskdate : n.created,
                                        finished: t.finished
                                    }
                                );

                            tmpCalendar.filter(c =>

                                c.year === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getFullYear() : GetDateObjectFromString(n.created).dateObj.getFullYear()) &&
                                c.month === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getMonth() : GetDateObjectFromString(n.created).dateObj.getMonth()) &&
                                c.date === (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getDate() : GetDateObjectFromString(n.created).dateObj.getDate()))[0].relatedNote.push(
                                    {
                                        key: n.idnote.toString(),
                                        value: n.type.toString()
                                    }
                                );

                        } else {
                            tmpCalendar.push({
                                year: (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getFullYear() : GetDateObjectFromString(n.created).dateObj.getFullYear()),
                                month: (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getMonth() : GetDateObjectFromString(n.created).dateObj.getMonth()),
                                date: (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getDate() : GetDateObjectFromString(n.created).dateObj.getDate()),
                                day: (t.taskdate.length > 0 ? GetDateObjectFromString(t.taskdate).dateObj.getDay() : GetDateObjectFromString(n.created).dateObj.getDay()),
                                relatedNote: [{ key: n.idnote.toString(), value: n.type.toString() }],
                                tasks: [
                                    {
                                        idtask: t.idtask,
                                        idnote: t.idnote,
                                        title: t.title,
                                        taskdate: t.taskdate.length > 0 ? t.taskdate : n.created,
                                        finished: t.finished
                                    }
                                ]
                            })
                        }
                    }
                }
            }

            //Recherche de la borne de début
            //------------------------------
            let tmpStartCalendarItem: I_CALENDAR_ITEM = tmpCalendar.sort((a, b) => { return (new Date(a.year, a.month, a.date)).getTime() - (new Date(b.year, b.month, b.date)).getTime() })[0];
            let tmpEndCalendarItem: I_CALENDAR_ITEM = tmpCalendar.sort((a, b) => { return (new Date(a.year, a.month, 31)).getTime() - (new Date(b.year, b.month, 31)).getTime() })[tmpCalendar.length - 1];

            if (tmpStartCalendarItem !== undefined && tmpEndCalendarItem !== undefined) {
                let startDate: Date = new Date((new Date(tmpStartCalendarItem.year, tmpStartCalendarItem.month, tmpStartCalendarItem.date)).setMonth((new Date(tmpStartCalendarItem.year, tmpStartCalendarItem.month, tmpStartCalendarItem.date)).getMonth() - 1));
                let endDate: Date = new Date((new Date(tmpEndCalendarItem.year + 1, tmpEndCalendarItem.month, tmpStartCalendarItem.month, tmpStartCalendarItem.date)).setMonth((new Date(tmpEndCalendarItem.year + 1, tmpEndCalendarItem.month, tmpStartCalendarItem.date)).getMonth() + 2));


                let finalCalendar: I_CALENDAR_ITEM[] = [];

                //Complétion du début du calendrier
                //=================================
                for (let x = startDate.getTime(); x <= endDate.getTime(); x = (new Date(x)).setDate((new Date(x).getDate() + 1))) {
                    finalCalendar.push({
                        year: (new Date(x)).getFullYear(),
                        month: (new Date(x)).getMonth(),
                        date: (new Date(x)).getDate(),
                        day: (new Date(x)).getDay(),
                        relatedNote: tmpCalendar.filter(c => c.year === (new Date(x)).getFullYear() && c.month === (new Date(x)).getMonth() && c.date === (new Date(x)).getDate()).length > 0 ?
                            tmpCalendar.filter(c => c.year === (new Date(x)).getFullYear() && c.month === (new Date(x)).getMonth() && c.date === (new Date(x)).getDate())[0].relatedNote : [],
                        tasks: tmpCalendar.filter(c => c.year === (new Date(x)).getFullYear() && c.month === (new Date(x)).getMonth() && c.date === (new Date(x)).getDate()).length > 0 ?
                            tmpCalendar.filter(c => c.year === (new Date(x)).getFullYear() && c.month === (new Date(x)).getMonth() && c.date === (new Date(x)).getDate())[0].tasks : []
                    })
                }

                this.setState({ calendarItems: finalCalendar }, () => {
                    //Scroll jusqu'a la date du jour
                    //==============================
                    GoToElement((new Date()).getFullYear() + "-" + (new Date()).getMonth());
                    (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                });

            }
        }


    }

    componentDidUpdate(prevProps: Readonly<I_TASK_CALENDAR_PROPS>, prevState: Readonly<I_TASK_CALENDAR_PROPS_STATE>, snapshot?: any): void {

    }

    componentDidMount(): void {
        // (this.props.GLOBAL_FUNCTION.onClearAllFilterOnNotes as Function)();
        this.onGenerateCalendar();

    }


    render(): JSX.Element {
        return <div>

            <div>
                <div>
                    <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                        this.state.selectedYear.length === GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.year.toString() })).length ?
                            this.onSelectAllYear(false) :
                            this.onSelectAllYear(true)
                    }}>
                        {
                            this.state.selectedYear.length === GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.year.toString() })).length ?
                                "Tout désélectionner" : "Tout sélectionner"
                        }

                    </span>
                    {
                        GetDistinctListOfString(this.state.calendarItems.map(y => { return y.year.toString() })).map(y => {
                            return <span className={this.state.selectedYear.filter(sy => sy === parseInt(y)).length > 0 ? "CALENDAR_YEAR_SELECTOR_BUTTON CALENDAR_YEAR_SELECTOR_BUTTON_SELECTED" : "CALENDAR_YEAR_SELECTOR_BUTTON"} onClick={() => this.onSelectYear(parseInt(y))}>
                                <span>{
                                    y
                                }</span>
                                {
                                    this.state.calendarItems.filter(ci => ci.year === parseInt(y) &&
                                        (
                                            this.props.filtered &&
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined ?
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(_n => ci.tasks.filter(t => t.idnote === _n.idnote).length > 0).length > 0 :
                                                ci.tasks.length > 0
                                        )
                                    ).length > 0 &&
                                    <span className="SPAN_SEP"></span>
                                }
                            </span>
                        })
                    }</div>
                <div>
                    <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                        this.state.selectedMonth.length === GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.month.toString() })).length ?
                            this.onSelectAllMonth(false) :
                            this.onSelectAllMonth(true)
                    }}>
                        {
                            this.state.selectedMonth.length === GetDistinctListOfString(this.state.calendarItems.map(ci => { return ci.month.toString() })).length ?
                                "Tout désélectionner" : "Tout sélectionner"
                        }
                    </span>
                    {
                        GetDistinctListOfString(this.state.calendarItems.map(m => { return m.month.toString() }))
                            .sort((a, b) => parseInt(a) - parseInt(b))
                            .map(m => {
                                return <span className={this.state.selectedMonth.filter(sm => sm === parseInt(m)).length > 0 ? "CALENDAR_MONTH_SELECTOR_BUTTON CALENDAR_MONTH_SELECTOR_BUTTON_SELECTED" : "CALENDAR_MONTH_SELECTOR_BUTTON"} onClick={() => this.onSelectMonth(parseInt(m))}>
                                    <span>{
                                        DB_MONTH_NAME[parseInt(m)]
                                    }</span>
                                    {
                                        this.state.calendarItems.filter(ci => ci.month === parseInt(m) && this.state.selectedYear.filter(y => y === ci.year && ci.month === parseInt(m) && ci.tasks.length > 0 && ci.tasks.filter(t => t.finished).length > 0).length > 0).length > 0 &&
                                        <span className="SPAN_SEP" style={{ backgroundColor: "grey" }}></span>
                                    }

                                    {
                                        !this.props.filtered &&
                                        this.state.calendarItems.filter(ci => ci.month === parseInt(m) && this.state.selectedYear.filter(y => y === ci.year && ci.month === parseInt(m) && ci.tasks.length > 0 && ci.tasks.filter(t => !t.finished).length > 0).length > 0).length > 0 &&
                                        <span className="SPAN_SEP" style={{ backgroundColor: "red" }}></span>
                                    }

                                    {
                                        this.props.filtered &&
                                        this.state.calendarItems.filter(ci => ci.month === parseInt(m) && this.state.selectedYear.filter(y => y === ci.year && ci.month === parseInt(m) && ci.tasks.length > 0 && ci.tasks.filter(t => !t.finished && this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined && this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote).length > 0).length > 0).length > 0).length > 0 &&
                                        <span className="SPAN_SEP" style={{ backgroundColor: "red" }}></span>
                                    }
                                </span>
                            })
                    }</div>

            </div>

            {
                this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.task &&
                <div className='NOTE_VIEW_TITLE_BOX' style={{ position: "relative", display: "block", top: "22px" }}>
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.calendar, 16) }}></span>
                    <span className='SPAN_SEP'></span>
                    <span>Le calendrier du carnet '</span>
                    <span className='NOTE_VIEW_TITLE_ITEM'>{
                        (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).title
                    }</span>
                    <span>'</span>
                </div>
            }

            {
                GetDistinctListOfString(this.state.calendarItems
                    .filter(ci => this.state.selectedYear.filter(_y => _y === ci.year).length > 0)
                    .map(ci => { return ci.year.toString() }))
                    .sort((a, b) => { return b.localeCompare(a) }).map(y => {
                        return <div>
                            {
                                GetDistinctListOfString(this.state.calendarItems.filter(ci => ci.year === parseInt(y) &&
                                    this.state.selectedMonth.filter(_m => _m === ci.month).length > 0)
                                    .map(ci => { return ci.month.toString() }))
                                    .sort((a, b) => { return parseInt(b) - parseInt(a) }).map(m => {
                                        return <div>
                                            <div className="CALENDAR_HEADER">

                                                <span className="CALENDAR_MONTH_TITLE">{
                                                    DB_MONTH_NAME[parseInt(m)]
                                                }</span>

                                                <div className="CALENDAR_YEAR_TITLE">
                                                    {
                                                        y
                                                    }
                                                </div>
                                            </div>

                                            {
                                                this.getEmptyCalendarItem(
                                                    (
                                                        this.state.calendarItems.filter(ci => ci.year === parseInt(y) && ci.month === parseInt(m))[0].day
                                                    )
                                                ).map(eci => {
                                                    return <div className="CALENDAR_ITEM_EMPTY_BOX" style={{ borderColor: "transparent" }}>

                                                    </div>
                                                })
                                            }

                                            {
                                                this.state.calendarItems
                                                    .filter(ci => ci.year === parseInt(y) && ci.month === parseInt(m))
                                                    .map(ci => {
                                                        return <div id={y + "-" + m} className={(new Date()).getMonth() === parseInt(m) &&
                                                            (new Date()).getFullYear() === parseInt(y) &&
                                                            (new Date()).getDate() === ci.date ? "CALENDAR_ITEM_BOX CALENDAR_ITEM_THIS_DAY_BOX" : "CALENDAR_ITEM_BOX"}
                                                            style={{ backgroundColor: ci.day === 6 || ci.day === 0 ? "#e1e1e1" : undefined }}>
                                                            <div className="CALENDAR_DATE_TITLE">
                                                                <span className="CALENDAR_DAY_TITLE">
                                                                    {
                                                                        DB_DAY_NAME[ci.day]
                                                                    }
                                                                </span>
                                                                <span >{
                                                                    ci.date
                                                                }</span>
                                                            </div>
                                                            {
                                                                ci.tasks.length > 0 &&
                                                                ci.tasks
                                                                    .filter(t =>
                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote).length > 0
                                                                    )
                                                                    .map(t => {
                                                                        return <div style={{
                                                                            backgroundColor:
                                                                                (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].type) ?
                                                                                    "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].type)[0].colorString :
                                                                                    undefined),
                                                                            padding: "1px", position: "relative", width: "100%", borderRadius: "4px", marginBottom: "2px",
                                                                            border: t.finished ? "1px dashed #ffffff" : undefined, boxShadow: "2px 3px 5px #3c3c3c",
                                                                            display:
                                                                                t.finished && this.props.GLOBAL_DATA.DB_TASKS !== undefined ?
                                                                                    "none" : "block"
                                                                        }}>

                                                                            <div title={this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined ? this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].type)[0].title : undefined}
                                                                                onClick={() => {
                                                                                    if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                                        let tmpMSGOption: I_MSG_OPTION = {
                                                                                            allMSG: [
                                                                                                {
                                                                                                    key: "Que voulez-vous faire ?",
                                                                                                    value: "Voulez-vous prévisualiser la note, ou simplement aller à la note",
                                                                                                    data: ENU_ICON_TYPE.information
                                                                                                }
                                                                                            ],
                                                                                            buttons: [
                                                                                                {
                                                                                                    title: "Aller à la note",
                                                                                                    F_BUTTON_ACTION: () => {

                                                                                                        if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                                                            (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0]);
                                                                                                            (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                                                        }
                                                                                                    }
                                                                                                },
                                                                                                {
                                                                                                    title: "Prévisualiser la note",
                                                                                                    F_BUTTON_ACTION: () => {
                                                                                                        if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                                                            (this.props.GLOBAL_FUNCTION.onPreviewNote as Function)(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0]);
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            showCloseButton: true
                                                                                        };

                                                                                        (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption);
                                                                                    }
                                                                                }} className="TEXT_TICKET" style={{
                                                                                    display: "block", fontWeight: "normal",
                                                                                    borderLeft: t.taskdate.length === 0 ? "5px dotted #ffffff" : undefined
                                                                                }}>

                                                                                {
                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                    IsCustForm(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                                                    parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title)) !== t.idnote &&
                                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                                                }

                                                                                <span style={{ fontWeight: "bold" }}>
                                                                                    {
                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                        ((IsCustForm(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                                                            parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title)) !== t.idnote) ?
                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title.split("]-")[1] :
                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0].title)
                                                                                    }
                                                                                </span>
                                                                                <span className="SPAN_SEP"></span>
                                                                                <span>{
                                                                                    t.title.indexOf("]:") > -1 ? t.title.split("]:")[1] : t.title
                                                                                }</span>
                                                                                <span style={{ fontWeight: "bold" }}>

                                                                                    {
                                                                                        t.finished &&
                                                                                        <span>
                                                                                            <span className="SPAN_SEP"></span>
                                                                                            <span>"(Terminée)"</span>
                                                                                        </span>
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                            }

                                                            {
                                                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.finished && ci.relatedNote.filter(rn => parseInt(rn.key) === t.idnote).length > 0).map(t => {
                                                                    return <div style={{
                                                                        backgroundColor:
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined ?
                                                                                "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_t => ci.relatedNote.filter(rn => t.idnote === parseInt(rn.key) && parseInt(rn.value) === _t.id).length > 0)[0].colorString
                                                                                : undefined,
                                                                        maskPosition: "relative",
                                                                        padding: "1px", display: "block", position: "relative", width: "100%", borderRadius: "4px", marginBottom: "2px",
                                                                        border: "1px dashed #ffffff",
                                                                        opacity: "0.4"
                                                                    }} onClick={() => {
                                                                        if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                            let tmpMSGOption: I_MSG_OPTION = {
                                                                                allMSG: [
                                                                                    {
                                                                                        key: "Que voulez-vous faire ?",
                                                                                        value: "Voulez-vous prévisualiser la note, ou simplement aller à la note",
                                                                                        data: ENU_ICON_TYPE.information
                                                                                    }
                                                                                ],
                                                                                buttons: [
                                                                                    {
                                                                                        title: "Aller à la note",
                                                                                        F_BUTTON_ACTION: () => {

                                                                                            if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                                                (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0]);
                                                                                                (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        title: "Prévisualiser la note",
                                                                                        F_BUTTON_ACTION: () => {
                                                                                            if (this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined) {
                                                                                                (this.props.GLOBAL_FUNCTION.onPreviewNote as Function)(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote)[0]);
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ],
                                                                                showCloseButton: true
                                                                            };

                                                                            (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption);
                                                                        }
                                                                    }} >
                                                                        <div className="TEXT_TICKET" style={{ display: "block" }}
                                                                            title={this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined ? this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].type)[0].title : undefined}>
                                                                            {
                                                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                                                parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)) !== t.idnote &&
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                                            }

                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                {
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    ((IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                                                        parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)) !== t.idnote) ?
                                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title.split("]-")[1] :
                                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)
                                                                                }
                                                                            </span>
                                                                            <span className="SPAN_SEP"></span>
                                                                            <span>{
                                                                                t.title.indexOf("]:") > -1 ? t.title.split("]:")[1] : t.title
                                                                            }</span>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }

                                                            {
                                                                ci.tasks.length === 0 &&
                                                                <div>
                                                                    &nbsp;
                                                                </div>
                                                            }
                                                        </div>
                                                    })
                                            }
                                        </div>
                                    })
                            }
                        </div>
                    })
            }
        </div>
    }
}