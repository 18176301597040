import { Guid } from "guid-typescript";
import { I_HELP_ITEM } from "../02_Interfaces/I_HELP";
import { ENU_IMG } from "./DB_IMG";
import { ENU_ICON_TYPE } from "../02_Interfaces/IGLOBAL";

export const DB_HELP: I_HELP_ITEM[] = [
    {
        id: Guid.create().toString(),
        title: "Carnet",
        IMG:undefined,
        icon:ENU_ICON_TYPE.folder,
        child:[
            {
                id:Guid.create().toString(),
                title:"Créer un carnet",
                IMG:ENU_IMG.HELP_CREATE_FOLDER,
                icon:ENU_ICON_TYPE.newItem
            },
            {
                id:Guid.create().toString(),
                title:"Modifier le titre du carnet",
                IMG:ENU_IMG.HELP_EDIT_FOLDER,
                icon:ENU_ICON_TYPE.edit
            },
            {
                id:Guid.create().toString(),
                title:"Mettre le carnet en favoris",
                IMG:ENU_IMG.HELP_SET_IN_FAVORIS,
                icon:ENU_ICON_TYPE.favorites
            },
            {
                id:Guid.create().toString(),
                title:"Afficher les carnets favoris",
                IMG:ENU_IMG.HELP_SHOW_FOLDRR_FAVORIS,
                icon:ENU_ICON_TYPE.favorites
            },
            {
                id:Guid.create().toString(),
                title:"Afficher tous mes carnets",
                IMG:ENU_IMG.HELP_SHOW_ONLYMINE_FAVORIS,
                icon:ENU_ICON_TYPE.admin
            },
        ]
    }
]