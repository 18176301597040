import * as React from "react";
import { I_ANNUAIRE_ITEM, I_ANNUAIRE_POPUP_PROPS, I_ANNUAIRE_POPUP_PROPS_STATE } from "../../02_Interfaces/IANNUAIRE";
import { ENU_ICON_TYPE, I_RESULT } from "../../02_Interfaces/IGLOBAL";
import { AcceptTheInvitation, AddContactForUer, DeleteContact, GetAllPublicUser, GetAllUserContacts, GetCurrentUserContacts } from "../../04_TOOLS/DB_WS";
import { GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/formGlobal.css";
import "../../99_Styles/formAnnuaire.css";
import { APP_BUTTON } from "../Components/BUTTON";
import { SEARCHBOX } from "../Components/SEARCHBOX";
import { I_User } from "../../02_Interfaces/IUser";
import { I_CONTACT_ITEM } from "../../02_Interfaces/ICONTACT";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";


export class ANNUAIRE_POPUP extends React.Component<I_ANNUAIRE_POPUP_PROPS, I_ANNUAIRE_POPUP_PROPS_STATE>{

    constructor(props: I_ANNUAIRE_POPUP_PROPS) {
        super(props);

        this.state = {
            annuaireItems: [],
            userContacts: [],
            searchKey: ""
        }

        this.addUserToContact = this.addUserToContact.bind(this);
        this.loadData = this.loadData.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.onSearchContact = this.onSearchContact.bind(this);
    }

    //============================
    //Méthodes et fonctions utiles
    //============================

    //On search contacts
    //------------------
    onSearchContact(value: string) {
        this.setState({ searchKey: value });
    }

    //Invitation de l'utilisateur
    //--------------------------
    addUserToContact(annItem: I_ANNUAIRE_ITEM) {
        let msgOption: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Invitation",
                    value: "Voulez vous réellement inviter '" + annItem.firstname + " " + annItem.lastname + "'",
                    data: ENU_ICON_TYPE.mail
                }
            ],
            showCloseButton: true,
            buttons: [
                {
                    title: "Inviter",
                    F_BUTTON_ACTION: () => {
                        AddContactForUer((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, annItem.iduser, (data: I_RESULT) => {
                            if (data.status) {
                                this.loadData();
                                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                            } else {
                                //-->
                            }
                        })
                    }
                }
            ]
        };

        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);
    }

    //Acceptation de l'invitation
    //---------------------------
    acceptTheInvitation(idContact: number) {
        let msgOption: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Invitation",
                    value: "Voulez vous réellement accepter l'invitation ?",
                    data: ENU_ICON_TYPE.mail
                }
            ],
            showCloseButton: true,
            buttons: [
                {
                    title: "Accepter",
                    F_BUTTON_ACTION: () => {
                        AcceptTheInvitation(idContact, (data: I_RESULT) => {
                            if (data.status) {
                                this.loadData();
                                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                            } else {
                                //-->
                            }
                        })
                    }
                }
            ]
        };

        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);

    }

    //Suppression du contact
    //------------------------
    deleteContact(idContact: number) {
        let msgOption: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Retirer le contact",
                    value: "Voulez vous réellement supprimer le contact, annuler ou retirer l'invitation ?",
                    data: ENU_ICON_TYPE.mail
                }
            ],
            showCloseButton: true,
            buttons: [
                {
                    title: "Retirer",
                    F_BUTTON_ACTION: () => {
                        DeleteContact(idContact, (data: I_RESULT) => {
                            if (data.status) {
                                this.loadData();
                                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                            } else {
                                //-->
                            }
                        })
                    }
                }
            ]
        };

        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);

    }

    //Chargement des données
    //----------------------
    loadData() {
        if (this.props.showOnlyMyContact) {
            GetAllPublicUser((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (data: I_RESULT) => {
                if (data.status) {
                    let tmpProfils: I_ANNUAIRE_ITEM[] = (data.data as I_ANNUAIRE_ITEM[]);
                    
                    //Recherche des contacts de l'utilisateur
                    GetCurrentUserContacts((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (_data: I_RESULT) => {
                        if (_data.status) {
                            //Nettoyage des données
                            //--------------------
                            let tmpData: I_CONTACT_ITEM[] = _data.data as I_CONTACT_ITEM[];
                            if (tmpData !== undefined) {

                                (tmpData).forEach(d => {
                                    d.approuved = (d.approuved as any) === 0 ? false : true;
                                });

                                //Suppression des contacts ne concernant pas l'utilisateur
                                tmpProfils = tmpProfils.filter(p=> tmpData.filter(d=>d.idCurrentUser === p.iduser || d.idUser === p.iduser).length>0);
                                //---------------------------------------------------------

                            } else {
                                tmpData = [];
                            }

                            //---------------------
                            this.setState({annuaireItems:tmpProfils, userContacts: tmpData }, () => {
                                // console.log(this.state.userContacts);
                            })
                        }else{
                            // console.log(_data);
                        }
                    })
                } else {
                    //-->
                }
            })
        } else {
            GetAllPublicUser((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (data: I_RESULT) => {
                if (data.status) {
                    let tmpProfils: I_ANNUAIRE_ITEM[] = (data.data as I_ANNUAIRE_ITEM[]);
                    // console.log(tmpProfils);
                    this.setState({
                        annuaireItems: tmpProfils
                    }, () => {
                        //Recherche des contacts de l'utilisateur
                        GetCurrentUserContacts((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (data: I_RESULT) => {
                            if (data.status) {
                                // console.log(data.data as I_CONTACT_ITEM[]);

                                //Nettoyage des données
                                //--------------------
                                let tmpData: I_CONTACT_ITEM[] = data.data as I_CONTACT_ITEM[];
                                if (tmpData !== undefined) {

                                    (tmpData).forEach(d => {
                                        d.approuved = (d.approuved as any) === 0 ? false : true;
                                    });

                                } else {
                                    tmpData = [];
                                }

                                //---------------------
                                this.setState({ userContacts: tmpData }, () => {
                                    // console.log(this.state.userContacts);
                                })
                            }
                        })
                    })
                } else {
                    //-->
                }
            })
        }
    }

    //Chargement initial
    //-------------------
    componentDidMount() {
        this.loadData();
    }
    //============================

    render(): JSX.Element {
        return <div>
            {/* font blanc non opaque  */}
            <div className="POPUP_LAYER" style={{ opacity: 0.4 }}></div>


            <div className="POPUP_BODY_BOX" style={{ top: "7%", bottom: "7%" }}>
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 24, "#c3c3c3") }}></span>
                        &nbsp;
                        {
                            this.props.showOnlyMyContact &&
                            <span>
                                <span>Mes contacts</span>
                            </span>
                        }
                        {
                            !this.props.showOnlyMyContact &&
                            <span>
                                <span>Annuaire</span>
                            </span>
                        }
                    </span>

                    {/* Bouton de fermeture de la popup */}
                    <div
                        className="POPUP_CLOSE_BUTTON" onClick={() => { (this.props.APP_GLOBAL_FUNCTION.onShowAnnuaire as Function)() }}
                        dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18) }}>
                    </div>
                </div>

                {/* Contenu du formulaire de gestion des propriétés du profil */}
                {
                    <div className="POPUP_BODY">

                        <div style={{ width: "100%", textAlign: "center" }}>
                            <SEARCHBOX APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} 
                            placeHolder={"Saisissez tout ou partie du nom ..."} forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={this.onSearchContact} />
                        </div>


                        <div className="ANN_ITEM_LIST_BOX">
                            {/* {
                                console.log(this.state.annuaireItems)
                            }
                            {
                                console.log(this.state.userContacts)
                            } */}

                            {
                                this.state.annuaireItems.filter(ai =>
                                    (ai.firstname as string).toUpperCase().indexOf(this.state.searchKey.toUpperCase()) > -1 ||
                                    (ai.lastname as string).toUpperCase().indexOf(this.state.searchKey.toUpperCase()) > -1
                                ).map(ai => {
                                    return <div key={ai.iduser_profil} className="ANN_ITEM_BOX">
                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 16) }}>

                                        </span>
                                        <span className="ANN_ITEM_USER_INFO">
                                            <span>
                                                {
                                                    ai.lastname
                                                }
                                            </span>
                                            <span>
                                                {
                                                    ai.firstname
                                                }
                                            </span>
                                            <span>
                                                {
                                                    ai.email
                                                }
                                            </span>
                                        </span>
                                        <span className="ANN_ITEM_USER_ACTIONS">
                                            {
                                                this.state.userContacts.filter(uc =>
                                                    (ai.iduser === uc.idUser && uc.author === 1) && !uc.approuved).length > 0 &&
                                                <APP_BUTTON buttonProps={
                                                    {
                                                        title: "Annuler l'invitation",
                                                        F_BUTTON_ACTION: () => {
                                                            this.deleteContact(this.state.userContacts.filter(uc => uc.idUser === ai.iduser && !uc.approuved)[0].idContact)
                                                        },
                                                        hover_backgroundColor: "red",
                                                        hover_textColor: "#ffffff"
                                                    }
                                                } />
                                            }
                                            {
                                                this.state.userContacts.filter(uc =>
                                                    ((ai.iduser === uc.idUser && uc.author === 1) ||
                                                        (ai.iduser === uc.idCurrentUser && uc.author === 0)) && uc.approuved).length > 0 &&
                                                <APP_BUTTON buttonProps={
                                                    {
                                                        title: "Retirer",
                                                        F_BUTTON_ACTION: () => {
                                                            this.deleteContact(this.state.userContacts.filter(uc => uc.idUser === ai.iduser && uc.approuved)[0].idContact);
                                                        },
                                                        hover_backgroundColor: "red",
                                                        hover_textColor: "#ffffff"
                                                    }
                                                } />
                                            }

                                            {
                                                this.state.userContacts.filter(uc =>
                                                    (ai.iduser === uc.idUser && uc.author === 1) ||
                                                    (ai.iduser === uc.idCurrentUser && uc.author === 0)).length === 0 &&
                                                <APP_BUTTON buttonProps={
                                                    {
                                                        title: "Inviter",
                                                        F_BUTTON_ACTION: () => {
                                                            this.addUserToContact(ai)
                                                        }
                                                    }
                                                } />
                                            }

                                            {
                                                this.state.userContacts.filter(uc =>
                                                    (ai.iduser === uc.idCurrentUser && uc.author === 0) && !uc.approuved).length > 0 &&
                                                <APP_BUTTON buttonProps={
                                                    {
                                                        title: "Accepter",
                                                        F_BUTTON_ACTION: () => {
                                                            this.acceptTheInvitation(this.state.userContacts.filter(uc => ai.iduser === uc.idCurrentUser && !uc.approuved)[0].idContact);
                                                        }
                                                    }
                                                } />
                                            }

                                            {
                                                this.state.userContacts.filter(uc => ai.iduser === uc.idCurrentUser && !uc.approuved).length > 0 &&
                                                <APP_BUTTON buttonProps={
                                                    {
                                                        title: "Reffuser",
                                                        F_BUTTON_ACTION: () => {
                                                            this.deleteContact(this.state.userContacts.filter(uc => ai.iduser === uc.idCurrentUser && !uc.approuved)[0].idContact)
                                                        },
                                                        hover_backgroundColor: "red",
                                                        hover_textColor: "#ffffff"
                                                    }
                                                } />
                                            }


                                        </span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }


                {/* Zon d'actions profil */}
                {
                    <div className="POPUP_ACTION_ZONE">
                        <APP_BUTTON buttonProps={
                            {
                                title: "Fermer",
                                F_BUTTON_ACTION: () => {
                                    (this.props.APP_GLOBAL_FUNCTION.onShowAnnuaire as Function)();
                                },
                            }
                        } />

                    </div>
                }
            </div>

        </div>
    }
}