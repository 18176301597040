import { title } from "process";
import * as React from "react";
import { I_FIELD_RETYPEPASS_PROPS, I_FIELD_RETYPEPASS_PROPS_STATE } from "../../02_Interfaces/IFIELD";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/field.css";
import { FIELD_TEXT } from "./FIELD_TEXT";

export class FIELD_RETYPE_PASS extends React.Component<I_FIELD_RETYPEPASS_PROPS, I_FIELD_RETYPEPASS_PROPS_STATE>{

    constructor(props: I_FIELD_RETYPEPASS_PROPS) {
        super(props);
        this.state = {
            fieldIsOk: true
        }

        this.onValidateForm = this.onValidateForm.bind(this);
    }

    //Les références
    private refPW = React.createRef<FIELD_TEXT>();
    private refRPW = React.createRef<FIELD_TEXT>();
    //==============

    //Fonction et méthodes utiles
    //===========================
    onValidateForm = () => {
        if (this.refPW.current !== null && this.refRPW.current !== null) {

            this.refPW.current.onCheckFieldStatus();
            this.refRPW.current.onCheckFieldStatus();
            console.log(this.refPW.current.state);
            console.log(this.refRPW.current.state);
            console.log(this.refPW.current.state.value === this.refRPW.current.state.value)
            if (this.refPW.current.state.value === this.refRPW.current.state.value) {
                console.log(this.refPW.current.state.value);
                let tmpValue: string = this.refPW.current.state.value;
                console.log(tmpValue);
                this.setState({ fieldIsOk: true });
            } else {
                this.setState({ fieldIsOk: false });
            }
        }
    }

    getFinalPW() {
        if (this.refPW.current !== null && this.state.fieldIsOk) {
            return this.refPW.current.state.value;
        } else {
            return undefined
        }
    }

    //===========================

    render(): JSX.Element {
        return <div className="FIELD_ZONE">
            <div className="RP_BOX">
                <div className={"RP_TITLE_CLOSE_EDIT_MODE"} >
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.key, 16, "ffffff") }}></span>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span>{this.props.title}</span>
                </div>

                <form>
                    {/* Mot de passe */}
                    <FIELD_TEXT ref={this.refPW}
                        title="Mot de passe" required={true}
                        value={""}
                        asPassword={true}
                        maxLength={10}
                        minLength={8}
                    />

                    {/* Ressaisie du mot de passe */}
                    <FIELD_TEXT ref={this.refRPW}
                        title="Ressaissie du mot de passe"
                        required={true}
                        value={""}
                        asPassword={true}
                        maxLength={10}
                        minLength={8}
                    />
                </form>
                {
                    !this.state.fieldIsOk &&
                    < div >
                        Le mot de passe saisi est différent...
                    </div>
                }
                <div>

                </div>
            </div>
        </div >
    }
}