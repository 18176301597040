import * as React from "react";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { GetIconFromType } from "../../04_TOOLS/tools";

import "../../99_Styles/loader.css";
export class LOADERBOX extends React.Component<{}, {}>{
    
    render(): JSX.Element {
        return <div className="LOADER_BOX">
            <div className="LOADER_CONTAINER">
               <div>Chargement en cours...</div>
               <div className="LOADER_LOGO" dangerouslySetInnerHTML={{__html:GetIconFromType(ENU_ICON_TYPE.WASPNOTE,1.4,"ffffff")}}></div>
            </div>
        </div>
    }
}