import { I_ANNUAIRE_ITEM } from "./IANNUAIRE";
import { I_APP_PARAMS } from "./IAPP";
import { I_CONTACT_ITEM } from "./ICONTACT";
import { I_LOCAL_MEMORY } from "./ILOCAL_MEMORY";
import { I_MSGBOX_PROPS } from "./IMSGBOX";
import { I_ATTACHMENT_LINK_ITEM, I_FAVORIT_ITEM, I_GROUPE_NOTE_ITEM, I_NOTE_ITEM, I_NOTE_OVER_GROUP_ITEM, I_TASK_ITEM, I_TYPENOTE } from "./INOTE";
import { IPATH_ITEM } from "./IPATH";
import { I_SHARED_ITEM } from "./ISHARER";
import { I_User } from "./IUser";
import { I_USER_PROFIL_ITEM } from "./IUSERPROFIL";
import { ENU_WIDGET_MENU_TYPE } from "./IWIDGET";
import { I_COMPTA_ACCOUNT_ITEM, I_COMPTA_FORM_ITEM, I_COMPTA_FORM_NOTE } from "./I_COMPTA_FORM";
import { ENU_IMG } from "../01_APPDATA/DB_IMG";
import { ENU_REMINDER_UNITY } from "../04_TOOLS/tools";


export interface I_APP_REMINDER_TIME_TYPE{
    typeTime:ENU_REMINDER_UNITY,
    value:number,
}

export interface I_APP_GLOBAL_DATA {
    //Global
    appParams?: I_APP_PARAMS;
    MSG_OPTION?: I_MSGBOX_PROPS;
    //-----------------

    //Média
    DB_NOTES_VIDEO: number[];
    showVideo: boolean;
    showAttachment: boolean;
    showDashed: boolean;
    //-----------------

    //Application
    DB_GROUPS?: I_GROUPE_NOTE_ITEM[];
    DB_TYPES_NOTES?: I_TYPENOTE[];
    DB_NOTES?: I_NOTE_ITEM[];
    DB_FILTERED_NOTES?: I_NOTE_ITEM[];
    DB_TAGS?: string[];
    DB_CALENDAR?: I_NOTE_CALENDAR[];
    DB_ATTACHMENT_LINKS?: I_ATTACHMENT_LINK_ITEM[];
    DB_TASKS?: I_TASK_ITEM[];
    DB_FAVORITS?: I_FAVORIT_ITEM[];
    DB_CONFIG?: I_CONFIG_ITEM[];
    //Gestion des données de type compta
    //==================================
    _DB_COMPTA?: I_COMPTA_FORM_NOTE[];
    _DB_FILTERED_COMPTA?: I_COMPTA_FORM_NOTE[];
    _DB_COMPTA_ACCOUNT?: I_COMPTA_ACCOUNT_ITEM[];
    //==================================
    selectedNoteMenu: ENU_ICON_TYPE;
    selectedGroup?: I_GROUPE_NOTE_ITEM;
    savedGroupParam?: I_GROUPE_NOTE_ITEM[];
    selectedWidgetMenuType?: ENU_WIDGET_MENU_TYPE;
    folderIsOnEditMode: boolean;
    linkItemMode: boolean;
    selectedNoteToLink?: I_NOTE_ITEM;
    selectedType?: number[];
    selectedTag?: string[];
    editType?: number;
    editNoteMode: number[];
    showNextTasks: boolean;
    notePanelMaximized: boolean;
    copyNoteMode: boolean;
    noteToCopy?: I_NOTE_ITEM;
    targetGroupForCopy?: number[];
    loading: boolean;
    currentAppMemory: I_MEMORY_CONFIG[];
    needUpdateCounts: boolean;
    searchGroupKey: string;
    noteStartId: number;
    onlyMine: boolean;
    onlyShared: boolean;
    onlyFavorit: boolean;
    searchOnAllNotes: boolean;
    onlyPath: boolean;
    showChildNoteInTimeLine:boolean;
    //-----------------

    //Les filtres sur les notes
    //-------------------------
    filterOnTasks: boolean;
    filterOnPinnedNote: boolean;
    filyerOnReminderNote: boolean;
    filterFromMemory: boolean;
    openedNote: number[];//Collecte les notes ouvertes
    filterOnTagOnOf: boolean;
    filterByLastChange?: boolean;
    filterOnLocalMemory?:boolean;
    //-------------------------

    //Autres fonctionnalitées et popup
    showAppConfig: boolean;
    showUserProfil: boolean;
    showContacts: boolean;
    showAnnuaire: boolean;
    showAllPath: boolean;
    showOnlyMyContact: boolean;
    showSharer: boolean;
    showCompta: boolean;
    //=============

    //Utilisateur
    //-------------
    connectedUser?: I_User;
    userProfil?: I_USER_PROFIL_ITEM;
    //-------------

    //Les données de partage
    //----------------------
    userContacts: I_CONTACT_ITEM[];
    effectifProfils: I_ANNUAIRE_ITEM[];
    sharedItems: I_SHARED_ITEM[];
    sharedItemsWithMe: I_SHARED_ITEM[];
    shareType?: ENU_SHARE_TYPE;
    shareItemId?: number;

    //Memoire globale
    //---------------
    localMemory: I_LOCAL_MEMORY[];
    localMemoryToLoad?: I_LOCAL_MEMORY;
    tmpMemory?: I_LOCAL_MEMORY;

    //Menu de la mémoire locale et des dernières taches restantes datées
    //------------------------------------------------------------------
    pendingMenuSelected: ENU_ICON_TYPE;

    //Les notifications
    //=================
    allNotifs: I_NOTIF[];
    allNotifsNote: I_NOTE_ITEM[];
    allLockedNotes:I_NOTIF[];

    //Global search
    //=============
    globalSearchResult: I_NOTE_ITEM[];
    savedGlobalSearchKeyValue?: string;
    selectedGroupNoteResultId?: number[];
    DB_NOTES_OVER_GROUPS?: I_NOTE_OVER_GROUP_ITEM[];

    //Fil d'ariane
    dynPath: number[];
    filterByOne?: number;

    //Gestion des groupes ouvert
    openedGroup?: number[];

    //Tous les chemins
    allPath: IPATH_ITEM[];

    //Limite des volumes
    noteMaxSize: number;
    pjMaxSize: number;

    loadingPendingData: boolean;

    //ACTIONS INFOS
    actionsInfoPile: I_KEY_VALUE_DATA[];
    //-------------

    firstLoad: boolean;

    groupMemory: I_GROUP_MEMORY_STATE[];

    selectedFormToGenerate?: number,


    //Gestion de la mémoire du navigateur
    //Mécanisme de chargement et déchargement des notes affichées
    //------------------------------------------------------------
    memoryCacheSize: number;//Par défaut 70 notes seront chargées. Puis à la demande, plus de notes pourront s'afficher
    //Il est à noter que dans tous les cas il sera possible de rechercher dans les notes filtrée au dela de la limitation du cache

    folderFilterMemories: I_FOLDER_FILTER_MEMORY[];

    //Gestion des statuts de navigation
    //---------------------------------
    navigation:I_NAVIGATION_ITEM[];

    selectedDraggableElement?:I_NOTE_ITEM;

    pageMaxMode:boolean;

}

export interface I_NAVIGATION_ITEM{
    noteId:number;
    isChildOpened:boolean;
    isNoteOpened:boolean;
}

export interface I_FOLDER_FILTER_MEMORY {
    folderId: number;
    selectedTag: string[];
    selectedType: number[];
    filterByOn?: number;
    selectedNoteMenu: ENU_ICON_TYPE;
    searchKeyWordValue: string;
}

export interface I_GROUP_MEMORY_STATE {
    idGroup: number;
    selectedType: number[];
    selectegTag: string[];

}

export interface I_NOTE_CALENDAR {
    year: number,
    months: number[]
}

export interface I_NOTIF_ITEM {
    idNotif: number,
    readed: boolean,
}

export interface I_APP_GLOBAL_FUNCTIONS {
    closePopup?: Function;
    authentUser?: Function;
    getAllGroups?: Function;
    selectGroupNote?: Function;
    setFolderEditMode?: Function;
    updateFolderProps?: Function;
    showMessage?: Function;
    deleteGroupNote?: Function;
    selectWidgetMenu?: Function;
    checkTaskNote?: Function;
    updateTaskItem?: Function;
    piningNote?: Function;
    updateNote?: Function;
    deleteNote?: Function;
    deleteAttachment?: Function;
    uploadFile?: Function;
    selectNoteType?: Function;
    addNewNoteInGroup?: Function;
    addNewTaskInNoteGroup?: Function;
    updateTaskNote?: Function;
    setNoteType?: Function;
    deleteTask?: Function;
    showAttahment?: Function;
    loadAsyncAttachment?: Function;
    onLinkItemMode?: Function;
    onLinkItemToNote?: Function;
    onSelectTypeNote?: Function;
    onSelectTypeNotes?: Function;
    onEditTypeNote?: Function;
    onCancelEditTypeNote?: Function;
    onUpdateTypeNote?: Function;
    addNewTypeNote?: Function;
    deleteTypeNote?: Function;
    showNexTaskPanel?: Function;
    updateNoteTags?: Function;
    onSelectTag?: Function;
    onClearAttachmentsMemorry?: Function;
    addNewFolder?: Function;
    openCopyNoteMode?: Function;
    onSelectGroupForCopy?: Function;
    onCopyNoteToGroup?: Function;
    onSaveMemory?: Function;
    onDeleteMemory?: Function;
    onSelectMemory?: Function;
    onShowConfig?: Function;
    onShowUserProfil?: Function;
    onShowLoader?: Function;
    onEditNoteMode?: Function;
    onUpdatePW?: Function;
    onUserDeconnexion?: Function;
    getUserProfil?: Function;
    getAllPublicUser?: Function;
    onShowAnnuaire?: Function;
    onShowShareBox?: Function;
    onShareItem?: Function;
    onUpdateSharedItem?: Function;
    onLoadAllSharedItemsForUser?: Function;
    onDeleteSharedItem?: Function;
    onUpdatedCounts?: Function;
    onSearchGroup?: Function;
    onShowNoteOfCurrentTask?: Function;
    onLoadNoteData?: Function;
    onUnLoadNoteData?: Function;
    getAllFavorisGroups?: Function;
    onAddGroupToFavoris?: Function;
    onRemoveGroupToFavoris?: Function;
    onLoadNextNote?: Function;
    onOpenGroupNote?: Function;
    onLoadAllUserConfig?: Function;
    onUpdateOrCreateParam?: Function;
    onSelectNoteMenu?: Function;
    onPutNoteInCupBoard?: Function;
    onUpdateLocalThread?: Function;
    getCurrentThread?: Function;

    //Les filtres sur les notes
    //-------------------------
    onSelectTask_Only?: Function;
    onSelectReminder_Only?: Function;
    onSelectPinned_Only?: Function;
    onClearAllFilterOnNotes?: Function;
    onFilterOnTagOnOf?: Function;
    //-------------------------

    //La mémoire locale
    //-----------------
    onSaveLocalMemory?: Function;
    onLoadLocalMemory?: Function;
    onLoadNoteAsMemory?: Function;
    //-----------------

    // Les notifications
    //------------------
    onReadNotifElement?: Function;
    onAddUnreadNotifInPile?: Function;
    //-----------------


    //Les commentaires des notes
    onComentNote?: Function;
    onDeleteComentOfNote?: Function;
    //--------------------------

    //Les notes ouvertes
    onOpenedNote?: Function;
    //------------------


    //Média
    onShowVideo?: Function;
    //-------------------

    //Corbeille
    onPutInTrash?: Function;
    //------------------

    //Zone de recherche
    onSearchGlobal?: Function;
    onLoadSearchData?: Function;
    //-----------------

    //Gestion des notes liées entre carnets
    onAddNoteToAnOtherNote?: Function;
    //-------------------------------------

    onShowAllPath?: Function;

    addPath?: Function;
    deletePath?: Function;
    clearGroupPath?: Function;
    clearAllPath?: Function;

    clearSelectByOne?: Function;

    //INFO ACTION
    ShowInfoMessage?: Function;
    //-----------

    //Zone de classeur
    ShowCupBoardChild?: Function;
    SearchNoteInCupBoard?: Function;
    SelectTypeNoteInCubBoard?: Function;
    SelectTagNoteInCubBoard?: Function;
    //----------------

    //La gestion des groupes
    OnPreSelectFolder?: Function;
    //----------------------

    OnShowNoteOverGroup?: Function;
    OnDeleteLinkedNoteOverGroup?: Function;
    OnDeleteLocalCurrentMemory?: Function;

    onLoadAllComptaForm?: Function;

    onSelectFormToGenerate?: Function;

    onUpdateAllCustFormChild?: Function;

    onPreviewNote?: Function;

    onShowAppVersion?: Function;

    onUpdateNavigationStatuts?:Function;

    onSelectElementToDrag?:Function;

    onDeleteNoteLock?:Function;

    OnClearAllCupboardFilter?:Function;
}

export interface I_ICON_ITEM {
    id: number;
    icon: string;
    iconType: ENU_ICON_TYPE;
    scalValue: number;
}

export interface I_RESULT {
    status: boolean;
    message?: string;
    data?: any;
}

export interface I_KEY_VALUE_DATA {
    key: any,
    value: any,
    data: any
}

export interface KeyValuePair {
    key: string;
    value: string;
}

export interface I_COLOR_PICKER {
    id: number;
    color: string;
    colorType: ENU_COLOR_TYPE;
}

export interface I_DATE_OBJ {
    date: string;
    month: string;
    year: string;
    hour: string;
    min: string;
    dateString: string;
    hourString: string;
    dateObj: Date;
}

export interface I_MEMORY_CONFIG {
    id: string,
    selectedWidgetMenu: ENU_WIDGET_MENU_TYPE;
    selectedMenuNote: ENU_ICON_TYPE;
    selectedTags: string[];
    selectedType: number[];
    searchKeyValue: string;
    displayUnity?: number;
}

export interface I_KEYBOARD_FUNCTION {
    keyboardValue: string;
    F_Action: Function;
}

export interface I_NOTIF {
    idNotif: number;
    readed: boolean;
    author: string;
    groupId: number;
    typeId: number;
    noteId: number;
    asComment: boolean;
    userId: number;
    asLock:boolean;
    locker:boolean;
}

export interface I_APP_VERSION {
    v_1: number;
    v_2: number;
    v_3: number;
    appliTitle: string;
    description?: string;
    v_1_description?: string;
    v_2_description?: string;
    v_3_description?: string;
    img?: ENU_IMG
}

export interface I_CONFIG_ITEM {
    idConfig: number;
    configName: string;
    configValue: string;
}



export enum ENU_ICON_TYPE {
    close,
    information,
    user,
    //--------------------
    openedFolder,
    folder,
    editFolder,
    //--------------------
    edit,
    delete,
    save,
    saveAsLocal,
    lock,
    goBack,
    nothing,
    category,
    tag,
    note,
    task,
    reminder,
    ticket,
    book,
    attachment,
    link,
    branch,
    mail,
    childElement,
    clearFilter,
    markBook,
    filtered,
    checkedTask,
    unCheckedTask,
    uploadFile,
    newItem,
    search,
    see,
    unSee,
    linkedItem,
    unLinkedItem,
    principalItem,
    linkItemMode,
    colorPicker,
    timer,
    currentDate,
    validate,
    arrow_Left,
    arrow_Right,
    arrow_go_top,
    arrow_go_bottom,
    copy,
    bee,
    param,
    adress,
    favorites,
    notFavorit,
    error,
    saveFavorites,
    key,
    power,
    community,
    editor,
    authorItem,
    pendingtasks,
    reload,
    notif,
    admin,
    WASPNOTE,
    empty,
    goto,
    talk,
    send,
    home,
    selectedItem,
    video,
    dash,
    searchResult,
    reusedNote,
    arrowPath,
    appConfig,
    path,
    euroDevise,
    paid,
    cards,
    cashMoney,
    boxOfNote,
    packet,
    delivery,
    AppChange,
    AppEvolution,
    AppCorrection,
    PutInFront,
    help,
    cupBoard,
    hexagone,
    underConstruction,
    form_parent,
    form_child,
    form_sync,
    field_text,
    field_rich_text,
    field_brut_text,
    field_list,
    field_check,
    field_link,
    field_number,
    YES,
    NO,
    filter_asc,
    filter_desc,
    coofee,
    calendar,
    anchorIndocator,
    plus,
    less,
    certified,
    newElement,
    addStack,
    menuPlus,
    goTopOfNote,
    goDownOfNote,
    seeMore,
    timeLeft,
}

export enum ENU_ACTION_TYPE {
    looked, edited, taged, tasked, pined, fileAttachment, noteAttachment, dashed, copied, shared
}

export enum ENU_COLOR_TYPE {
    normal, moderne
}

export enum ENU_FILE_TYPE {
    picture, doc, excel, pdf, text, presentation, other, email
}

export enum ENU_SHARE_TYPE {
    groupe, type, note
}