import * as React from "react";
import { APPVERSIONREF } from "../../01_APPDATA/DB_APP_VERSION";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_HEADER_PROPS, I_HEADER_PROPS_STATE } from "../../02_Interfaces/IHEADER";
import { I_TYPENOTE } from "../../02_Interfaces/INOTE";
import { I_USER_PROFIL_ITEM } from "../../02_Interfaces/IUSERPROFIL";
import { CupBoardCode, GetDistinctListOfString, GetIconFromType, IsTypeNoteIsAnCupBord } from "../../04_TOOLS/tools";
import { DB_HELP } from "../../01_APPDATA/DB_HELP";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { ENU_IMG } from "../../01_APPDATA/DB_IMG";
import { SEARCHBOX } from "./SEARCHBOX";
import "../../99_Styles/formGlobal.css";
import { APROPOS, CGU } from "../../01_APPDATA/DB_APP_CONFIG";
import { GetNote } from "../../04_TOOLS/DB_WS";
import { ORDO_PROCESS_ITEM } from "../../04_TOOLS/WASPORDO";
// import "../../99_Styles/WASP_NEW_01/app_init.css";

export class HEADER extends React.Component<I_HEADER_PROPS, I_HEADER_PROPS_STATE> {
    constructor(props: I_HEADER_PROPS) {
        super(props);

        this.state = {
            currentDateTitme: (new Date).toString(),
            notifInfo: []
        }
    }


    componentDidMount() {
        setInterval(async () => {
            this.setState({ currentDateTitme: (new Date).getDate() + " . " + (((new Date).getMonth() + 1) > 9 ? ((new Date).getMonth() + 1) : "0" + ((new Date).getMonth() + 1)) + " . " + (new Date).getFullYear() + " <span style='color:#EB6B16;font-weight:bold;font-size:12px;'>" + (new Date).getHours() + ":" + ((new Date).getMinutes() > 9 ? (new Date).getMinutes() : "0" + (new Date).getMinutes()) + ":" + (new Date).getSeconds() + "</span>" })
        }, 1000);


    }

    render(): JSX.Element {
        return <div className="APP_HEADER_ZONE">
            <div title="Cliquez pour afficher les détails sur la version courante.">
                <div className='APP_LOGO_ZONE' onClick={() => {
                    (this.props.GLOBAL_FUNCTIONS.onShowAppVersion as Function)()
                }} style={{ cursor: "pointer" }}>
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.WASPNOTE, 1.2, "e1e1e1") }}></span>
                </div>
                <div className='APP_LOGO_TITLE_ZONE' onClick={() => {
                    (this.props.GLOBAL_FUNCTIONS.onShowAppVersion as Function)()
                }} style={{ cursor: "pointer" }}>
                    <span>{APPVERSIONREF[APPVERSIONREF.length - 1].appliTitle}</span>
                    <span style={{ fontSize: "11px" }}>
                        &nbsp;
                        <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_1}</span>
                        <span className="SPAN_SEP"></span>
                        <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_2}</span>
                        <span className="SPAN_SEP"></span>
                        <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_3}</span>
                    </span>
                </div>
                {
                    this.props.GLOBAL_DATA.userProfil !== undefined &&
                    <div className="APP_USER_PROFIL_LIGHT">
                        <span>{
                            (this.props.GLOBAL_DATA.userProfil.lastName[0])
                        }</span>
                        &nbsp;
                        <span>
                            {
                                (this.props.GLOBAL_DATA.userProfil.firstName[0])
                            }
                        </span>
                    </div>
                }
            </div>

            {/* ===================== */}

            {/* Menu principal application */}
            {
                this.props.GLOBAL_DATA.connectedUser !== undefined &&
                <div className='APP_HEADER_MENU_ZONE'>

                    {/* ============================== */}
                    {/* Menu Accueil */}
                    <span className="APP_HEADER_MENU_ITEM">
                        {
                            //Notification info
                            // this.props.GLOBAL_DATA.allNotifs.length > 0 &&
                            ORDO_PROCESS_ITEM.getInstance().DB_NOTIF.length > 0 &&
                            <div className="NOTIFINFO_BOX" title="Vous avez des notifications en attente de lecture ...">
                                <span>
                                    {
                                        GetDistinctListOfString(ORDO_PROCESS_ITEM.getInstance().DB_NOTIF.map(
                                            notif => {
                                                return notif.groupId +
                                                    (notif.typeId !== undefined ? "." + notif.typeId : "") +
                                                    (notif.noteId !== undefined ? "." + notif.noteId : "")
                                            }
                                        )).length
                                    }
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.talk, 16, "ffffff") }}>

                                </span>

                                <div className="NOTIF_CONTENT_BOX">
                                    <div className="NOTIF_SYNTHESE_BOX">
                                        {
                                            this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                            GetDistinctListOfString(ORDO_PROCESS_ITEM.getInstance().DB_NOTIF
                                                .map(n => { return (n.groupId).toString() }))
                                                .map(gs => {
                                                    return <span key={gs} className="NOTIF_GROUP_ITEM" onClick={() => {
                                                        this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gs)).length > 0 &&
                                                            (this.props.GLOBAL_FUNCTIONS.selectGroupNote as Function)(this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gs))[0]);
                                                    }}>
                                                        <div dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 16) }}>

                                                        </div>
                                                        {
                                                            this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gs)).length > 0 &&
                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === parseInt(gs))[0].title
                                                        }
                                                    </span>
                                                })
                                        }
                                    </div>

                                    {
                                        ORDO_PROCESS_ITEM.getInstance().DB_NOTIF.map(notif => {
                                            return <div className="NOTIF__INFO_ITEM" onClick={()=>{
                                                (this.props.GLOBAL_FUNCTIONS.onLoadNoteAsMemory as Function)(ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId)[0]);
                                            }}>
                                                {
                                                    ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId).length > 0 &&
                                                    <div>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 16) }}></span>
                                                            {
                                                                this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId)[0].idgroupnote).length > 0 &&
                                                                this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId)[0].idgroupnote)[0].title
                                                            }
                                                        </div>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 16) }}></span>
                                                            {
                                                                ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId)[0].title
                                                            }
                                                        </div>
                                                        <div className="NOTIF_DATE_BOX">
                                                            <span className="TEXT_TICKET">
                                                            {
                                                                ORDO_PROCESS_ITEM.getInstance().DB_NOTIF_NOTE.filter(notifN => notifN.idnote !== null && notifN.idnote === notif.noteId)[0].modified
                                                            }
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        }


                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.home, 24, "ffffff") }}
                            onClick={() => {
                                //Retour à la page d'accueil
                                (this.props.GLOBAL_FUNCTIONS.selectGroupNote as Function)(undefined);
                            }}
                            title="Cliquez pour revenir à l'accueil des carnets ...">
                        </span>
                    </span>
                    {/* Menu utilisateur */}
                    <span className='APP_HEADER_MENU_ITEM'>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 24, "ffffff") }} >
                        </span>
                        <span className='APP_HEADER_SUBMENU_ITEM'>
                            {/* Info de l'utilisateur connecté */}
                            {
                                this.props.GLOBAL_DATA.userProfil !== undefined &&
                                <div className="APP_HEADER_PROFIL_BOX">
                                    {
                                        (this.props.GLOBAL_DATA.userProfil as I_USER_PROFIL_ITEM).lastName
                                        + " " +
                                        (this.props.GLOBAL_DATA.userProfil as I_USER_PROFIL_ITEM).firstName

                                    }
                                </div>
                            }
                            <div className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {
                                (this.props.GLOBAL_FUNCTIONS.onUserDeconnexion as Function)();
                            }}>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.power, 16, "ffffff") }} ></span>
                                <span>Déconnexion</span>
                            </div>
                            <div className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => { (this.props.GLOBAL_FUNCTIONS.onShowUserProfil as Function)() }}
                                title="Gérez vos données de profil ...">
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 16, "ffffff") }} ></span>
                                <span>Profil</span>
                            </div>
                        </span>
                    </span>

                    {/* menu carnet d'adresse */}
                    <span className='APP_HEADER_MENU_ITEM' >

                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.adress, 24, "ffffff") }} >
                        </span>
                        <span className='APP_HEADER_SUBMENU_ITEM'>
                            <div className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {
                                (this.props.GLOBAL_FUNCTIONS.onShowAnnuaire as Function)(false)
                            }} title="Tous les utilisateurs publiques ...">
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.search, 16, "ffffff") }} ></span>
                                <span>Annuaire</span>
                            </div>
                            <div className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {
                                (this.props.GLOBAL_FUNCTIONS.onShowAnnuaire as Function)(true)
                            }} title="Tous Vos contacts ...">
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 16, "ffffff") }} ></span>
                                <span>Mes contacts</span>
                            </div>
                        </span>
                    </span>

                    {/* menu Config */}
                    <span className='APP_HEADER_MENU_ITEM' >
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.information, 24, "ffffff") }} >
                        </span>
                        <span className='APP_HEADER_SUBMENU_ITEM'>
                            <div title="Condition Générale d'Utilisation" className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {
                                let tmpMSGOption: I_MSG_OPTION = {
                                    allMSG: [
                                        {
                                            key: "C.G.U.",
                                            value: CGU,
                                            data: ENU_ICON_TYPE.certified
                                        }
                                    ],
                                    showCloseButton: true,
                                    buttons: [
                                    ],
                                    postedData: true,
                                };

                                (this.props.GLOBAL_FUNCTIONS.showMessage as Function)(tmpMSGOption);
                            }}>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.certified, 16, "ffffff") }} ></span>
                                <span>C.G.U.</span>
                            </div>

                            <div className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {
                                let tmpMSGOption: I_MSG_OPTION = {
                                    allMSG: [
                                        {
                                            key: "A propos",
                                            value: APROPOS,
                                            data: ENU_ICON_TYPE.information
                                        }
                                    ],
                                    showCloseButton: true,
                                    buttons: [
                                    ],
                                    postedData: true,
                                };

                                (this.props.GLOBAL_FUNCTIONS.showMessage as Function)(tmpMSGOption);

                            }} >
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.information, 16, "ffffff") }} ></span>
                                <span>A propos</span>
                            </div>
                        </span>
                    </span>

                    {/* menu d'aide */}
                    {
                        !true &&
                        <span>
                            <span className='APP_HEADER_MENU_ITEM' >
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.help, 24, "ffffff") }} >
                                </span>
                                <span className='APP_HEADER_SUBMENU_ITEM' style={{ width: "300px", left: "-235px" }}>

                                    <div className="APP_HEADER_HELPSEARCH_BOX">
                                        <SEARCHBOX placeHolder="Rechercher une aide ..." forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={() => {
                                        }} APPFUNCTION={this.props.GLOBAL_FUNCTIONS} />
                                    </div>
                                    {
                                        DB_HELP.map(h => {
                                            return <div key={h.id} className="APP_HEADER_MENU_SUBMENU_LINE" onClick={() => {

                                            }}>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(h.icon, 16, "ffffff") }} ></span>
                                                <span>{h.title}</span>

                                                {
                                                    h.child !== undefined &&
                                                    h.child.map(c => {
                                                        return <div key={c.id} className="APP_HEADER_MENU_SUBMENU_LINE APP_HEADER_MENU_SUBSUBMENU_LINE" onClick={() => {
                                                            const msgOption: I_MSG_OPTION = {
                                                                allMSG: [
                                                                    {
                                                                        key: "WASPNOTE - " + c.title,
                                                                        value: "...",
                                                                        data: c.icon
                                                                    }
                                                                ],
                                                                showCloseButton: false,
                                                                postedData: c.IMG as ENU_IMG,
                                                                buttons: [{
                                                                    title: "OK",
                                                                    F_BUTTON_ACTION: () => {
                                                                        (this.props.GLOBAL_FUNCTIONS.closePopup as Function)();
                                                                    }
                                                                }]
                                                            };

                                                            (this.props.GLOBAL_FUNCTIONS.showMessage as Function)(msgOption);
                                                        }}>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(c.icon, 12, "ffffff") }} ></span>
                                                            <span>{c.title}</span>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        })
                                    }
                                </span>
                            </span>
                        </span>
                    }

                </div>
            }


            {/* Affichage des filtres  actifs */}
            <div className='APP_HEADER_ACTIVE_FILTER'>
                <div className="APP_HEADER_TIME_BOX" dangerouslySetInnerHTML={{ __html: this.state.currentDateTitme }}>
                </div>
                {
                    this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                    <span className="APP_HEADER_CURRENT_GROUP_TITLE">
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 18, "EF6F1A") }}>

                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>{
                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                            this.props.GLOBAL_DATA.selectedGroup.title
                        }</span>
                    </span>
                }

                <span>

                    <span>
                        {/* les types de notes */}
                        {
                            (this.props.GLOBAL_DATA.selectedType !== undefined && this.props.GLOBAL_DATA.selectedType.length > 0) &&
                            <span className='APP_HEADER_ACTIVE_GROUP' title={
                                (this.props.GLOBAL_DATA.selectedType as number[]).length + ' : ' + (this.props.GLOBAL_DATA.selectedType as number[]).map(t => (this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t).length > 0 && (this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t)[0].title).join(", ")
                            }>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 14, "ffffff") }}></span>&nbsp;
                                {
                                    (this.props.GLOBAL_DATA.selectedType as number[]).slice(0, 3).map((t, index) => {
                                        return <span key={t + "_Type"} >
                                            <span className="APP_HEADER_ACTIVE_ITEM">{(this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t).length > 0 ?
                                                IsTypeNoteIsAnCupBord((this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t)[0].title) ?
                                                    (this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t)[0].title.split(CupBoardCode)[1] :
                                                    (this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === t)[0].title
                                                : "..."}</span>
                                            <span className='APP_HEADER_ACTIVE_ITEM_DOT'></span>
                                        </span>
                                    })
                                }

                                {
                                    (this.props.GLOBAL_DATA.selectedType as number[]).length > 3 &&
                                    <span>(+ {(this.props.GLOBAL_DATA.selectedType as number[]).length - 3})</span>
                                }
                            </span>
                        }

                        {/* les tags */}
                        {
                            (this.props.GLOBAL_DATA.selectedTag !== undefined && this.props.GLOBAL_DATA.selectedTag.length > 0) &&
                            <span className='APP_HEADER_ACTIVE_GROUP' title={
                                this.props.GLOBAL_DATA.selectedTag.length + " : " + this.props.GLOBAL_DATA.selectedTag.join(", ")
                            }>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.tag, 14, "ffffff") }}></span>&nbsp;
                                {
                                    (this.props.GLOBAL_DATA.selectedTag as string[]).slice(0, 3).map((t, index) => {
                                        return <span key={t + "_Tag"} >
                                            <span className="APP_HEADER_ACTIVE_ITEM">{t}</span>
                                            <span className='APP_HEADER_ACTIVE_ITEM_DOT'></span>
                                        </span>
                                    })
                                }

                                {
                                    (this.props.GLOBAL_DATA.selectedTag as string[]).length > 3 &&
                                    <span>(+ {(this.props.GLOBAL_DATA.selectedTag as string[]).length - 3})</span>
                                }
                            </span>
                        }
                    </span>
                </span>
            </div>
        </div>
    }
}