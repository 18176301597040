import * as React from "react";
import { I_ANNUAIRE_ITEM, I_ANNUAIRE_POPUP_PROPS, I_ANNUAIRE_POPUP_PROPS_STATE } from "../../02_Interfaces/IANNUAIRE";
import { ENU_ACTION_TYPE, ENU_ICON_TYPE, I_RESULT } from "../../02_Interfaces/IGLOBAL";
import { AcceptTheInvitation, AddContactForUer, DeleteContact, GetAllPublicUser, GetAllUserContacts, GetCurrentUserContacts } from "../../04_TOOLS/DB_WS";
import { GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/formGlobal.css";
import "../../99_Styles/formAnnuaire.css";
import { APP_BUTTON } from "../Components/BUTTON";
import { SEARCHBOX } from "../Components/SEARCHBOX";
import { I_ALLPATH_POPUP_PROPS, I_ALLPATH_POPUP_PROPS_STATE } from "../../02_Interfaces/IPATH";


export class ALLPATH_POPUP extends React.Component<I_ALLPATH_POPUP_PROPS, I_ALLPATH_POPUP_PROPS_STATE>{

    constructor(props: I_ANNUAIRE_POPUP_PROPS) {
        super(props);

        this.state = {
            searchKey: ""
        }

        this.onSearchPath = this.onSearchPath.bind(this);
    }

    //============================
    //Méthodes et fonctions utiles
    //============================

    //On search contacts
    //------------------
    onSearchPath(value: string) {
        this.setState({ searchKey: value });
    }



    //Chargement initial
    //-------------------
    componentDidMount() {

    }
    //============================

    render(): JSX.Element {
        return <div>
            {/* font blanc non opaque  */}
            <div className="POPUP_LAYER" style={{ opacity: 0.4 }}></div>


            <div className="POPUP_BODY_BOX" style={{ top: "7%", bottom: "7%" }}>
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 24, "#c3c3c3") }}></span>
                        &nbsp;
                        <span>
                            <span>Historique des actions</span>
                        </span>
                    </span>

                    {/* Bouton de fermeture de la popup */}
                    <div
                        className="POPUP_CLOSE_BUTTON" onClick={() => { (this.props.APP_GLOBAL_FUNCTION.onShowAllPath as Function)() }}
                        dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18) }}>
                    </div>
                </div>

                {/* Contenu du formulaire de gestion des propriétés du profil */}
                {
                    <div className="POPUP_BODY">

                        <div style={{ width: "100%", textAlign: "center" }}>
                            <SEARCHBOX APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} placeHolder={"Saisissez tout ou partie du nom ..."} forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={this.onSearchPath} />
                        </div>


                        <div className="ANN_ITEM_LIST_BOX">
                            {
                                this.props.APP_GLOBAL_DATA.allPath.map(p => {
                                    return <div>
                                        <div>{p.noteTitle}</div>
                                        <div>
                                            {
                                                p.actionType.filter(at => at = ENU_ACTION_TYPE.tasked).length > 0 &&
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.task, 16) }}></span>
                                            }
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                }


                {/* Zon d'actions profil */}
                {
                    <div className="POPUP_ACTION_ZONE">
                        <APP_BUTTON buttonProps={
                            {
                                title: "Fermer",
                                F_BUTTON_ACTION: () => {
                                    (this.props.APP_GLOBAL_FUNCTION.onShowAllPath as Function)();
                                },
                            }
                        } />
                    </div>
                }
            </div>

        </div>
    }
}