import { I_APP_GLOBAL_DATA, I_APP_GLOBAL_FUNCTIONS, KeyValuePair } from "./IGLOBAL";
import { I_NOTE_ITEM } from "./INOTE";

export interface I_COMPTA_FORM_PROPS {
    APP_DATA: I_APP_GLOBAL_DATA;
    APP_FUNCTION: I_APP_GLOBAL_FUNCTIONS;
    note: I_NOTE_ITEM;
    editMode: boolean;
    showTotalOnly:boolean;
}

export interface I_COMPTA_FORM_PROPS_STATE {
    comptaLines: I_COMPTA_FORM_ITEM[];
    comptaTitle: string;
    accounts: I_COMPTA_ACCOUNT_ITEM[];
    Year: number;
    periodeYear: number[];
    Month: number;
}

export interface I_COMPTA_FORM_ITEM {
    idLigne: string;
    lineType: ENU_LINE_TYPE;
    amount: number;
    taxeRatio: number;
    description: string;
    source: string;
    destination: string;
}


export enum ENU_LINE_TYPE {
    facturation = "Facturation",
    food = "Frais repas",
    equipment = "Equipement / Matériel",
    taxe = "Taxes",
    maintenance = "Entretient / Maintenance",
    rent = "Loyer / Charges",
    save = "Epargnes",
    other = "Autre"
}

export enum ENU_ACCOUNT_TYPE {
    usual = "Courant", saved = "Epargne", other = "Autre"
}

export interface I_COMPTA_FORM_NOTE {
    year: number;
    month: number;
    lines: I_COMPTA_FORM_ITEM[];
    accounts: I_COMPTA_ACCOUNT_ITEM[];
}

export interface I_COMPTA_ACCOUNT_ITEM {
    id: string,
    title: string,
    typeAccount: ENU_ACCOUNT_TYPE,
    amount: number,
}



export const COMPTA_TAXE_REF: KeyValuePair[] = [
    {
        key: ENU_LINE_TYPE.facturation.toString(),
        value: "20"
    }
]

export interface I_COMPTA_STAT_PROPS{
    GLOBAL_DATA:I_APP_GLOBAL_DATA;
    GLOBAL_FUNCTION:I_APP_GLOBAL_FUNCTIONS;
    filteredNotes:I_NOTE_ITEM[];
}

export interface I_COMPTA_STAT_PROPS_STATE{
    totalDeficiteAmount:number;
    totalBenefitAmount:number;
    //-------------------------
    selectedYear:number;
    selectedMonth:number;
}

