import { Guid } from "guid-typescript";
import * as React from "react";
import { DB_MONTH_NAME } from "../../01_APPDATA/DB_APP";
import { ENU_ACTION_TYPE, ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { I_NOTE_ITEM } from "../../02_Interfaces/INOTE";
import { ENU_ACCOUNT_TYPE, ENU_LINE_TYPE, I_COMPTA_ACCOUNT_ITEM, I_COMPTA_FORM_ITEM, I_COMPTA_FORM_NOTE, I_COMPTA_FORM_PROPS, I_COMPTA_FORM_PROPS_STATE } from "../../02_Interfaces/I_COMPTA_FORM";
import { GenerateComptaBodyFromData, GetDateStringFromObject, GetDistinctListOfString, GetIconFromType, GetNoteComptaData, insertAt } from "../../04_TOOLS/tools";
import "../../99_Styles/compta.css";
import { FIELD_TEXT } from "../Fields/FIELD_TEXT";

export class COMPTA_FORM extends React.Component<I_COMPTA_FORM_PROPS, I_COMPTA_FORM_PROPS_STATE> {

    constructor(props: I_COMPTA_FORM_PROPS) {
        super(props);

        this.state = {
            comptaLines: [],
            accounts: [],
            Year: (new Date()).getFullYear(),
            periodeYear: [],
            Month: 1,
            comptaTitle: this.props.note.title
        }

        this.onLoadComptaNote = this.onLoadComptaNote.bind(this);
        this.onAddNewLine = this.onAddNewLine.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChangeLine = this.onChangeLine.bind(this);
        this.onDeleteLine = this.onDeleteLine.bind(this);
        this.onAddNewAccount = this.onAddNewAccount.bind(this);
        this.moveItemToAnOtherLine = this.moveItemToAnOtherLine.bind(this);
        this.onGenerateNoteBody = this.onGenerateNoteBody.bind(this);
        this.onDeleteAccount = this.onDeleteAccount.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
        this.onChangeTitleCompta = this.onChangeTitleCompta.bind(this);


    }

    private ref_AccountList = React.createRef<HTMLSelectElement>();


    onChangeTitleCompta(e: React.FormEvent) {
        this.setState({ comptaTitle: (e.target as HTMLInputElement).value })
    }

    onChangeYear(year: React.FormEvent) {
        this.setState({ Year: (year.target as HTMLSelectElement).value.length > 0 ? parseInt((year.target as HTMLInputElement).value) : 0 }, () => {
            //this.onGenerateNoteBody();
        })
    }

    onChangeMonth(month: React.FormEvent) {
        this.setState({ Month: (month.target as HTMLSelectElement).value.length > 0 ? parseInt((month.target as HTMLSelectElement).value) : 0 }, () => {
            //this.onGenerateNoteBody();
        })
    }

    onDeleteAccount(id: string) {
        const targetAccount: I_COMPTA_ACCOUNT_ITEM = this.state.accounts.filter(a => a.id === id)[0];

        let msgOption: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Suppression du compte '" + targetAccount.title + "(" + Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(targetAccount.typeAccount) === index)[0] + ")'",
                    value: "En supprimant le compte, les lignes concernés seront mises à jour",
                    data: ENU_ICON_TYPE.close
                }
            ],
            buttons: [
                {
                    title: "Supprimer",
                    F_BUTTON_ACTION: () => {
                        let tmpAccounts: I_COMPTA_ACCOUNT_ITEM[] = this.state.accounts.filter(a => a.id !== id);
                        let tmpLines: I_COMPTA_FORM_ITEM[] = this.state.comptaLines;

                        (tmpLines).forEach(cl => {
                            if (cl.source === id) {
                                cl.source = "-1";
                            }
                            if (cl.destination === id) {
                                cl.destination = "-1";
                            }
                        });

                        this.setState({ accounts: tmpAccounts, comptaLines: tmpLines }, () => {
                            (this.props.APP_FUNCTION.onLoadAllComptaForm as Function)()
                        });

                        (this.props.APP_FUNCTION.closePopup as Function)();
                    }
                }
            ],
            showCloseButton: true
        };

        (this.props.APP_FUNCTION.showMessage as Function)(msgOption);
    }

    onLoadComptaNote() {

        (this.props.APP_FUNCTION.onLoadAllComptaForm as Function)()

        //Chargement des données
        //----------------------
        const comptaNote: I_COMPTA_FORM_NOTE = GetNoteComptaData(this.props.note.description);

        this.setState({
            Year: comptaNote.year,
            Month: comptaNote.month,
            comptaLines: comptaNote.lines,
            accounts: comptaNote.accounts
        })
        //----------------------
    }

    onQuickSaveNote(e: React.KeyboardEvent) {
        if (e.ctrlKey && e.key === "s" && this.props.editMode) {

            this.onGenerateNoteBody();

            e.preventDefault();
        }

    }

    onGenerateNoteBody() {
        let formatedComptaData: string = GenerateComptaBodyFromData(this.state.Month, this.state.Year, this.state.accounts, this.state.comptaLines);
        let tmpNote: I_NOTE_ITEM = this.props.note;

        //Nettoyage des tags => Suppression de tous les autres tags
        //---------------------------------------------------------
        const currentNoteBody: I_COMPTA_FORM_NOTE = GetNoteComptaData(this.props.note.description);
        if (currentNoteBody.year !== this.state.Year) {
            tmpNote.tags = tmpNote.tags.split(";").filter(t => t.length > 0 && t !== currentNoteBody.year.toString()).join(";");
        }

        if (currentNoteBody.month !== this.state.Month) {
            tmpNote.tags = tmpNote.tags.split(";").filter(t => t.length > 0 && t !== currentNoteBody.month.toString()).join(";");
        }
        // ------------------


        // console.log(formatedComptaData);
        tmpNote.title = this.state.comptaTitle;
        tmpNote.created = "01/" + (this.state.Month > 9 ? this.state.Month : "0" + this.state.Month) + "/" + this.state.Year
        tmpNote.description = formatedComptaData;
        tmpNote.descriptionraw = formatedComptaData;
        tmpNote.modified = GetDateStringFromObject(new Date(), true);
        tmpNote.tags = "";

        // if (tmpNote.tags.split(";").filter(t => t === DB_MONTH_NAME[this.state.Month - 1]).length === 0) {
        tmpNote.tags += DB_MONTH_NAME[this.state.Month - 1] + ";";
        // }
        // if (tmpNote.tags.split(";").filter(t => t === this.state.Year.toString()).length === 0) {
        tmpNote.tags += this.state.Year + ";";
        // }


        (this.props.APP_FUNCTION.updateNote as Function)(tmpNote);

    }

    onAddNewAccount(account?: I_COMPTA_ACCOUNT_ITEM) {
        let tmpAccounts: I_COMPTA_ACCOUNT_ITEM[] = this.state.accounts;
        if (tmpAccounts.filter(ca => (account !== undefined ? true : ca.title === "...") && (account !== undefined ? account.id === ca.id : true)).length === 0) {
            if (account !== undefined) {
                tmpAccounts.push(account)
            } else {
                tmpAccounts.push({
                    id: Guid.create().toString(),
                    title: "...",
                    amount: 0,
                    typeAccount: "usual" as ENU_ACCOUNT_TYPE
                });
            }


            this.setState({ accounts: tmpAccounts }, () => {
                (this.props.APP_FUNCTION.onLoadAllSharedItemsForUser as Function)();
                (this.props.APP_FUNCTION.onLoadAllComptaForm as Function)();
            });
        } else {
            let tmpMSGOption: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Compte de gestion",
                        value: account !== undefined ? "Le compte '" + account.title + "(" + account.typeAccount.toString() + ")" + "' est déjà présent dans le formulaire courant." : "Un compte '...' existe déjà. Mettez le compte à jour avant de continuer.",
                        data: ENU_ICON_TYPE.euroDevise
                    }
                ],
                showCloseButton: false,
                buttons: [
                    {
                        title: "OK",
                        F_BUTTON_ACTION: () => {
                            (this.props.APP_FUNCTION.closePopup as Function)()
                        }
                    }
                ]
            };

            (this.props.APP_FUNCTION.showMessage as Function)(tmpMSGOption)
        }
    }

    onAddNewLine(index: number, accountIdSource: string, accountIdDestination: string) {
        let tmpComptaLine: I_COMPTA_FORM_ITEM[] = this.state.comptaLines;

        insertAt(tmpComptaLine, index, {
            idLigne: Guid.create().toString(),
            lineType: "other" as ENU_LINE_TYPE,
            amount: 0,
            taxeRatio: 0,
            description: "...",
            source: accountIdSource,
            destination: accountIdDestination
        } as never);

        this.setState({ comptaLines: tmpComptaLine }, () => {
            //this.onGenerateNoteBody();
        });
    }

    moveItemToAnOtherLine(sourceIndex: number, targetIndex: number) {
        const neededLine: I_COMPTA_FORM_ITEM = this.state.comptaLines.filter((cl, _index) => _index === sourceIndex)[0]
        let tmpComptaLine: I_COMPTA_FORM_ITEM[] = this.state.comptaLines.filter((cl, _index) => _index !== sourceIndex);

        insertAt(tmpComptaLine, targetIndex, neededLine as never)
        this.setState({ comptaLines: tmpComptaLine }, () => {
            //this.onGenerateNoteBody();
        });
    }

    onDeleteLine(line: I_COMPTA_FORM_ITEM) {
        let msgOption: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Suppression de la ligne",
                    value: "Voulez-vous supprimer la ligne comptable '" + Object.values(ENU_LINE_TYPE)[Object.keys(ENU_LINE_TYPE).indexOf(line.lineType)] + "' dont la description est '" + line.description + "' ?",
                    data: ENU_ICON_TYPE.euroDevise
                }
            ],
            showCloseButton: true,
            buttons: [
                {
                    title: "Supprimer",
                    F_BUTTON_ACTION: () => {
                        let tmpAllLine: I_COMPTA_FORM_ITEM[] = this.state.comptaLines;
                        tmpAllLine = tmpAllLine.filter(cl => cl.idLigne !== line.idLigne);
                        this.setState({ comptaLines: tmpAllLine }, () => {
                            //this.onGenerateNoteBody();
                            (this.props.APP_FUNCTION.closePopup as Function)();
                        });
                    }
                }
            ]
        };

        (this.props.APP_FUNCTION.showMessage as Function)(msgOption);
    }

    onChangeAccount(idAccount: string, title?: React.FormEvent, type?: React.FormEvent, amount?: number) {
        // console.log("ok" + amount);
        // let tmpAllAccount: I_COMPTA_ACCOUNT_ITEM[] = this.state.accounts;
        let tmpAccount: I_COMPTA_ACCOUNT_ITEM = this.state.accounts.filter(a => a.id === idAccount)[0];

        if (title !== undefined) {
            tmpAccount.title = (title.target as HTMLInputElement).value;
        }

        if (type !== undefined) {
            let tmpType: ENU_ACCOUNT_TYPE = (type.target as HTMLSelectElement).value as ENU_ACCOUNT_TYPE;
            if (this.state.accounts.filter(a => a.typeAccount === tmpType).length < 1) {
                tmpAccount.typeAccount = (type.target as HTMLSelectElement).value as ENU_ACCOUNT_TYPE;
            }
        }

        if (amount !== undefined) {
            tmpAccount.amount = amount;
        }

        let tmpAllAccount: I_COMPTA_ACCOUNT_ITEM[] = this.state.accounts;
        tmpAllAccount.filter(a => a.id === idAccount)[0] = tmpAccount;

        this.setState({ accounts: tmpAllAccount }, () => {
            (this.props.APP_FUNCTION.onLoadAllComptaForm as Function)()
        });

    }

    onChangeLine(idLine: string, typeLine?: React.FormEvent, amount?: number, taxe?: React.FormEvent, description?: React.FormEvent, source?: React.FormEvent, destination?: React.FormEvent) {
        let tmpAllLine: I_COMPTA_FORM_ITEM[] = this.state.comptaLines;
        let tmpLine: I_COMPTA_FORM_ITEM = tmpAllLine.filter(cl => cl.idLigne === idLine)[0];

        if (typeLine !== undefined) {
            tmpLine.lineType = (typeLine.target as HTMLSelectElement).value as ENU_LINE_TYPE;

            if (tmpLine.lineType.toString() !== "facturation") {
                tmpLine.taxeRatio = 0;
            } else {
                tmpLine.taxeRatio = 20;
            }
        }

        if (amount !== undefined) {
            tmpLine.amount = amount;
        }

        if (taxe !== undefined) {
            tmpLine.taxeRatio = parseFloat((taxe.target as HTMLSelectElement).value);
        }

        if (description !== undefined) {
            tmpLine.description = (description.target as HTMLTextAreaElement).value;
        }

        if (source !== undefined) {
            tmpLine.source = (source.target as HTMLSelectElement).value;
        }

        if (destination !== undefined) {
            tmpLine.destination = (destination.target as HTMLSelectElement).value;
        }

        this.setState({ comptaLines: tmpAllLine }, () => {
            //this.onGenerateNoteBody();
        });
    }

    componentDidMount(): void {

        //Génération plage de dates
        //-------------------------
        let tmpYears: number[] = [];
        for (let x = (new Date()).getFullYear() - 30; x < (new Date()).getFullYear() + 30; x++) {
            tmpYears.push((new Date()).getFullYear() - ((new Date()).getFullYear() - x))
        }
        this.setState({ periodeYear: tmpYears.sort((a, b) => { return b - a }) });

        //Chargement des données
        //----------------------
        this.onLoadComptaNote();
        //----------------------
    }

    render(): JSX.Element {
        return <div onKeyDown={(e) => {
            // this.onQuickSaveNote(e);
        }}
        onMouseLeave={() => {
            this.props.editMode &&
                this.onGenerateNoteBody();
        }}
        >
            {/* Année et mois des opérations */}
            <div>
                <div className="COMPTA_FORM_HEADER_ZONE">

                    <span>
                        <select onChange={(e) => this.onChangeMonth(e)} disabled={!this.props.editMode}>
                            <option value="">
                                ...
                            </option>
                            {
                                DB_MONTH_NAME.map((m, index) => {
                                    return <option key={m + "-" + index} value={index + 1} selected={this.state.Month - 1 === index}>
                                        {
                                            m
                                        }
                                    </option>
                                })
                            }
                        </select>
                    </span>
                    <span className="SPAN_SEP"></span>
                    <span>
                        <select style={{ color: "red" }} onChange={(e) => this.onChangeYear(e)} disabled={!this.props.editMode}>
                            <option value="">
                                ...
                            </option>
                            {
                                this.state.periodeYear.map((y, index) => {
                                    return <option key={index} value={y} selected={this.state.Year === y}>
                                        {
                                            y
                                        }
                                    </option>
                                })
                            }
                        </select>
                    </span>
                    <span className="SPAN_SEP"></span>
                    <span>
                        <input type={"text"} value={this.state.comptaTitle} onChange={(e) => this.onChangeTitleCompta(e)} disabled={!this.props.editMode} />
                    </span>

                </div>
            </div>
            {/* Comptes impliqués dans les opérations */}
            <div className="COMPTA_ACCOUNTS_MANAGER_ZONE">
                <span className="COMPTA_ACCOUNTS_ZONE">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cards, 16) }}></span>
                                    &nbsp;
                                    <span>Compte</span>
                                </th>
                                <th>
                                    Type
                                </th>
                                <th>
                                    Montant
                                </th>
                                <th>

                                </th>
                                <td></td>
                                <td></td>
                                <td >
                                    ...
                                </td>
                            </tr>

                            {
                                //---------------------------------
                                this.state.accounts.map(ca => {
                                    return <tr key={ca.id} className={"COMPTA_LINE_ZONE"}>
                                        <td>
                                            <input onChange={(e) => { this.onChangeAccount(ca.id, e) }} type="text" value={ca.title} disabled={!this.props.editMode} />
                                        </td>
                                        <td>
                                            <select onChange={(e) => { this.onChangeAccount(ca.id, undefined, e) }} disabled={!this.props.editMode}>
                                                {
                                                    Object.values(ENU_ACCOUNT_TYPE).map((at, index) => {
                                                        return <option key={"AMOUNT" + ca.id + "-" + index}
                                                            value={Object.keys(ENU_ACCOUNT_TYPE).filter((k, i) => index === i)[0]}
                                                            selected={Object.keys(ENU_ACCOUNT_TYPE).filter(k => ca.typeAccount === Object.keys(ENU_ACCOUNT_TYPE).filter((k, i) => index === i)[0]).length > 0 ? true : false}>
                                                            {
                                                                at
                                                            }
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <FIELD_TEXT
                                                numberOnly={true}
                                                title="***"
                                                value={ca.amount.toString()}
                                                F_Action={
                                                    (value: string) => {
                                                        this.onChangeAccount(ca.id, undefined, undefined, parseFloat(value))
                                                    }
                                                }
                                                required={false}
                                                disable={!this.props.editMode}
                                            />
                                            {/* <input type={"number"}  onChange={(e) => { this.onChangeAccount(ca.id, undefined, undefined, e) }} value={ca.amount} disabled={!this.props.editMode} /> */}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <span className="COMPTA_STAT_ACCOUNT_ZONE">
                                                {
                                                    // this.state.comptaLines.filter(cl => cl.destination === ca.id).length > 0 &&
                                                    <div className="COMPTA_STAT_INFO COMPTA_STAT_CREDIT" >
                                                        <div className="COMPTA_STAT_VALUE">{
                                                            (this.state.comptaLines.filter(cl => cl.destination === ca.id).map(cl => {
                                                                return cl.amount
                                                            }).reduce((sum, number) => sum + number, 0) + (!isNaN(ca.amount) ? ca.amount : 0)).toFixed(2)}</div>
                                                    </div>
                                                }
                                            </span>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <span className="COMPTA_STAT_ACCOUNT_ZONE">
                                                {
                                                    // this.state.comptaLines.filter(cl => cl.source === ca.id).length > 0 &&
                                                    <div className="COMPTA_STAT_INFO COMPTA_STAT_DEBIT">
                                                        <div className="COMPTA_STAT_VALUE">{
                                                            (this.state.comptaLines.filter(cl => cl.source === ca.id).map(cl => {
                                                                return cl.amount
                                                            }).reduce((sum, number) => sum - number, 0)).toFixed(2)}</div>
                                                    </div>
                                                }
                                            </span>
                                        </td>
                                        <td style={{ textAlign: "right", borderLeft: "2px dotted #3c3c3c", paddingLeft: "14px" }}>
                                            <span className="COMPTA_STAT_ACCOUNT_ZONE">
                                                {
                                                    // this.state.comptaLines.filter(cl => cl.source === ca.id).length > 0 &&
                                                    // this.state.comptaLines.filter(cl => cl.destination === ca.id).length > 0 &&
                                                    <div className={

                                                        parseFloat(((parseFloat((this.state.comptaLines.filter(cl => cl.destination === ca.id).map(cl => {
                                                            return cl.amount
                                                        }).reduce((sum, number) => sum + number, 0) + (!isNaN(ca.amount) ? ca.amount : 0)).toFixed(2))) +
                                                            (parseFloat((this.state.comptaLines.filter(cl => cl.source === ca.id).map(cl => {
                                                                return cl.amount
                                                            }).reduce((sum, number) => sum - number, 0)).toFixed(2)))).toFixed(2)) > 0 ?
                                                            "COMPTA_STAT_INFO COMPTA_STAT_CREDIT" : "COMPTA_STAT_INFO COMPTA_STAT_DEBIT"
                                                    }>
                                                        <div className="COMPTA_STAT_VALUE">
                                                            {
                                                                ((parseFloat((this.state.comptaLines.filter(cl => cl.destination === ca.id).map(cl => {
                                                                    return cl.amount
                                                                }).reduce((sum, number) => sum + number, 0) + (!isNaN(ca.amount) ? ca.amount : 0)).toFixed(2))) +
                                                                    (parseFloat((this.state.comptaLines.filter(cl => cl.source === ca.id).map(cl => {
                                                                        return cl.amount
                                                                    }).reduce((sum, number) => sum - number, 0)).toFixed(2)))).toFixed(2)
                                                            }
                                                            <span className="SPAN_SEP"></span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cashMoney, 14) }}>

                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </span>
                                        </td>
                                        <td>
                                            {
                                                this.props.editMode &&
                                                <span className="COMPTA_LINE_BUTTON"
                                                    onClick={
                                                        () => this.onDeleteAccount(ca.id)
                                                    } dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 11, "d81313") }}></span>
                                            }

                                        </td>
                                    </tr>
                                })
                                //---------------------------------
                            }
                            <tr>

                                <td colSpan={6}></td>
                                {
                                    this.props.editMode &&
                                    <td>
                                        <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => this.onAddNewAccount()}>
                                            Nouveau compte
                                        </span>

                                    </td>
                                }

                            </tr>
                            <tr>
                                <td colSpan={6}></td>
                                {
                                    this.props.editMode &&
                                    this.props.APP_DATA._DB_COMPTA_ACCOUNT !== undefined &&
                                    this.props.APP_DATA._DB_COMPTA_ACCOUNT
                                        .filter(a => this.state.accounts.filter(_a => _a.id === a.id).length === 0).length > 0 &&
                                    <td>
                                        <span>
                                            <select ref={this.ref_AccountList}>
                                                {
                                                    this.props.APP_DATA._DB_COMPTA_ACCOUNT !== undefined &&
                                                    this.props.APP_DATA._DB_COMPTA_ACCOUNT
                                                        .filter(a => this.state.accounts.filter(_a => _a.id === a.id).length === 0)
                                                        .map(a => {
                                                            return <option key={a.id} value={a.id}>
                                                                {
                                                                    a.title
                                                                }
                                                            </option>
                                                        })
                                                }
                                            </select>
                                        </span>
                                        <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                            if (this.props.APP_DATA._DB_COMPTA_ACCOUNT !== undefined) {
                                                this.onAddNewAccount(this.props.APP_DATA._DB_COMPTA_ACCOUNT.filter(a => this.ref_AccountList.current !== null && a.id === this.ref_AccountList.current.value)[0]);
                                            }

                                        }}>
                                            Ajouter
                                        </span>

                                    </td>
                                }
                            </tr>
                        </tbody>

                    </table>
                </span>

            </div>
            {/* Zone des lignes */}
            {
                !this.props.showTotalOnly &&
                <div className="COMPTA_LINE_BOX">

                    <table >
                        <tbody>

                            {
                                //Gestion des regroupements d'opération
                                //--------------------------------------
                                GetDistinctListOfString(this.state.comptaLines.map((cl => { return cl.source + ";" + cl.destination }))).map((clg, index) => {
                                    return <tr key={"GROUP" + clg + "index"} className="COMPTA_GROUP_LINES">
                                        <tr>
                                            <th>
                                                N°
                                            </th>
                                            <th>
                                                Type Mouvement
                                            </th>
                                            <th >
                                                Montant
                                            </th>
                                            <th>
                                                T.V.A.
                                            </th>
                                            <th >
                                                Livellé
                                            </th>
                                            <th>
                                                Crédit
                                            </th>
                                            <th style={{ width: "125px" }}>
                                                Débit
                                            </th>
                                            <th style={{ width: "125px" }}>
                                                T.V.A. Perçue
                                            </th>
                                            <th>
                                                Débité
                                            </th>
                                            <th>
                                                Crédité
                                            </th>
                                            <th>
                                                ...
                                            </th>
                                        </tr>

                                        {
                                            //Toutes les lignes
                                            //==================
                                            this.state.comptaLines
                                                .filter(cl => cl.source === clg.split(";")[0] && cl.destination === clg.split(";")[1])
                                                .map((cl, _index) => {
                                                    return <tr key={"LINE" + cl.idLigne} className={"COMPTA_LINE_ZONE"}>
                                                        <td style={{ minWidth: "45px", textAlign: "center", color: "#ffffff", backgroundColor: "#3c3c3c" }}>
                                                            {(index + 1) + "-" + (_index + 1)}
                                                        </td>
                                                        <td>
                                                            <select onChange={(e) => {
                                                                this.onChangeLine(cl.idLigne, e, undefined, undefined);
                                                            }} disabled={!this.props.editMode}>
                                                                {
                                                                    (Object.keys(ENU_LINE_TYPE) as Array<keyof typeof ENU_LINE_TYPE>).map(type => {
                                                                        return <option key={type + "-" + cl.idLigne} selected={type as ENU_LINE_TYPE === cl.lineType ? true : undefined} value={type}>
                                                                            {ENU_LINE_TYPE[type]}
                                                                        </option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td style={{ minWidth: "125px" }}>
                                                            <FIELD_TEXT
                                                                // ref={this.ref_LineAmount}
                                                                numberOnly={true}
                                                                title="***"
                                                                value={cl.amount.toString()}
                                                                F_Action={
                                                                    (value: string) => {
                                                                        this.onChangeLine(cl.idLigne, undefined,
                                                                            parseFloat(value)
                                                                            , undefined);
                                                                    }
                                                                }
                                                                required={false}
                                                                disable={!this.props.editMode}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select disabled={cl.lineType.toString() !== "facturation" || !this.props.editMode ? true : undefined} onChange={(e) => {
                                                                this.onChangeLine(cl.idLigne, undefined, undefined, e);
                                                            }} >
                                                                <option value={20} selected={cl.taxeRatio === 20 ? true : undefined}>20</option>
                                                                <option value={0} selected={cl.taxeRatio === 0 ? true : undefined}>0</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <textarea onChange={(e) => {
                                                                this.onChangeLine(cl.idLigne, undefined, undefined, undefined, e)
                                                            }} value={cl.description} disabled={!this.props.editMode} />
                                                        </td>
                                                        <td style={{ minWidth: "45px" }}>
                                                            <span>{
                                                                cl.lineType.toString() === "facturation" &&
                                                                cl.amount
                                                            }</span>
                                                        </td>
                                                        <td style={{ minWidth: "45px" }}>
                                                            <span>{
                                                                cl.lineType.toString() !== "facturation" &&
                                                                (cl.amount > 0 ? "-" : "") + cl.amount
                                                            }</span>
                                                        </td>
                                                        <td>
                                                            <span>{((cl.amount * cl.taxeRatio) / (100 + cl.taxeRatio)).toFixed(2)}</span>
                                                        </td>
                                                        <td>
                                                            <select onChange={(e) => {
                                                                this.onChangeLine(cl.idLigne, undefined, undefined, undefined, undefined, e, undefined);
                                                            }} disabled={!this.props.editMode}>
                                                                <option value={-1}>...</option>
                                                                {

                                                                    this.state.accounts.map(ca => {
                                                                        return <option key={"LINE_ACCOUNT" + ca.id} selected={cl.source === ca.id ? true : undefined} value={ca.id}>
                                                                            {
                                                                                ca.title
                                                                            } ({
                                                                                Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(ca.typeAccount) === index).length > 0 &&
                                                                                Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(ca.typeAccount) === index)[0].toString()
                                                                            })
                                                                        </option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select onChange={(e) => {
                                                                this.onChangeLine(cl.idLigne, undefined, undefined, undefined, undefined, undefined, e);
                                                            }} disabled={!this.props.editMode} >
                                                                <option value={-1}>...</option>
                                                                {
                                                                    this.state.accounts.map(ca => {
                                                                        return <option key={"LINE_ACCOUNT_CREDIT" + ca.id} selected={cl.destination === ca.id ? true : undefined} value={ca.id}>
                                                                            {
                                                                                ca.title
                                                                            }({
                                                                                Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(ca.typeAccount) === index).length > 0 &&
                                                                                Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(ca.typeAccount) === index)[0].toString()
                                                                            })
                                                                        </option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td style={{ width: "45px", textAlign: "center" }}>
                                                            {
                                                                this.props.editMode &&
                                                                <div>
                                                                    <div>
                                                                        <span className="COMPTA_LINE_BUTTON"
                                                                            onClick={
                                                                                () => this.onDeleteLine(cl)
                                                                            } dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 16, "d81313") }}></span>
                                                                        <span className="COMPTA_LINE_BUTTON"
                                                                            onClick={() => this.onAddNewLine(index, clg.split(";")[0], clg.split(";")[1])} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newItem, 16) }}></span>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            _index < this.state.comptaLines.filter(cl => cl.source === clg.split(";")[0] && cl.destination === clg.split(";")[1]).length - 1 &&
                                                                            <span onClick={() => {
                                                                                this.moveItemToAnOtherLine(_index, _index + 1);
                                                                            }} className="COMPTA_LINE_BUTTON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.arrow_go_bottom, 16) }}></span>
                                                                        }

                                                                        {
                                                                            _index > 0 &&
                                                                            <span onClick={() => {
                                                                                this.moveItemToAnOtherLine(_index, _index - 1);
                                                                            }} className="COMPTA_LINE_BUTTON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.arrow_go_top, 16) }}></span>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            }

                                                        </td>
                                                    </tr>
                                                })
                                        }

                                        {/* <tr className="COMPTA_GROUPE_OPERATION_ZONE">

                                    </tr> */}

                                        <tr>
                                            <td colSpan={8}>
                                                {
                                                    this.state.accounts.filter(a => a.id === clg.split(";")[0]).length > 0 &&
                                                    <span className="COMPTA_ACCOUNT_GROUP_NAME COMPTA_STAT_DEBIT">
                                                        {this.state.accounts.filter(a => a.id === clg.split(";")[0])[0].title}
                                                        (
                                                        {Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(this.state.accounts.filter(a => a.id === clg.split(";")[0])[0].typeAccount as ENU_ACCOUNT_TYPE) === index)[0]}
                                                        )
                                                    </span>
                                                }
                                                {
                                                    this.state.accounts.filter(a => a.id === clg.split(";")[0]).length === 0 &&
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cashMoney, 16) }}></span>
                                                }
                                                <span className="SPAN_SEP"></span>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.paid, 16, "#3c3c3c") }}>

                                                </span>
                                                <span className="SPAN_SEP"></span>
                                                {
                                                    this.state.accounts.filter(a => a.id === clg.split(";")[1]).length > 0 &&
                                                    <span className="COMPTA_ACCOUNT_GROUP_NAME COMPTA_STAT_CREDIT">
                                                        {this.state.accounts.filter(a => a.id === clg.split(";")[1])[0].title}
                                                        (
                                                        {Object.values(ENU_ACCOUNT_TYPE).filter((at, index) => Object.keys(ENU_ACCOUNT_TYPE).indexOf(this.state.accounts.filter(a => a.id === clg.split(";")[1])[0].typeAccount as ENU_ACCOUNT_TYPE) === index)[0]}
                                                        )
                                                    </span>
                                                }

                                                {
                                                    this.state.accounts.filter(a => a.id === clg.split(";")[1]).length === 0 &&
                                                    "..."
                                                }

                                            </td>
                                            <td className="COMPTA_TOTAL_GROUP COMPTA_STAT_DEBIT">
                                                -&nbsp;{
                                                    this.state.comptaLines
                                                        .filter(cl => cl.source === clg.split(";")[0] && cl.destination === clg.split(";")[1])
                                                        .filter(cl => cl.source === clg.split(";")[0] && cl.lineType.toString() !== "facturation")
                                                        .map(cl => { return cl.amount }).reduce((sum, num) => sum + num, 0).toFixed(2)
                                                }
                                            </td>
                                            <td className="COMPTA_TOTAL_GROUP COMPTA_STAT_CREDIT">
                                                {
                                                    this.state.comptaLines
                                                        .filter(cl => cl.source === clg.split(";")[0] && cl.destination === clg.split(";")[1])
                                                        .filter(cl => (cl.destination === clg.split(";")[1] || cl.lineType.toString() === "facturation"))
                                                        .map(cl => { return cl.amount }).reduce((sum, num) => sum + num, 0).toFixed(2)
                                                }
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tr>
                                })
                            }






                            {/* Bouton d'ajout d'une nouvelle ligne 
                        =======================================*/}
                            {
                                this.state.comptaLines.length === 0 &&
                                <tr>
                                    <td colSpan={10}>

                                    </td>
                                    <td>
                                        {
                                            this.props.editMode &&
                                            <span onClick={() => this.onAddNewLine(this.state.comptaLines.length, "-1", "-1")} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newItem, 16) }}></span>
                                        }

                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            }

        </div>
    }
}