import * as React from "react";
import { GetDistinctListOfString, GetIconFromType, IsComptaForm, IsCustForm } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_CUPBOARD_PROPS } from "../../02_Interfaces/I_CUPBOARD";
import { CONTEXTUEAL_MENU_ITEM } from "./CONTEXTUAL_MENU";
import "../../99_Styles/cupboard.css";
import { ENU_EYE_STATUS } from "../../02_Interfaces/I_EYE";
import { EYE_DYN } from "./EYE_DYN";
import { SEARCHBOX } from "./SEARCHBOX";
import { I_NOTE_ITEM } from "../../02_Interfaces/INOTE";


export class CUPBOARD extends React.Component<I_CUPBOARD_PROPS, {}> {

    private ref_SearchBox = React.createRef<SEARCHBOX>();


    getDistinctTags = (tags: string[][]) => {

        let tmptags: string[] = [];

        for (let x = 0; x < tags.length; x++) {
            for (let y = 0; y < tags[x].length; y++) {
                if (tmptags.filter(t => t === (tags[x][y]) && t.length > 0).length === 0) {
                    tmptags.push((tags[x][y]) as string);
                }
            }
        }


        return GetDistinctListOfString(tmptags);
    }




    render(): JSX.Element {
        return <div className="CUPBOARD_BOX" title={this.props.currentNote.showChilFromCupBoard ? "Cliquez dans la zone du titre du classeur pour fermer le classeur" : "Cliquez dans la zone du titre du classeur pour ouvrir le classeur"}>
            <div className="CUPBOARD_BANNER_ZONE" onClick={() => {
                (this.props.GLOBAL_FUNCTION.ShowCupBoardChild as Function)(this.props.currentNote.idnote)
            }}>
                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 22) }}></span>
                <span className="SPAN_SEP">
                </span>
                <span className="CUPBOARD_TITLE">
                    <span>{
                        IsCustForm(this.props.currentNote.description) ?
                            this.props.currentNote.title.split("]-")[1] :
                            this.props.currentNote.title
                    }</span>
                    <span className="SPAN_SEP"></span>
                    {
                        //Si  non classeur
                        //-----------------
                        <span >
                            <span>
                                <EYE_DYN
                                    allwaysOnTop={false}
                                    eye_status={
                                        this.props.currentNote.showChilFromCupBoard ? ENU_EYE_STATUS.open : ENU_EYE_STATUS.close
                                    }
                                />
                            </span>
                        </span>
                    }
                    <span style={{ fontSize: "11px" }}>
                        {
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined && this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).length > 0 &&
                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => t.taskdate !== null && t.taskdate.length > 0 && !t.finished).length > 0 &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                    {
                                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => (t.taskdate === null || (t.taskdate !== null && t.taskdate.length > 0)) && !t.finished).length
                                    }
                                </span>
                                <span>-</span>
                                <span style={{ color: "red" }}>{
                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => t.taskdate !== null && t.taskdate.length > 0 && !t.finished).length
                                }</span>
                            </span>
                        }
                    </span>

                </span>
                <span style={{ display: "block", backgroundColor: "#ffffff", borderRadius: "4px 1px 1px 1px" }} >
                    {
                        this.props.currentNote.tags.split(";").filter(t => t.length > 0).map(t => {
                            return <span key={t} className="HOME_STATE_TAGS_ZONE">
                                {
                                    t
                                }
                            </span>
                        })
                    }
                </span>

            </div>

            <div className="CUPBOARD_CHILD_DETAIL_BOX">

                <div className={this.props.currentNote.showChilFromCupBoard ? "CUBBOARD_ACTION_ZONE CUBBOARD_ACTION_ZONE_SELECTED" : "CUBBOARD_ACTION_ZONE"} >
                    <CONTEXTUEAL_MENU_ITEM menus={[

                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.markBook, 16),
                            description: "Mettre le classeur en favoris",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.piningNote as Function)(this.props.currentNote.idnote, !this.props.currentNote.pined)
                            },
                            subMenu: false,
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.PutInFront, 16),
                            description: "Mise en évidence de la note",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.currentNote)
                            },
                            subMenu: false
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.saveAsLocal, 16),
                            description: "Sauvegarder temporairement",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.onSaveLocalMemory as Function)(this.props.currentNote)
                            },
                            subMenu: false
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.newItem, 16),
                            description: "Ajouter une nouvelle note au classeur",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.addNewNoteInGroup as Function)(undefined, this.props.currentNote)
                            },
                            subMenu: false
                        }
                    ]} />
                </div>

                {
                    (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                        .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                            this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0
                        ).length > 0 &&
                    <div>
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 12) }}></span>
                            <span className="SPAN_SEP"></span>
                            <span>
                                {
                                    (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                                        .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                                            this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0
                                        ).length
                                }
                            </span>
                        </div>
                    </div>
                }


                <div >
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 12) }}></span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                GetDistinctListOfString(this.props.GLOBAL_DATA.DB_NOTES.filter(n => this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote).length > 0).map(n => { return n.type.toString() })).length
                            }
                        </span>
                        <span>:</span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                GetDistinctListOfString(this.props.GLOBAL_DATA.DB_NOTES.filter(n => this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote).length > 0).map(n => { return n.type.toString() }))
                                    .map(t => { return parseInt(t) }).map(t => {
                                        return <span key={t} className={this.props.currentNote.selectedTypeNote !== undefined && this.props.currentNote.selectedTypeNote.filter(_t => _t === t).length > 0 ? "HOME_STATE_TYPE_NOTE CUBBOARD_TYPE_SELECTED" : "HOME_STATE_TYPE_NOTE"}
                                            onClick={() => {
                                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote, t)
                                            }}
                                            style={{
                                                marginLeft: "3px", marginRight: "3px", fontSize: "10px", border: "2px solid #" + this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].colorString
                                            }}>
                                            &nbsp;
                                            {
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].title
                                            }
                                            &nbsp;
                                        </span>
                                    })

                            }
                        </span>
                        {
                            this.props.currentNote.selectedTypeNote !== undefined && this.props.currentNote.selectedTypeNote.length > 0 &&
                            <span onClick={() => {
                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote)
                            }}>
                                <span className="SPAN_SEP">
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.clearFilter, 12, "d61919") }}>

                                </span>
                            </span>
                        }
                    </div>
                </div>
                <div>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.tag, 12) }}></span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.getDistinctTags
                                    (this.props.GLOBAL_DATA.DB_NOTES
                                        .filter(n => this.props.currentNote.associations !== null && this.props.currentNote.associations
                                            .split(";")
                                            .filter(a => a.length > 0 && parseInt(a) === n.idnote).length > 0)
                                        .map(n => { return n.tags.split(";") })).length

                            }
                        </span>
                        <span>:</span>
                        <span>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.getDistinctTags
                                    (this.props.GLOBAL_DATA.DB_NOTES
                                        .filter(n => this.props.currentNote.associations !== null && this.props.currentNote.associations
                                            .split(";")
                                            .filter(a => a.length > 0 && parseInt(a) === n.idnote).length > 0)
                                        .map(n => { return n.tags.split(";") }))
                                    .map(t => {
                                        return <span key={t} onClick={() => {
                                            (this.props.GLOBAL_FUNCTION.SelectTagNoteInCubBoard as Function)(this.props.currentNote.idnote, t)
                                        }}>
                                            <span className={this.props.currentNote.selectedTags !== undefined && this.props.currentNote.selectedTags.filter(_t => _t === t).length > 0 ? "HOME_STATE_TAGS_ZONE CUPBOARD_TAG_SELECTED" : "HOME_STATE_TAGS_ZONE"}>{t}</span>
                                        </span>
                                    })
                            }
                        </span>

                        {
                            this.props.currentNote.selectedTags !== undefined && this.props.currentNote.selectedTags.length > 0 &&
                            <span onClick={() => {
                                (this.props.GLOBAL_FUNCTION.SelectTagNoteInCubBoard as Function)(this.props.currentNote.idnote)
                            }}>
                                <span className="SPAN_SEP">
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.clearFilter, 12, "d61919") }}>

                                </span>
                            </span>
                        }

                    </div>
                </div>
                <div>

                    {
                        (
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                            (this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                !t.finished && t.taskdate.length > 0 &&
                                this.props.currentNote.associations !== null &&
                                this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                            ).length > 0 ||
                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                    !t.finished && t.taskdate.length === 0 &&
                                    this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                ).length > 0
                            )
                        ) &&
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.unCheckedTask, 12) }}></span>
                            {
                                //Les taches non termiée et datées dans le classeur
                                //--------------------------------------------------
                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                    !t.finished && t.taskdate.length > 0 &&
                                    this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                ).length > 0 &&
                                <span>
                                    <span className="SPAN_SEP"></span>
                                    <span className="CUPBOARD_TASK_TODO_BOX">
                                        <span style={{ fontWeight: "bold" }}>
                                            {

                                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                                    !t.finished && t.taskdate.length > 0 &&
                                                    this.props.currentNote.associations !== null &&
                                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                                ).length
                                            }
                                        </span>&nbsp;
                                        <span>Courante(s)</span>
                                    </span>
                                </span>
                            }

                            {
                                //Les taches non termiée et non datées dans le classeur
                                //--------------------------------------------------
                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                    !t.finished && t.taskdate.length === 0 &&
                                    this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                ).length > 0 &&
                                <span >
                                    <span className="SPAN_SEP"></span>
                                    <span className="CUPBOARD_TASK_UNDATED_BOX">
                                        <span style={{ fontWeight: "bold" }}>
                                            {

                                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                                    !t.finished && t.taskdate.length === 0 &&
                                                    this.props.currentNote.associations !== null &&
                                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                                ).length
                                            }
                                        </span>&nbsp;
                                        <span>A faire</span>
                                    </span>
                                </span>
                            }


                        </div>
                    }


                </div>

                <div className="CUPBOARD_SEARCH_ZONE">
                    {
                        this.props.currentNote.showChilFromCupBoard &&
                        <SEARCHBOX ref={this.ref_SearchBox} placeHolder="Recherche dans les notes ..." forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={() => {
                            if (this.ref_SearchBox.current !== null) {
                                (this.props.GLOBAL_FUNCTION.SearchNoteInCupBoard as Function)(this.props.currentNote.idnote, this.ref_SearchBox.current.state.searchValue);
                            }
                        }} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                    }

                    <span>
                        {
                            (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                                .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0 &&
                                    (
                                        this.props.currentNote.selectedTypeNote !== undefined &&
                                            this.props.currentNote.selectedTypeNote.length > 0 &&
                                            this.props.currentNote.selectedTypeNote.filter(tn => tn === _n.type).length === 0 ? false : true
                                    )
                                    &&
                                    (
                                        this.props.currentNote.selectedTags !== undefined &&
                                            this.props.currentNote.selectedTags.length > 0 &&
                                            this.props.currentNote.selectedTags.filter(t => t === _n.tags !== null && _n.tags.split(";").filter(_t => _t === t).length > 0).length === 0 ? false : true
                                    )
                                ).length
                        }
                    </span>
                </div>

            </div>
        </div>
    }
}