import { ENU_ICON_TYPE, I_KEY_VALUE_DATA, I_NOTIF, I_RESULT } from "../02_Interfaces/IGLOBAL";
import { DeleteNotif, DoesUserSessionIsActive, GetAllGroupsNotes, GetAllNotif, GetNote } from "./DB_WS";
import { I_GROUPE_NOTE_ITEM, I_NOTE_ITEM } from "../02_Interfaces/INOTE";
import { decodeUtf8, GetCookie, GetDistinctListOfString, SetCookie } from "./tools";
import { Guid } from "guid-typescript";




export class ORDO_PROCESS_ITEM {
    private static instance: ORDO_PROCESS_ITEM = new ORDO_PROCESS_ITEM();

    private constructor() {

        setInterval( () => {
            this.getAllSessionNotif()
            // console.log(this.DB_NOTIF);
        }, 1000);

        setInterval(() => {
            this.IsUserIsConnected();
        }, 10000);

    }


    //Instance de la classe
    //---------------------
    public static getInstance(): ORDO_PROCESS_ITEM {

        if (this.instance === null) {
            this.instance = new ORDO_PROCESS_ITEM();
        }

        return this.instance;
    }



    //Propriétés de l'instance
    //------------------------
    runingSession: boolean = false;
    USER_CONNECTION_ID?: string = undefined;
    DB_NOTIF: I_NOTIF[] = [];
    DB_LOCKED_NOTE: I_NOTIF[] = [];
    DB_NOTIF_NOTE: I_NOTE_ITEM[] = [];
    DB_GROUPS: I_GROUPE_NOTE_ITEM[] = [];
    DB_NOTE_TO_UPDATE: I_NOTE_ITEM[] = [];
    DB_PARENT_FORM: I_NOTE_ITEM[] = [];
    DB_TASG: string[] = [];
    ACTION_INFO: I_KEY_VALUE_DATA[] = [];
    isUserIsConnected: boolean = false;


    public runSessionProcess = async (userId: string) => {
        this.USER_CONNECTION_ID = userId;
        this.getAllSessionNotif();
    }

    public computNoteToUpdate = async (local_DB_Notes?: I_NOTE_ITEM[], selectedGroup?: I_GROUPE_NOTE_ITEM) => {

        //Mise à jour des données si besoin
        (this.DB_NOTIF as I_NOTIF[]).forEach((n) => {

            //Mise à jour de la note
            if (n.noteId > 0 && local_DB_Notes !== undefined) {
                if ((local_DB_Notes as I_NOTE_ITEM[]).filter(note =>
                    !n.readed &&
                    n.noteId === note.idnote && selectedGroup !== undefined &&
                    n.groupId === selectedGroup.id).length > 0) {
                    //Mise à jour de la note
                    let tmpDBNOTES: I_NOTE_ITEM[] = (local_DB_Notes as I_NOTE_ITEM[]);
                    setTimeout(async () => {
                        GetNote((selectedGroup as I_GROUPE_NOTE_ITEM).id, this.USER_CONNECTION_ID as string, n.noteId,  (data: I_RESULT) => {
                            if (data.status) {
    
                                let tmpNote: I_NOTE_ITEM = (tmpDBNOTES as I_NOTE_ITEM[]).filter(_n => _n.idnote === n.noteId)[0];
                                let tmpGetedNote: I_NOTE_ITEM = (data.data as I_NOTE_ITEM);
                                tmpNote.title = tmpGetedNote.title;
                                tmpNote.associations = tmpGetedNote.associations;
                                tmpNote.editor = tmpGetedNote.editor;
                                tmpNote.color = tmpGetedNote.color;
                                tmpNote.colorString = tmpGetedNote.colorString;
                                tmpNote.description = decodeUtf8((tmpGetedNote.description as any).data);
                                tmpNote.descriptionraw = tmpGetedNote.descriptionraw;
                                tmpNote.modified = tmpGetedNote.modified;
                                tmpNote.pined = (tmpGetedNote.pined as any) as number === 0 ? false : true;
                                tmpNote.tags = tmpGetedNote.tags;
                                tmpNote.type = tmpGetedNote.type;
                                tmpNote.typeNoteString = tmpGetedNote.typeNoteString;
    
                                this.DB_NOTE_TO_UPDATE.push(tmpGetedNote);
                            } else {
                                //-->
                            }
                        }, true)
                    }, 300);
                    
                } else {
                    //Ajout de la nouvelle note
                    GetNote((selectedGroup as I_GROUPE_NOTE_ITEM).id, this.USER_CONNECTION_ID as string, n.noteId, (data: I_RESULT) => {
                        if (data.status) {

                            let tmpGetedNote: I_NOTE_ITEM = (data.data as I_NOTE_ITEM);

                            if (tmpGetedNote !== undefined) {
                                let tmpNewNote: I_NOTE_ITEM = {
                                    title: tmpGetedNote.title,
                                    associations: tmpGetedNote.associations,
                                    editor: tmpGetedNote.editor,
                                    color: tmpGetedNote.color,
                                    colorString: tmpGetedNote.colorString,
                                    description: decodeUtf8((tmpGetedNote.description as any).data),
                                    descriptionraw: tmpGetedNote.description,
                                    created: tmpGetedNote.created,
                                    isAuthor: tmpGetedNote.isAuthor,
                                    autor: tmpGetedNote.autor,
                                    modified: tmpGetedNote.modified,
                                    pined: (tmpGetedNote.pined as any) as number === 0 ? false : true,
                                    type: tmpGetedNote.type,
                                    typeNoteString: tmpGetedNote.typeNoteString,
                                    idnote: tmpGetedNote.idnote,
                                    tags: tmpGetedNote.tags,
                                    idgroupnote: tmpGetedNote.idgroupnote,
                                    displayUnity: tmpGetedNote.displayUnity,
                                    showChilFromCupBoard: false,
                                    searInChildFromCubBoard: "",
                                    selectedTypeNote: [],
                                    selectedTags: []
                                };

                                this.DB_NOTE_TO_UPDATE.push(tmpNewNote);


                            }

                        } else {
                            //-->
                        }
                    }, true)
                }
            }
            //-----------------------

        });
    }

    public computTags = async (local_DB_Notes?: I_NOTE_ITEM[]) => {
        if (local_DB_Notes !== undefined) {
            let tmpFinalTags: string[] = [];


            for (let x = 0; x < local_DB_Notes.length; x++) {
                let tmpTags: string[] = local_DB_Notes[x].tags.split(";");
                for (let y = 0; y < tmpTags.length; y++) {
                    if (tmpFinalTags.filter(_t => _t === tmpTags[y]).length === 0) {
                        tmpFinalTags.push(tmpTags[y]);
                    }
                }
            }


            this.DB_TASG = tmpFinalTags.filter(t => t.length > 0)
                .sort((a, b) => { return a.localeCompare(b) });


        } else {
            this.DB_TASG = [];
        }

        // console.log(this.DB_TASG);
    }

    public loadParentFormData = async (groupNote: I_GROUPE_NOTE_ITEM, parentForm: I_NOTE_ITEM[]) => {
        if (this.DB_PARENT_FORM.length === 0)
            this.runingSession = true;
        try {
            (parentForm.filter(n => n.description.length === 0)).forEach(form => {
                GetNote(groupNote.id, this.USER_CONNECTION_ID as string, form.idnote,  (data: I_RESULT) => {
                    if (data.status && data.data !== undefined) {
                        const tmpNoteItem: I_NOTE_ITEM = data.data as I_NOTE_ITEM;
                        form.description = tmpNoteItem.description;
                        form.descriptionraw = tmpNoteItem.descriptionraw;


                        this.DB_PARENT_FORM = this.DB_PARENT_FORM.filter(f => f.idnote !== form.idnote);

                        this.DB_PARENT_FORM.push(form);
                    } else {
                        //-->
                    }
                }, true)
            });
        } finally {
            this.runingSession = false
        }
    }

    public pushActionInfo =   (actionInfo: I_KEY_VALUE_DATA) => {
        this.ACTION_INFO.push(actionInfo);
    }

    private getAllSessionNotif =  async () => {

        if (this.USER_CONNECTION_ID !== undefined && !this.runingSession) {

            this.runingSession = true;//Déclanchement

            try {

                GetAllNotif(this.USER_CONNECTION_ID, (data: I_RESULT) => {
                    if (data.status) {
                        //Calcul des notifications et des notes lockées
                        this.DB_LOCKED_NOTE = (data.data as I_NOTIF[]).filter(n => !n.asComment && n.asLock && (n.readed as any).toString() ==="0");
                        this.DB_NOTIF = (data.data as I_NOTIF[]).filter(n => !n.asComment && !n.asLock && (n.readed as any).toString() ==="0");

                    }
                }).then(() => {
                    // console.log("ici");
                    //Nettoyage des notifications orphelines
                    //--------------------------------------
                    if (this.DB_NOTIF.length > 0 && this.USER_CONNECTION_ID !== undefined) {
                        GetAllGroupsNotes(this.USER_CONNECTION_ID, (data: I_RESULT) => {
                            if (data.status) {

                                this.DB_GROUPS = data.data as I_GROUPE_NOTE_ITEM[];

                                this.runingSession = false;//finalisation
                            } else {
                                this.runingSession = true;//finalisation
                            }
                        })

                        let notifID:string[] = GetDistinctListOfString(this.DB_NOTIF.map(n=>{return n.noteId.toString()}));


                        for (let x = 0; x < (notifID).length; x++) {
                            let notifIDItem: number = parseInt(notifID[x]);
                            // if (n.noteId !== null && n.noteId > 0 && this.DB_NOTIF_NOTE.filter(_n => _n !== undefined && _n.idnote === n.noteId).length === 0) {
                                //chargement de la note
                                //--------------------- 
                                GetNote(this.DB_NOTIF.filter(_notif=>_notif.noteId === notifIDItem)[0].groupId, (this.USER_CONNECTION_ID as string),notifIDItem, (data: I_RESULT) => {
                                    if (data.status && (data.data) !== undefined) {
                                        //let tmpAPPDATA: I_APP_GLOBAL_DATA = this.state.GLOBAL_DATA;

                                        if (notifIDItem !== null && notifIDItem > 0 && this.DB_NOTIF_NOTE.filter(_n => _n !== undefined && _n.idnote === notifIDItem).length === 0) {
                                            this.DB_NOTIF_NOTE.push(data.data as I_NOTE_ITEM);
                                        } else {
                                            this.DB_NOTIF_NOTE.filter(_n => _n !== undefined && _n.idnote === notifIDItem)[0].title = (data.data as I_NOTE_ITEM).title;
                                        }
                                    } else {
                                        //Suppression de la notification orpheline
                                        DeleteNotif(notifIDItem, (data: I_RESULT) => {
                                            if (data.status) {
                                                //-->
                                            } else {
                                                //-->
                                            }
                                        });
                                    }
                                }, true);
                            // }
                        }

                        console.log(this.DB_NOTIF);

                    } else {
                        this.runingSession = false;//finalisation
                    }
                    // console.log("tack");

                    this.runingSession = false;//finalisation
                })

            } finally {
                
            }
        }

    }

    //Contrôle de session et rafraichissement du jeton
    //=================================================
    public IsUserIsConnected = async (forceUserId?: string) => {
        if (this.USER_CONNECTION_ID !== undefined && !this.runingSession)
            DoesUserSessionIsActive(forceUserId !== undefined ? forceUserId : this.USER_CONNECTION_ID,  (data: I_RESULT) => {
                if (data.status) {
                    this.isUserIsConnected = true;
                    SetCookie("waspsu", data.data, 14);
                    this.USER_CONNECTION_ID = data.data;
                } else {
                    this.isUserIsConnected = false;
                    this.USER_CONNECTION_ID = undefined;
                    SetCookie("waspsu", "", 2);
                }
            })


    }

}