import * as React from "react";
import { APPVERSIONREF } from "../../01_APPDATA/DB_APP_VERSION";
import { I_VERSION_CHECKER_PROPS } from "../../02_Interfaces/I_VERSION_CHECKER";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { ENU_IMG } from "../../01_APPDATA/DB_IMG";
import { ENU_ICON_TYPE, I_CONFIG_ITEM } from "../../02_Interfaces/IGLOBAL";
import { COOKIEFUNCTION, GetIconFromType } from "../../04_TOOLS/tools";


export class VERSION_CHECKER extends React.Component<I_VERSION_CHECKER_PROPS, {}>{

    onShowCurrentVersion() {
        let tmpMSG: I_MSG_OPTION = {
            allMSG: [
                {
                    key: "Publication de version : V" + (APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_3),
                    value: "La version <b>" + (APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "<span class='SPAN_SEP'></span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "<span class='SPAN_SEP'></span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_3) +
                        "</b> est maintenant <b>disponible</b> !" +

                        "<p>Elle concerne le(s) sujet(s) suivant : </p>" +
                        (
                            APPVERSIONREF[APPVERSIONREF.length - 1].v_1_description !== undefined ?
                                "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppChange, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_1_description + "</span></p>" : ""
                        ) +
                        (
                            APPVERSIONREF[APPVERSIONREF.length - 1].v_2_description !== undefined ?
                                "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppEvolution, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_2_description + "</span></p>" : ""
                        ) +
                        (
                            APPVERSIONREF[APPVERSIONREF.length - 1].v_3_description !== undefined ?
                                "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppCorrection, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_3_description + "</span></p>" : ""
                        ),
                    data: ENU_ICON_TYPE.delivery
                }
            ],
            postedData: ENU_IMG.NewPublication,
            buttons: [
                {
                    F_BUTTON_ACTION: () => {
                        (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                    },
                    title: "OK"
                }
            ],
            showCloseButton: false
        };

        (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);
    }

    componentDidMount(): void {

        //controle de la visualisation de la version
        if (this.props.GLOBAL_DATA.DB_CONFIG !== undefined && this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === ("V_" + APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_3 + "_P")).length === 0 ||
            this.props.GLOBAL_DATA.DB_CONFIG !== undefined && this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === ("V_" + APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_3 + "_P") && c.configValue === "Vue").length === 0) {
            let tmpMSG: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Publication de version : V" + (APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_3),
                        value: "La version <b>" + (APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "<span class='SPAN_SEP'></span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "<span class='SPAN_SEP'></span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_3) +
                            "</b> est maintenant <b>disponible</b> !" +

                            "<p>Elle concerne le(s) sujet(s) suivant : </p>" +
                            (
                                APPVERSIONREF[APPVERSIONREF.length - 1].v_1_description !== undefined ?
                                    "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppChange, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_1_description + "</span></p>" : ""
                            ) +
                            (
                                APPVERSIONREF[APPVERSIONREF.length - 1].v_2_description !== undefined ?
                                    "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppEvolution, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_2_description + "</span></p>" : ""
                            ) +
                            (
                                APPVERSIONREF[APPVERSIONREF.length - 1].v_3_description !== undefined ?
                                    "<p><span>" + GetIconFromType(ENU_ICON_TYPE.AppCorrection, 24) + "</span><span class='SPAN_SEP'></span><span>" + APPVERSIONREF[APPVERSIONREF.length - 1].v_3_description + "</span></p>" : ""
                            ),
                        data: ENU_ICON_TYPE.delivery
                    }
                ],
                postedData: ENU_IMG.NewPublication,
                buttons: [
                    {
                        F_BUTTON_ACTION: () => {
                            (this.props.GLOBAL_FUNCTION.onUpdateOrCreateParam as Function)({
                                idConfig: -1,
                                configName: ("V_" + APPVERSIONREF[APPVERSIONREF.length - 1].v_1 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_2 + "." + APPVERSIONREF[APPVERSIONREF.length - 1].v_3 + "_P"),
                                configValue: "Vue"
                            } as I_CONFIG_ITEM);
                            (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                        },
                        title: "Yeah!"
                    }
                ],
                showCloseButton: false
            };

            (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);
        }

        if (this.props.GLOBAL_DATA.DB_CONFIG !== undefined &&
            (
                this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === COOKIEFUNCTION.key).length === 0 ||
                this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === COOKIEFUNCTION.key && c.configValue === "Non").length > 0
            )
        ) {
            let tmpMSG: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Cookies de fonctionnement ...",
                        value: "L'option d'utilisation des cookies de fonctionnement n'est pas activée. Cette option est fortement conseillée afin de maintenir votre session active.",
                        data: ENU_ICON_TYPE.AppChange
                    }
                ],
                showCloseButton: true,
                buttons: [
                    {
                        title: "Activer les cookies",
                        F_BUTTON_ACTION: () => {
                            (this.props.GLOBAL_FUNCTION.onUpdateOrCreateParam as Function)({
                                idConfig:this.props.GLOBAL_DATA.DB_CONFIG!==undefined && this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === COOKIEFUNCTION.key && c.configValue === "Non").length > 0 ? this.props.GLOBAL_DATA.DB_CONFIG.filter(c => c.configName === COOKIEFUNCTION.key && c.configValue === "Non")[0].idConfig : -1,
                                configName: COOKIEFUNCTION.key,
                                configValue: "Oui"
                            } as I_CONFIG_ITEM);

                            (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                        }
                    }
                ]
            };
            (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);
        }


    }

    render(): JSX.Element {
        return <div className="APP_VERSION_BOX" onClick={() => {
            this.onShowCurrentVersion();
        }} title="En savoir plus sur la version courante ? ...">
            <span>
                {
                    APPVERSIONREF[APPVERSIONREF.length - 1].appliTitle
                }
            </span>
            &nbsp;&nbsp;
            <span className="APP_VERSION_VALUES" title={APPVERSIONREF[APPVERSIONREF.length - 1].description as string}>
                <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_1}</span>
                <span className="SPAN_SEP"></span>
                <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_2}</span>
                <span className="SPAN_SEP"></span>
                <span>{APPVERSIONREF[APPVERSIONREF.length - 1].v_3}</span>
            </span>
            <span className="APP_MODE">
                Accès anticipé
            </span>
        </div>
    }

}