import * as React from "react";
import { I_FOREST, I_FOREST_STATE } from "../../02_Interfaces/I_FOREST";
import "../../99_Styles/forest.css";
import { FOREST_NUANCE, FOREST_NUANCE_EXCEPT } from "../../01_APPDATA/DB_APP";
import { GetIconFromType } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";

export class FOREST extends React.Component<{}, I_FOREST_STATE>{

    constructor(props: any) {
        super(props);

        this.state = {
            allForest: [],
        }
    }


    componentDidMount(): void {
        let finalForest: I_FOREST[] = [];

        for (let x = 0; x < 35; x++) {
            for (let y = 0; y < 35; y++) {
                finalForest.push({
                    id: x + "." + y,
                    X_Index: x,
                    Y_Index: y,
                    light: false,
                    backgroundColor: "#" + (FOREST_NUANCE[Math.floor(Math.random() * FOREST_NUANCE.length)])
                });
            }
        }

        this.setState({ allForest: finalForest }, async () => {
            const Interval = setInterval(async () => {
                let tmpAllForest: I_FOREST[] = this.state.allForest;

                if (tmpAllForest.filter(f => !f.light).length > 0) {
                    if (tmpAllForest.filter(f => !f.light && FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id).length > 0).length > 0) {
                        tmpAllForest.filter(f => !f.light && FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id).length > 0)[0].light = true;
                    }

                    for (let x = 0; x < 5; x++) {
                        let indexToCheck: number = Math.floor(Math.random() * tmpAllForest.filter(f => !f.light).length);
                        if (!tmpAllForest.filter(f => !f.light)[indexToCheck].light) {
                            tmpAllForest.filter(f => !f.light)[indexToCheck].light = true;
                        }
                    }


                    for (let x = 0; x < 10; x++) {
                        let indexToCheck: number = Math.floor(Math.random() * tmpAllForest.length);
                        tmpAllForest[indexToCheck].light = false;
                    }

                } else {

                    clearInterval(Interval);
                }
                this.setState({ allForest: tmpAllForest })
            }, 700);

            // Interval();
        });
    }


    render(): JSX.Element {
        return <div className="FOREST_BACKGROUND">

            {
                this.state.allForest.map(f => {
                    return <div className={f.light ? "FOREST_ITEM FOREST_ITEM_LIGHT" : "FOREST_ITEM"}
                        style={{
                            backgroundColor: f.light ? /*f.backgroundColor*/"#ff9000" : undefined,
                            rotate: FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id && f.light).length > 0 ? "-0deg" : undefined,
                            zIndex: FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id && f.light).length > 0 ? "120" : undefined,
                            opacity: FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id && f.light).length > 0 ? undefined : 0.1
                        }} >
                        {
                            FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id).length > 0 &&
                            <span className="FOREST_APP_NAME">{FOREST_NUANCE_EXCEPT.filter(fne => fne.key === f.id)[0].value}</span>
                        }
                    </div>
                })
            }

            <div className="FOREST_FONDU_BOX">
            <div className='WAVE_BOX'><svg viewBox="0 0 500 150" preserveAspectRatio="none" className='WAVE_ITEM'><path d="M0.00,49.99 C149.99,150.00 349.81,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" className='WAVE_CONTENT'></path></svg></div>
                <div className='WAVE_BOX'><svg viewBox="0 0 500 150" preserveAspectRatio="none" className='WAVE_ITEM'><path d="M-37.81,121.08 C245.94,145.09 185.93,7.09 517.81,138.09 L500.00,150.00 L0.00,150.00 Z" className='WAVE_CONTENT_'></path></svg></div>
                <div className='WAVE_BOX'><svg viewBox="0 0 500 150" preserveAspectRatio="none" className='WAVE_ITEM'><path d="M-8.43,124.09 C357.18,-14.90 472.80,-44.90 624.06,92.09 L500.00,150.00 L0.00,150.00 Z" className='WAVE_CONTENT_'></path></svg></div>
                <span className="APP_LOGO_IN_FOREST" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.WASPNOTE, 3, "000000") }}>

                </span>
                <div className="FOREST_FOOTER_BOX">
                    <span>Powered by <a href="mailto:didier.sainsily@gmail.com" className="APP_CONTACT">DSY</a></span>
                </div>

                <div className="FOREST_SLOGANT">
                    Toutes vos notes à porté de clic ...
                </div>
            </div>
        </div>
    }
}