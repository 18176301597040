import { I_APP_GLOBAL_DATA, I_APP_GLOBAL_FUNCTIONS } from "./IGLOBAL";
import { I_TYPENOTE } from "./INOTE";

export interface I_WIDGET_PROPS{
    typesNotes:I_TYPENOTE[];
    tags:string[];
    noteCount:number;
    selectedType:number[];
    selectedTag:string[];
    editType:number;

    //-----------Menu du widget---------
    selectedMenu:ENU_WIDGET_MENU_TYPE;
    //----------------------------------

    APP_GLOBAL_FUNCTION:I_APP_GLOBAL_FUNCTIONS;
    APP_GLOBAL_DATA:I_APP_GLOBAL_DATA;
}

export interface I_WIDGET_PROPS_STATE{
    currentEditType?:I_TYPENOTE;
    searchKeyValue:string;
}

export enum ENU_WIDGET_MENU_TYPE{
    tag,
    typeNote,
    favorites,
    nothing
}