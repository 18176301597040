import { data } from "jquery";
import * as React from "react";
import { ENU_ICON_TYPE, I_RESULT } from "../../02_Interfaces/IGLOBAL";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { I_User } from "../../02_Interfaces/IUser";
import { I_USER_PROFIL_ITEM, I_USER_PROFIL_PROPS, I_USER_PROFIL_PROPS_STATE } from "../../02_Interfaces/IUSERPROFIL";
import { CreateUserProfil, GetUserLogin, GetUserProfil, UpdateUserProfil } from "../../04_TOOLS/DB_WS";
import { encrypt, GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/formGlobal.css";
import "../../99_Styles/userProfil.css";
import { APP_BUTTON } from "../Components/BUTTON";
import { FIELD_CHECK_BOX } from "../Fields/FIELD_CHECK_BOX";
import { FIELD_RETYPE_PASS } from "../Fields/FIELD_RETYPE_PASS";
import { FIELD_TEXT } from "../Fields/FIELD_TEXT";

export class USER_PROFIL extends React.Component<I_USER_PROFIL_PROPS, I_USER_PROFIL_PROPS_STATE>{
    constructor(props: I_USER_PROFIL_PROPS) {
        super(props);
        this.state = {
            editPWMode: false
        }

        this.onValidateForm = this.onValidateForm.bind(this);
        this.onEditMode = this.onEditMode.bind(this);
        this.onLoadUserProfil = this.onLoadUserProfil.bind(this);
    }

    //Les références
    private refLayer = React.createRef<HTMLDivElement>();
    private refLogin = React.createRef<FIELD_TEXT>();
    private refName = React.createRef<FIELD_TEXT>();//Prénom
    private refLasName = React.createRef<FIELD_TEXT>();//Nom
    private refEmail = React.createRef<FIELD_TEXT>();//Adresse email
    private refVisibility = React.createRef<FIELD_CHECK_BOX>();
    private refRePass = React.createRef<FIELD_RETYPE_PASS>();
    //==============

    onValidatePassWord() {
        if (this.refRePass.current !== null) {
            this.refRePass.current.onValidateForm();
            if (this.refRePass.current.state.fieldIsOk) {

                //Préparation du message de confimration

                //--------------------------------------

                (this.props.APP_GLOBAL_FUNCTION.onUpdatePW as Function)(encrypt(this.refRePass.current.getFinalPW() as string));
                this.setState({ editPWMode: false }, () => {
                    this.onLoadUserProfil();
                });


            } else {
                //-->
            }
        }
    }

    onEditMode() {
        this.setState({ editPWMode: !this.state.editPWMode }, () => {
            if (!this.state.editPWMode) {
                this.onLoadUserProfil();
            }
        });
    }

    onLoadUserProfil() {
        GetUserLogin((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (data: I_RESULT) => {
            if (data.status) {
                this.refLogin.current?.onForceFieldValue(data.data[0].login);

                //Chargement des données de l'utilisateur
                GetUserProfil((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, (data: I_RESULT) => {
                    if (data.status) {
                        let userProfil: I_USER_PROFIL_ITEM = (data.data as I_USER_PROFIL_ITEM[])[0];
                        userProfil.visibility = (data.data)[0].visibility === 1 ? true : false;
                        userProfil.active = (data.data)[0].active === 1 ? true : false;

                        // Mise à jour des données localStorage
                        this.refName.current?.onForceFieldValue(userProfil.lastName);
                        this.refLasName.current?.onForceFieldValue(userProfil.firstName);
                        this.refEmail.current?.onForceFieldValue(userProfil.email);
                        this.refVisibility.current?.onForceUpdateValue(userProfil.visibility);
                        //=======================================
                    } else {

                    }
                });
            } else {
                //-->
            }
        })


        //=======================================
    }

    componentDidMount() {
        this.onLoadUserProfil();
    }


    onValidateForm() {
        this.refEmail.current?.forceUpdate();
        this.refLasName.current?.forceUpdate();
        this.refName.current?.forceUpdate();
        this.refLogin.current?.forceUpdate();
        this.refVisibility.current?.forceUpdate();

        if (!this.refEmail.current?.state.fieldIsOk ||
            !this.refLasName.current?.state.fieldIsOk ||
            !this.refLogin.current?.state.fieldIsOk ||
            !this.refName.current?.state.fieldIsOk) {
            const MSGOption: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Mise à jour du profil",
                        value: "Un ou plusieurs champs requis sont manquants.",
                        data: ENU_ICON_TYPE.user
                    }
                ],
                buttons: [
                    {
                        title: "OK",
                        F_BUTTON_ACTION: () => {
                            (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)()
                        }
                    }
                ],
                showCloseButton: false
            };
            (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(MSGOption);
        } else {
            let tmpName: string = this.refName.current.state.value;
            let tmpLastName: string = this.refLasName.current.state.value;
            let tmpEmail: string = this.refEmail.current.state.value;
            let tmpVisibility: boolean = false;
            if (this.refVisibility.current !== null)
                tmpVisibility = this.refVisibility.current.state.checked;

            if (this.refVisibility.current !== null) {
                tmpVisibility = this.refVisibility.current.state.checked;
            }

            let tmpOption: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Modification du porifil utilisateur",
                        value: "Voulez vous réellement mêtre à jour les données du profil ?",
                        data: ENU_ICON_TYPE.user
                    },
                ],
                buttons: [
                    {
                        title: "Modifier",
                        F_BUTTON_ACTION: () => {
                            if (this.props.APP_GLOBAL_DATA.userProfil !== undefined) {
                                //Sauvegarde du formulaire
                                UpdateUserProfil((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, tmpEmail, tmpLastName, tmpName, tmpVisibility, (data: I_RESULT) => {
                                    if (data.status) {

                                        //Mise à jour du profil utilisateur

                                        //---------------------------------
                                        (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                                        (this.props.APP_GLOBAL_FUNCTION.getAllGroups as Function)();

                                    } else {
                                        (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                                    }
                                })
                                //---------------------------
                            } else {
                                CreateUserProfil((this.props.APP_GLOBAL_DATA.connectedUser as I_User).id, tmpEmail, tmpLastName, tmpName, tmpVisibility, (data: I_RESULT) => {
                                    if (data.status) {
                                        (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                                    } else {
                                        //-->
                                    }
                                });
                            }
                        }
                    }, {
                        title: "Annuler",
                        F_BUTTON_ACTION: () => {
                            (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                            //---------------------------
                        }
                    }
                ],
                showCloseButton: false,
            };

            (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(tmpOption);
            (this.props.APP_GLOBAL_FUNCTION.onShowUserProfil as Function)();
        }
    }


    render(): JSX.Element {
        return <div className="POPUP_BOX">

            {/* font blanc non opaque  */}
            <div className="POPUP_LAYER" style={{ opacity: 0.4 }} ref={this.refLayer}></div>

            <div className="POPUP_BODY_BOX">
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.user, 24, "#c3c3c3") }}></span>
                        &nbsp;
                        <span>
                            <span>Profil utilisateur</span>
                            {
                                this.state.editPWMode &&
                                <span> - Mot de passe</span>
                            }
                        </span>
                    </span>

                    {/* Bouton de fermeture de la popup */}
                    <div
                        onClick={() => (this.props.APP_GLOBAL_FUNCTION.onShowUserProfil as Function)()}
                        className="POPUP_CLOSE_BUTTON"
                        dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18) }}>
                    </div>
                </div>

                {/* Contenu du formulaire de gestion des propriétés du profil */}
                <form>
                    {
                        !this.state.editPWMode &&
                        <div className="POPUP_BODY">
                            {/* Login utilisateur */}
                            <FIELD_TEXT
                                title="Login"
                                value={""}
                                required={true}
                                ref={this.refLogin}
                                asDisplayMode={true}
                            />


                            {/* Nom utilisateur */}
                            <FIELD_TEXT
                                title="Nom"
                                value={""}
                                required={true}
                                ref={this.refLasName}
                            />

                            {/* Prénom utilisateur */}
                            <FIELD_TEXT
                                title="Prénom"
                                value={""}
                                required={true}
                                ref={this.refName}
                            />

                            {/* Adresse email */}
                            <FIELD_TEXT
                                title="Email"
                                value={""}
                                required={true}
                                asEmail={true}
                                ref={this.refEmail}
                            />

                            {/* Visibilité du profil */}
                            <FIELD_CHECK_BOX
                                title="Visible"
                                defaultValue={false}
                                ref={this.refVisibility}
                            />
                        </div>
                    }

                    {/* contenu du formulaire de modification du mot de passe */}
                    {
                        this.state.editPWMode &&
                        <FIELD_RETYPE_PASS title="Changement du mot de passe"
                            APP_GLOBAL_DATA={this.props.APP_GLOBAL_DATA}
                            APP_GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} ref={this.refRePass} />
                    }
                </form>






                {/* Zon d'actions profil */}
                {
                    !this.state.editPWMode &&
                    <div className="POPUP_ACTION_ZONE">
                        <APP_BUTTON buttonProps={
                            {
                                title: "Enregistrer",
                                F_BUTTON_ACTION: () => {
                                    this.onValidateForm();
                                },
                            }
                        } />

                        <APP_BUTTON buttonProps={
                            {
                                title: "Modifier le mot de passe",
                                F_BUTTON_ACTION: () => {
                                    this.onEditMode()
                                },
                            }
                        } />

                        <APP_BUTTON buttonProps={
                            {
                                title: "Annuler",
                                hover_textColor: "#ffffff",
                                hover_backgroundColor: "rgb(209,30,9)",
                                F_BUTTON_ACTION: () => { (this.props.APP_GLOBAL_FUNCTION.onShowUserProfil as Function)() }
                            }
                        } />
                    </div>
                }



                {/* Zon d'actions mot de passe */}
                {
                    this.state.editPWMode &&
                    <div className="POPUP_ACTION_ZONE">
                        <APP_BUTTON buttonProps={
                            {
                                title: "Modifier le mot de passe",
                                F_BUTTON_ACTION: () => {
                                    this.onValidatePassWord();
                                },
                            }
                        } />
                        <APP_BUTTON buttonProps={
                            {
                                title: "Retour",
                                F_BUTTON_ACTION: () => {
                                    this.onEditMode();
                                },
                            }
                        } />
                        <APP_BUTTON buttonProps={
                            {
                                title: "Annuler",
                                hover_textColor: "#ffffff",
                                hover_backgroundColor: "rgb(209,30,9)",
                                F_BUTTON_ACTION: () => { (this.props.APP_GLOBAL_FUNCTION.onShowUserProfil as Function)() }
                            }
                        } />
                    </div>
                }

            </div>
        </div>
    }
}