import { HtmlEditor, Image, Link, Inject, QuickToolbar, RichTextEditorComponent, Toolbar } from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import { I_FIELD_AREA_PROPS, I_FIELD_AREA_PROPS_STATE } from "../../02_Interfaces/IFIELD";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { GetIconFromType } from "../../04_TOOLS/tools";

import "../../99_Styles/field.css";
export class FIELD_COMENT_BOX extends React.Component<I_FIELD_AREA_PROPS, I_FIELD_AREA_PROPS_STATE>{

    constructor(props: I_FIELD_AREA_PROPS) {
        super(props);
        this.state = {
            comentValue: "",
        }


        //Binding
        //=========
        this.OnSaveComent = this.OnSaveComent.bind(this);
        //=========
    }

    // Les références
    protected refComent = React.createRef<RichTextEditorComponent>();
    // ===============

    // Fonctions et méthodes utiles
    OnSaveComent  ()  {

        if (this.refComent.current !== null) {
            (this.props.APP_GLOBAL_FUNCTION.onComentNote as Function)(this.props.noteId, this.refComent.current.getText());

            this.setState({ comentValue: "" }, async () => {
                (this.props.PARENT_FUNCTION_DEFAULT as Function)();
                if (this.refComent.current !== null) {
                    this.refComent.current.updateValue("");
                    this.refComent.current.forceUpdate();
                }

                (this.props.PARENT_FUNCTION_DEFAULT as Function)();
            });
        }
    }

    componentDidMount(): void {
    }
    // =========================

    render(): JSX.Element {

        return <div className="FIELD_COMENT_ZONE">
            {
                this.props.showComents &&
                <form>
                    <div className="FIELD_AREA">
                        <RichTextEditorComponent enterKey="BR" ref={this.refComent}
                            toolbarSettings={
                                {
                                    // items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                                    //     'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                                    //     'LowerCase', 'UpperCase', '|',
                                    //     'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                                    //     'Outdent', 'Indent', '|',
                                    //     'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                                    //     'SourceCode', '|', 'Undo', 'Redo']
                                    items:[]
                                }
                            }

                            insertImageSettings={
                                {
                                    saveFormat: "Base64"
                                }
                            }
                        >
                            {/* INTERNAL MESSAGE */}
                            <div id="INTERNAL_MESSAGE"></div>
                            <p dangerouslySetInnerHTML={{ __html: this.state.comentValue }}></p>
                            <div id="INTERNAL_MESSAGE"></div>
                            {/* INTERNAL MESSAGE */}

                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                        {/* <textarea aria-multiline={true} className="FIELD_AREA_COMPONENT" placeholder={this.props.title} style={{ textAlign: "left" }} /> */}
                    </div>
                    <div className="APP_DEFAULT_ACTION_ZONE">
                        <div className="APP_DEFAULT_BUTTON" onClick={() => {
                            this.OnSaveComent();
                        }}>
                            <span>Envoyer</span>
                            <span className="APP_DEFAULT_BUTTON_ICON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.send, 16) }}></span>
                        </div>
                    </div>
                </form>
            }

        </div>
    }
}