import * as React from "react";
import "../../99_Styles/ACTION_INFO.css";
import { I_ACTION_TYPE_PROPS, I_ACTION_TYPE_PROPS_STATE } from "../../02_Interfaces/I_ACTION_INFO";
import { GetIconFromType } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE, I_KEY_VALUE_DATA } from "../../02_Interfaces/IGLOBAL";
import { unmountComponentAtNode } from "react-dom";
import { ORDO_PROCESS_ITEM } from "../../04_TOOLS/WASPORDO";

export class ACTION_INFO extends React.Component<I_ACTION_TYPE_PROPS, I_ACTION_TYPE_PROPS_STATE> {
    constructor(props: I_ACTION_TYPE_PROPS) {
        super(props);

        this.state = {
            notifReaded: []
        }

        this.onReadedNotif = this.onReadedNotif.bind(this);
    }

    onReadedNotif =  () => {
        // console.log(ORDO_PROCESS_ITEM.getInstance().ACTION_INFO);
        let allUnReadedNotif: I_KEY_VALUE_DATA[] = ORDO_PROCESS_ITEM.getInstance().ACTION_INFO.filter(ap => this.state.notifReaded.filter(nr => nr === (ap.key as string)).length === 0) //this.props.GLOBAL_DATA.actionsInfoPile.filter(ap => this.state.notifReaded.filter(nr => nr === (ap.key as string)).length === 0);
        let tmpNotifReaded: string[] = this.state.notifReaded;
        if (allUnReadedNotif.length > 0) {
            tmpNotifReaded.push(allUnReadedNotif[0].key as string);
            try {
                this.setState({ notifReaded: tmpNotifReaded });
            } catch (err) {
                console.log(err);
            } finally {
                // setTimeout(() => {
                //     this.onReadedNotif()
                // }, 4000)
                //-->
            }
        }


    }

    componentDidUpdate(prevProps: Readonly<I_ACTION_TYPE_PROPS>, prevState: Readonly<I_ACTION_TYPE_PROPS_STATE>, snapshot?: any): void {

    }

    componentDidMount(): void {
        setInterval(async () => {
            this.onReadedNotif()
        }, 4000)
    }

    render(): JSX.Element {
        return <div className={"TOOLTIPINFOBOX" + (ORDO_PROCESS_ITEM.getInstance().ACTION_INFO.length > 0 ? " TOOLTIP_OPOEN" : "")} style={{
            display:
            ORDO_PROCESS_ITEM.getInstance().ACTION_INFO
                    .filter(ap => this.state.notifReaded.filter(nr => nr === (ap.key as string)).length === 0).length > 0 ? "block" : "none"
        }}>
            {
                ORDO_PROCESS_ITEM.getInstance().ACTION_INFO
                    .filter(ap => this.state.notifReaded.filter(nr => nr === (ap.key as string)).length === 0)
                    .slice(0, 3)
                    .map((a, index) => {
                        return <div key={a.key + "-" + index}>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType((a.data as any) as ENU_ICON_TYPE, 11, "e1e1e1") }}></span>
                            <span className="SPAN_SEP"></span>
                            <span>{
                                a.value
                            }</span>
                        </div>
                    })
            }
        </div>
    }
}