import { I_RESULT } from "../02_Interfaces/IGLOBAL"
import { DB_APP_PARAM } from "../01_APPDATA/DB_APP"

export const T_GET_APP_PARAMS = (_data: any) => {
    _data({
        status: true,
        data: DB_APP_PARAM
    } as I_RESULT);
}

