import * as React from "react";
import { I_GROUP_BOX_ITEM_PROPS } from "../../../02_Interfaces/I_GROUP_BOX_ITEM";
import { GetIconFromType } from "../../../04_TOOLS/tools";
import { ENU_ICON_TYPE } from "../../../02_Interfaces/IGLOBAL";

export class GROUP_BOX_ITEM extends React.Component<I_GROUP_BOX_ITEM_PROPS, {}>{
    render(): JSX.Element {
        return <div title={this.props.groupItem.title} className={
            ((this.props.groupItem.selected as boolean && (this.props.GLOBAL_DATA.copyNoteMode || this.props.GLOBAL_DATA.selectedGroup === undefined)) || (this.props.forceSelected !== undefined && this.props.forceSelected) ?
                "FOLDER_BOX FOLDER_BOX_SELECTED " : "FOLDER_BOX ") + ((this.props.groupItem.nbTaskReminded as number) > 0 ? "FOLDER_BOX_TASK_REMENING" : (this.props.groupItem.nbTask as number) > 0 ? "FOLDER_BOX_TASK" : "")}
            key={this.props.groupItem.id}
            onClick={() => {
                if (this.props.GLOBAL_DATA.selectedGroup !== undefined && !this.props.GLOBAL_DATA.copyNoteMode) {
                    (this.props.GLOBAL_FUNCTION.selectGroupNote as Function)(this.props.groupItem);
                    (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false)
                } else if (this.props.GLOBAL_DATA.selectedGroup === undefined ||
                    (this.props.GLOBAL_DATA.selectedGroup !== undefined && this.props.GLOBAL_DATA.copyNoteMode)) {
                    (this.props.GLOBAL_FUNCTION.OnPreSelectFolder as Function)(this.props.groupItem.id);
                }

            }}

            onDoubleClick={() => {
                (this.props.GLOBAL_FUNCTION.selectGroupNote as Function)(this.props.groupItem)
            }}
            style={{ backgroundColor: (this.props.groupItem.title === "..." ? "#e1e1e1" : undefined) }}>


            {
                this.props.groupItem.selected &&
                <span className='FOLDER_BOX_SELECTED_ICON' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.checkedTask, 11, "FFFFFF") }}>

                </span>
            }

            {
                this.props.GLOBAL_DATA.allNotifs.filter(notif => notif.groupId === this.props.groupItem.id).length > 0 &&
                <span className='FOLDER_BOX_SELECTED_ICON' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.notif, 22, "ff0000") }} style={{ left: "20px" }}>

                </span>
            }

            {
                this.props.groupItem.authorLastname !== null && this.props.groupItem.authorName !== null &&
                <div className='FOLDER_AUTHOR_BOX' title={this.props.groupItem.authorLastname + " " + this.props.groupItem.authorName}>
                    {
                        this.props.groupItem.authorLastname[0].toUpperCase()
                    }
                    {
                        this.props.groupItem.authorName[0].toUpperCase()
                    }
                </div>
            }




            <div className={this.props.forceSelected ? "GN_ICON GN_ICON_SELECT" : "GN_ICON"} dangerouslySetInnerHTML={{
                __html:
                    GetIconFromType(ENU_ICON_TYPE.folder, this.props.GLOBAL_DATA.selectedGroup !== undefined && this.props.GLOBAL_DATA.selectedGroup.id === this.props.groupItem.id ? 75 : 24)
            }}>

            </div>
            <div className='FOLDER_TITLE' style={{
                fontSize: this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                    this.props.GLOBAL_DATA.selectedGroup.id === this.props.groupItem.id ? "13px" : "inherite"
            }} title={this.props.groupItem.title}>
                {
                    this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                        this.props.GLOBAL_DATA.selectedGroup.id === this.props.groupItem.id ?
                        this.props.groupItem.title.substring(0, 14) + (this.props.groupItem.title.length > 13 ? "..." : "") :
                        this.props.groupItem.title
                }
            </div>
            <div>
                {
                    (this.props.GLOBAL_DATA.DB_FAVORITS !== undefined && this.props.GLOBAL_DATA.DB_FAVORITS.filter(_g => _g.idPinedGroup === this.props.groupItem.id || _g.idGroup === this.props.groupItem.id).length > 0) &&
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.favorites, 16) }}></span>
                }

                {
                    this.props.groupItem.isAuthor === 1 &&
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.admin, 16) }}></span>
                }

                {
                    this.props.groupItem.isAuthor === 0 &&
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 16) }}></span>
                }

                {
                    this.props.GLOBAL_DATA.folderFilterMemories.filter(fm => this.props.groupItem.id === fm.folderId).length > 0 &&
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.save, 16,"EB6B16") }}></span>
                }
            </div>
            <div className='FOLDER_STATE_ZONE'>
                {

                    <span className='FOLDER_STATE_NB_NOTE'>{
                        // this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                        //     this.props.groupItem.id === this.props.GLOBAL_DATA.selectedGroup.id &&
                        //     this.props.GLOBAL_DATA.DB_NOTES ?

                        //     (
                        //         this.props.GLOBAL_DATA.DB_NOTES.length
                        //     ) :
                        this.props.groupItem.nbNotes !== undefined &&
                        this.props.groupItem.nbNotes

                    }</span>
                }

                {
                    this.props.groupItem.nbTask !== undefined &&
                    this.props.groupItem.nbTask > 0 &&
                    <span>
                        <span className='SPAN_SEP'></span>
                        <span className='FOLDER_TASK_COUNT'>{
                            // this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                            //     this.props.groupItem.id === this.props.GLOBAL_DATA.selectedGroup.id &&
                            //     this.props.GLOBAL_DATA.DB_TASKS !== undefined ?
                            //     (
                            //         this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished).length
                            //     ) :


                            this.props.groupItem.nbTask
                        }</span>
                    </span>
                }

                {
                    this.props.groupItem.nbTaskReminded !== undefined &&
                    this.props.groupItem.nbTaskReminded > 0 &&
                    <span>
                        <span className='SPAN_SEP'></span>
                        <span className='FOLDER_TASK_COUNT_REMEMMING'>{
                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                this.props.groupItem.id === this.props.GLOBAL_DATA.selectedGroup.id &&
                                this.props.GLOBAL_DATA.DB_TASKS !== undefined ?
                                (
                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.taskdate.length > 0).length
                                ) :
                                this.props.groupItem.nbTaskReminded

                        }</span>
                    </span>
                }
            </div>

            {
                //Affichage du bouton d'accès au carnet
                this.props.GLOBAL_DATA.selectedGroup === undefined &&
                <div className='FOLDER_DIRECT_ACCESS' onClick={() => {
                    (this.props.GLOBAL_FUNCTION.selectGroupNote as Function)(this.props.groupItem);
                }} title={"Accès direct au carnet '" + this.props.groupItem.title + "'"}>
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.see, 16, "ffffff") }}></span>
                </div>
            }

            {
                //Animation
                //------------
                (
                    this.props.GLOBAL_DATA.selectedGroup === undefined ||
                    (
                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                        this.props.GLOBAL_DATA.selectedGroup.id !== this.props.groupItem.id
                    )
                ) &&
                <div className="GROUP_ELEMENTS_ANIMATION">
                    <div className="ANIMATE_NOTE" style={{
                        backgroundColor: this.props.groupItem.nbTaskReminded !== undefined &&
                            this.props.groupItem.nbTaskReminded > 1 ? "#ff0000" : "#ffffff"
                    }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{ rotate: "33deg", top: "-3px" }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{
                        backgroundColor:
                            this.props.groupItem.nbTask !== undefined &&
                                this.props.groupItem.nbTask > 1 ? "#EB6B16" : "#ffffff"
                        , rotate: "38deg", top: "-5px"
                    }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{
                        rotate: "90deg", top: "-7px", backgroundColor: this.props.groupItem.nbTaskReminded !== undefined &&
                            this.props.groupItem.nbTaskReminded > 0 ? "#ff0000" : "#ffffff"
                    }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{ rotate: "-38deg", top: "-5px" }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{
                        rotate: "-25deg", top: "-3px", backgroundColor:
                            this.props.groupItem.nbTask !== undefined &&
                                this.props.groupItem.nbTask > 0 ? "#EB6B16" : "#ffffff"
                    }}>
                    </div>
                    <div className="ANIMATE_NOTE" style={{
                        rotate: "-45deg", backgroundColor: this.props.groupItem.nbTaskReminded !== undefined &&
                            this.props.groupItem.nbTaskReminded > 2 ? "#ff0000" : "#ffffff"
                    }}>
                    </div>
                </div>
            }

        </div>
    }
}