import * as React from "react";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_SEARCH_PROPS, I_SEARCH_PROPS_STATE } from "../../02_Interfaces/ISEARCHBOX";
import { GetIconFromType } from "../../04_TOOLS/tools";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";


export class SEARCHBOX extends React.Component<I_SEARCH_PROPS, I_SEARCH_PROPS_STATE>{
    constructor(props: I_SEARCH_PROPS) {
        super(props);
        this.state = {
            searchValue: ""
        }
    }

    private ref_input_search = React.createRef<HTMLInputElement>();

    onChangeSearchGlobal(e: React.FormEvent) {
        if ((e as React.KeyboardEvent).key === "Enter") {
            (this.props.F_UPDATE_SEARCHVALUE(this.state.searchValue));
        }
    }

    forceUpdateValue(value: string) {
        this.setState({ searchValue: value });
    }

    clearSearchValue() {

        this.setState({ searchValue: "" }, () => {
            this.props.F_UPDATE_SEARCHVALUE("");
            this.forceUpdateValue("");
            (this.props.APPFUNCTION.onShowLoader as Function)(false);
        });

    }

    onChangeSearchValue = (e: React.FormEvent) => {
        this.setState({ searchValue: (e.target as HTMLInputElement).value }, () => {
            if (this.state.searchValue.length === 0 || this.props.forceUpdateEveryTime) {

                (this.props.F_UPDATE_SEARCHVALUE(this.state.searchValue));

                if (this.state.searchValue.length === 0)
                    this.clearSearchValue();
            } else {
                (this.props.APPFUNCTION.onShowLoader as Function)(false);
            }
        });
    }

    render(): JSX.Element {
        return <div>
            <span>
                <input ref={this.ref_input_search} type={"text"} value={this.state.searchValue} placeholder={this.props.placeHolder}
                    style={{
                        fontStyle: this.state.searchValue.length === 0 ? "italic" : "normal",
                        textAlign: this.state.searchValue.length === 0 ? "center" : "left",
                        color: this.state.searchValue.length === 0 ? "#c3c3c3" : "#3c3c3c",
                        fontWeight: this.state.searchValue.length === 0 ? "normal" : "bold"
                    }}
                    onChange={(e) => {
                        this.onChangeSearchValue(e)
                    }}
                    onKeyUp={(e) => this.onChangeSearchGlobal(e)} />
            </span>

            &nbsp;
            {
                this.state.searchValue.length === 0 &&
                <span
                    style={{ cursor: (this.state.searchValue.length > 0 ? "pointer" : undefined) }}
                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.search, 16) }}>
                </span>
            }

            &nbsp;
            {
                this.state.searchValue.length > 0 &&
                <span onClick={() => {
                    if (this.ref_input_search.current !== undefined && this.ref_input_search.current !== null) {
                        this.ref_input_search.current.value = "";
                    }
                    this.forceUpdateValue("");
                    this.clearSearchValue();

                }} style={{ cursor: "pointer" }} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 14) }}></span>
            }


            {
                this.props.counter !== undefined &&
                <span>
                    <span className="SPAN_SEP"></span>
                    <span>{this.props.counter as number}</span>
                </span>
            }

        </div>
    }
}