import * as React from "react";
import { I_FIELD_CHECKBOX_PROPS, I_FIELD_CHECKBOX_PROPS_STATE } from "../../02_Interfaces/IFIELD";

export class FIELD_CHECK_BOX extends React.Component<I_FIELD_CHECKBOX_PROPS, I_FIELD_CHECKBOX_PROPS_STATE>{

    constructor(props: I_FIELD_CHECKBOX_PROPS) {
        super(props);

        this.state = {
            checked: this.props.defaultValue
        }

        this.onCheckBox = this.onCheckBox.bind(this);
        this.onForceUpdateValue = this.onForceUpdateValue.bind(this);
    }

    //Méthodes et fonctions utiles
    onForceUpdateValue(value: boolean) {
        this.setState({ checked: value });
    }

    onCheckBox() {
        this.setState({ checked: !this.state.checked }, async () => {
            if (this.props.F_Action !== undefined) {
                (this.props.F_Action as Function)(this.state.checked);
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<I_FIELD_CHECKBOX_PROPS>, prevState: Readonly<I_FIELD_CHECKBOX_PROPS_STATE>, snapshot?: any): void {

    }
    //===========================


    render(): JSX.Element {
        return <div onClick={() => this.onCheckBox()} className="FIELD_ZONE" style={{ cursor: "pointer" }}>
            <div className={"FIELD_LABEL"}>
                {this.props.title}
            </div>
            <div className="FIELD_INPUT" style={{ top: "7px" }}>
                <span className="FIELD_CB_ZONE">
                    <div className={
                        (
                            this.props.forceDefaultValue !== undefined &&
                                this.props.forceDefaultValue ?
                                this.props.defaultValue :
                                this.state.checked
                        ) ? "FIELD_CB_BUTTON FIELD_CB_BUTTON_SELECTED" : "FIELD_CB_BUTTON"} />
                </span>
                {
                    (this.props.showCheckText !== undefined && this.props.showCheckText)
                    || this.props.showCheckText === undefined &&
                    <span className={"FIELD_CB_VALUE_STRING"} style={{
                        color:
                            (
                                this.props.forceDefaultValue !== undefined &&
                                    this.props.forceDefaultValue ?
                                    this.props.defaultValue :
                                    this.state.checked
                            ) ? "#ec6104" : "initial"
                    }}>{
                            this.props.forceDefaultValue !== undefined &&
                                this.props.forceDefaultValue ?
                                this.props.defaultValue :
                                this.state.checked ? "ON" : "OFF"
                        }</span>
                }

            </div>
        </div>
    }
}