import * as React from "react";
import { ENU_ICON_TYPE, ENU_SHARE_TYPE } from "../../02_Interfaces/IGLOBAL";
import { I_GROUPE_NOTE_ITEM, I_NOTE_ITEM, I_TYPENOTE } from "../../02_Interfaces/INOTE";
import { I_SHARED_ITEM, I_SHARER_PROPS } from "../../02_Interfaces/ISHARER";
import { GetIconFromType } from "../../04_TOOLS/tools";

import "../../99_Styles/formGlobal.css";
import "../../99_Styles/sharer.css";
import { APP_BUTTON } from "../Components/BUTTON";
import { CONTEXTUEAL_MENU_ITEM } from "../Components/CONTEXTUAL_MENU";
import { SEARCHBOX } from "../Components/SEARCHBOX";
import { I_CONTACT_ITEM } from "../../02_Interfaces/ICONTACT";

export class SHARER_BOX extends React.Component<I_SHARER_PROPS, {}> {
    constructor(props: I_SHARER_PROPS) {
        super(props);
    }

    private ref_SearchContact = React.createRef<SEARCHBOX>();

    //Méthodes et fonctions utiles
    //============================
    onShareItem(idContact: number, canEdit: boolean, idTypeNote?: number, idNote?: number) {

        let tmpSharedItems: I_SHARED_ITEM[] = this.props.APP_GLOBAL_DATA.sharedItems as I_SHARED_ITEM[];

        if ((this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.groupe) /*|| this.props.showLight*/) {
            if (tmpSharedItems.filter(si => si.idGroup ===
                /*(this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id :*/ (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                si.idNote === null && si.idType === null && si.idContact === idContact).length > 0) {
                console.log(tmpSharedItems);
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (this.props.APP_GLOBAL_DATA.shareItemId as number),
                    idShare: tmpSharedItems.filter(si => si.idGroup === (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.APP_GLOBAL_DATA.shareItemId as number)) && si.idContact === idContact)[0].idShare,
                    idType: idTypeNote,
                    idNote: idNote,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onUpdateSharedItem as Function)(tmpShareConfig);
            } else {
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (/*this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id :*/ (this.props.APP_GLOBAL_DATA.shareItemId as number)),
                    idShare: -1,
                    idType: idTypeNote,
                    idNote: idNote,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onShareItem as Function)(tmpShareConfig);
            }
        } else if (this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.type) {
            if (tmpSharedItems.filter(si => si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && (si.idNote === null || si.idNote === undefined) && si.idType === (this.props.APP_GLOBAL_DATA.shareItemId as number) && si.idContact === idContact).length > 0) {
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id,
                    idShare: tmpSharedItems.filter(si => si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && (si.idNote === null || si.idNote === undefined) && si.idType === (this.props.APP_GLOBAL_DATA.shareItemId as number) && si.idContact === idContact)[0].idShare,
                    idType: (this.props.APP_GLOBAL_DATA.shareItemId as number),
                    idNote: undefined,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onUpdateSharedItem as Function)(tmpShareConfig);
            } else {
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id,
                    idShare: -1,
                    idType: (this.props.APP_GLOBAL_DATA.shareItemId as number),
                    idNote: undefined,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onShareItem as Function)(tmpShareConfig);
            }
        } else if (this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.note) {
            if (tmpSharedItems.filter(si => si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                si.idNote !== undefined &&
                si.idNote === (this.props.APP_GLOBAL_DATA.shareItemId as number)).length > 0) {
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id,
                    idShare: tmpSharedItems.filter(si => si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && si.idType !== null && si.idNote === (this.props.APP_GLOBAL_DATA.shareItemId as number))[0].idShare,
                    idNote: (this.props.APP_GLOBAL_DATA.shareItemId as number),
                    idType: (this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === this.props.APP_GLOBAL_DATA.shareItemId as number)[0].type,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onUpdateSharedItem as Function)(tmpShareConfig);
            } else {
                let tmpShareConfig: I_SHARED_ITEM = {
                    idContact: idContact,
                    idGroup: (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id,
                    idShare: -1,
                    idNote: (this.props.APP_GLOBAL_DATA.shareItemId as number),
                    idType: (this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === this.props.APP_GLOBAL_DATA.shareItemId as number)[0].type,
                    canEdit: canEdit
                };

                (this.props.APP_GLOBAL_FUNCTION.onShareItem as Function)(tmpShareConfig);
            }
        }
    }

    getSharedItem(uc: I_CONTACT_ITEM) {
        const sharedItems: I_SHARED_ITEM[] = this.props.APP_GLOBAL_DATA.sharedItems;
        let sharedItemId: number = -1;
        if ((this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.groupe) /*|| (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.APP_GLOBAL_DATA.shareItemId as number))*/) {
            sharedItemId = (sharedItems.filter(si => si.idContact === uc.idContact && si.idGroup === ( /*this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : */(this.props.APP_GLOBAL_DATA.shareItemId as number)) && si.idType === null && si.idNote === null)[0].idShare);
        } else if (this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.type) {
            sharedItemId = (sharedItems.filter(si => si.idContact === uc.idContact && si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && si.idType === (this.props.APP_GLOBAL_DATA.shareItemId as number) && si.idNote === null)[0].idShare);
        } else if (this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.note) {
            sharedItemId = (sharedItems.filter(si => si.idContact === uc.idContact && si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && si.idNote === (this.props.APP_GLOBAL_DATA.shareItemId as number) && si.idType !== null)[0].idShare);
        }


        return sharedItemId;
    }



    componentDidMount() {
        (this.props.APP_GLOBAL_FUNCTION.onLoadAllSharedItemsForUser as Function)();
    }
    //============================

    render(): JSX.Element {
        return <div>
            {/* font blanc non opaque  */}
            {
                !this.props.showLight &&
                <div className="POPUP_LAYER" style={{ opacity: 0.4 }}></div>

            }


            {
                this.props.showLight &&
                this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                this.props.APP_GLOBAL_DATA.shareType !== ENU_SHARE_TYPE.groupe &&
                <div className="PANEL_SHOW_BUTTON_ZONE">
                    <div className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                        this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                            (this.props.APP_GLOBAL_FUNCTION.onShowShareBox as Function)(ENU_SHARE_TYPE.groupe, this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM, true);
                    }}>
                        Afficher les autorisations du dossier sélectionné
                    </div>
                </div>
            }



            <div className={!this.props.showLight ? "POPUP_BODY_BOX" : "POPUP_BODY_BOX_VIEW"} style={{
                top: "20%", bottom: "20%",
                left: (this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.groupe) ? "40%" :
                    ((this.props.APP_GLOBAL_DATA.shareType !== undefined && this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.type) ? "20%" : "40%"),
                width: "350px"
            }}>
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 16, "#c3c3c3") }}></span>
                        {
                            (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.groupe) &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 16, "#c3c3c3") }}></span>
                                <span className="SPAN_SEP"></span>
                            </span>
                        }
                        {
                            (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.type) &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 16, "#c3c3c3") }}></span>
                                <span className="SPAN_SEP"></span>
                            </span>
                        }
                        {
                            (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.note) &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 16, "#c3c3c3") }}></span>
                                <span className="SPAN_SEP"></span>
                            </span>
                        }
                        &nbsp;
                        <span>
                            <div className="SHARER_TITLE_BOX">
                                {
                                    (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                        this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.groupe) &&
                                    " '" + (this.props.APP_GLOBAL_DATA.DB_GROUPS as I_GROUPE_NOTE_ITEM[]).filter(c => c.id === (/*this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : */(this.props.APP_GLOBAL_DATA.shareItemId as number)))[0].title + "'"
                                }

                                {
                                    (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                        this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.type) &&
                                    " '" + (this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]).filter(tn => tn.id === this.props.APP_GLOBAL_DATA.shareItemId as number)[0].title + "'"
                                }

                                {
                                    (this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                        this.props.APP_GLOBAL_DATA.shareType === ENU_SHARE_TYPE.note) &&
                                    " '" + (this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === this.props.APP_GLOBAL_DATA.shareItemId as number)[0].title.substring(0, 22) +
                                    ((this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === this.props.APP_GLOBAL_DATA.shareItemId as number)[0].title.length > 22 ? "..." : "") +
                                    "' "
                                }

                            </div>
                        </span>
                    </span>

                    {/* Bouton de fermeture de la popup */}
                    {
                        this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                        !this.props.showLight &&
                        <div
                            className="POPUP_CLOSE_BUTTON" onClick={() => { (this.props.APP_GLOBAL_FUNCTION.onShowShareBox as Function)() }}
                            dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18) }}>
                        </div>
                    }

                </div>


                {/* Corps de la popup */}
                <div className="POPUP_BODY">
                    {
                        this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                        <div style={{ textAlign: "center" }}>
                            <SEARCHBOX ref={this.ref_SearchContact} APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} placeHolder={"Mot clé ..."}
                                F_UPDATE_SEARCHVALUE={() => {
                                    (this.props.APP_GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                }}
                                forceUpdateEveryTime={true} />
                        </div>
                    }

                    <div>
                        {
                            this.props.APP_GLOBAL_DATA.userContacts.length > 0 &&
                            this.props.APP_GLOBAL_DATA.userContacts
                                .map(uc => {
                                    return <div key={uc.idContact}>
                                        {
                                            this.props.APP_GLOBAL_DATA.effectifProfils.filter(ep =>
                                                (ep.iduser === uc.idUser || ep.iduser === uc.idCurrentUser || ep.idCurrentUser === uc.idCurrentUser || ep.idCurrentUser === uc.idUser) &&
                                                ((this.ref_SearchContact.current !== null &&
                                                    this.ref_SearchContact.current.state.searchValue.length > 0) ?
                                                    ep.firstname.toUpperCase().indexOf(this.ref_SearchContact.current.state.searchValue.toUpperCase()) > -1 ||
                                                    ep.lastname.toUpperCase().indexOf(this.ref_SearchContact.current.state.searchValue.toUpperCase()) > -1 ||
                                                    ep.email.toUpperCase().indexOf(this.ref_SearchContact.current.state.searchValue.toUpperCase()) > -1
                                                    : true)
                                            ).length > 0 &&

                                            <span>
                                                <span className="SHARER_USER_NAME">
                                                    {
                                                        this.props.APP_GLOBAL_DATA.effectifProfils.filter(ep =>
                                                            (ep.iduser === uc.idUser || ep.iduser === uc.idCurrentUser || ep.idCurrentUser === uc.idCurrentUser || ep.idCurrentUser === uc.idUser)
                                                        ).length > 0 &&
                                                        <span>
                                                            {
                                                                this.props.APP_GLOBAL_DATA.effectifProfils.filter(ep => ep.iduser === uc.idUser || ep.iduser === uc.idCurrentUser || ep.idCurrentUser === uc.idCurrentUser || ep.idCurrentUser === uc.idUser)[0].firstname
                                                                + " " +
                                                                this.props.APP_GLOBAL_DATA.effectifProfils.filter(ep => ep.iduser === uc.idUser || ep.iduser === uc.idCurrentUser || ep.idCurrentUser === uc.idCurrentUser || ep.idCurrentUser === uc.idUser)[0].lastname
                                                            }
                                                        </span>
                                                    }
                                                </span>
                                                <span className="SHARER_USER_ACTION">
                                                    {/* Partage du carnet */}
                                                    {
                                                        ((this.props.APP_GLOBAL_DATA.shareType as ENU_SHARE_TYPE) === ENU_SHARE_TYPE.groupe || this.props.APP_GLOBAL_DATA.shareType === undefined) &&
                                                        this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                                                        <CONTEXTUEAL_MENU_ITEM
                                                            menus={[
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.see, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.APP_GLOBAL_DATA.shareItemId as number))) &&
                                                                            si.idType === null &&
                                                                            si.idNote === null &&
                                                                            !si.canEdit &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, false);
                                                                    },
                                                                    subMenu: false,
                                                                    description: "Lecture"
                                                                },
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.editor, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.APP_GLOBAL_DATA.shareItemId as number)) &&
                                                                            si.idType === null &&
                                                                            si.idNote === null &&
                                                                            si.canEdit &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, true);
                                                                    },
                                                                    subMenu: false,
                                                                    description: "Contribution"
                                                                }
                                                                ,
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.close, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.showLight ? (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id : (this.props.APP_GLOBAL_DATA.shareItemId as number)) &&
                                                                            si.idType === null &&
                                                                            si.idNote === null &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "FF0000" : "ffffff")),
                                                                    F_Menu_Action: () => {
                                                                        (this.props.APP_GLOBAL_FUNCTION.onDeleteSharedItem as Function)(this.getSharedItem(uc));
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Supprimer les autorisations"
                                                                }
                                                            ]}
                                                        />
                                                    }


                                                    {/* Partage du type de note */}
                                                    {
                                                        ((this.props.APP_GLOBAL_DATA.shareType as ENU_SHARE_TYPE) === ENU_SHARE_TYPE.type) &&
                                                        <CONTEXTUEAL_MENU_ITEM
                                                            menus={[
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.see, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idType as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                                                                            !si.canEdit &&
                                                                            (si.idNote === null || si.idNote === undefined) &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, false);
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Lecture"
                                                                },
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.editor, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idType as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                                                                            si.canEdit &&
                                                                            (si.idNote === null || si.idNote === undefined) &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, true);
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Contribution"
                                                                }
                                                                ,
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.close, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idType as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                                                                            (si.idNote === null || si.idNote === undefined) &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "FF0000" : "ffffff")),
                                                                    F_Menu_Action: () => {
                                                                        (this.props.APP_GLOBAL_FUNCTION.onDeleteSharedItem as Function)(this.getSharedItem(uc));
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Supprimer les autorisations"
                                                                }
                                                            ]}
                                                        />
                                                    }

                                                    {/* Partage de la note */}
                                                    {
                                                        ((this.props.APP_GLOBAL_DATA.shareType as ENU_SHARE_TYPE) === ENU_SHARE_TYPE.note) &&
                                                        <CONTEXTUEAL_MENU_ITEM
                                                            menus={[
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.see, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idNote as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                                                                            !si.canEdit &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, false);
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Lecture"
                                                                },
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.editor, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idNote as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number) &&
                                                                            si.canEdit &&
                                                                            si.idContact === uc.idContact
                                                                        )
                                                                        ).length > 0 ? "EB6B16" : undefined)
                                                                    ),
                                                                    F_Menu_Action: () => {
                                                                        this.onShareItem(uc.idContact, true);
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Contribution"
                                                                }
                                                                ,
                                                                {
                                                                    icon: GetIconFromType(ENU_ICON_TYPE.close, 16,
                                                                        (this.props.APP_GLOBAL_DATA.sharedItems.filter(si =>
                                                                        (
                                                                            si.idGroup === (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id &&
                                                                            (si.idNote as number) === (this.props.APP_GLOBAL_DATA.shareItemId as number)
                                                                        )
                                                                        ).length > 0 ? "FF0000" : "ffffff")),
                                                                    F_Menu_Action: () => {
                                                                        (this.props.APP_GLOBAL_FUNCTION.onDeleteSharedItem as Function)(this.getSharedItem(uc));
                                                                    },
                                                                    subMenu: false,
                                                                    description:"Supprimer les autorisations"
                                                                }
                                                            ]}
                                                        />
                                                    }
                                                </span>
                                            </span>
                                        }

                                    </div>
                                })
                        }
                    </div>
                </div>

                {/* Zone d'actions profil */}
                {
                    !this.props.showLight &&
                    this.props.APP_GLOBAL_DATA.shareType !== undefined &&
                    < div className="POPUP_ACTION_ZONE">
                        <APP_BUTTON buttonProps={
                            {
                                title: "Fermer",
                                F_BUTTON_ACTION: () => {
                                    (this.props.APP_GLOBAL_FUNCTION.onShowShareBox as Function)()
                                },
                            }
                        } />

                    </div>
                }
            </div>


        </div >
    }
}