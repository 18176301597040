//Toutes les images référencées

import { I_KEY_VALUE_DATA } from "../02_Interfaces/IGLOBAL";

export const IMG_PATH: string = "IMG/";

export enum ENU_IMG {
    ShowPendingTask,
    ShowNavigation,
    AddNewFolder,
    EditNewGroup,
    SaveNewGroup,
    FavoritsGroup,
    NoTypeNoteFound,
    NeedCreateTypeBefor,
    cantCreateNote,
    NeedUpdateTypeNote,
    SaveChangeOfTypeNote,
    BookStruct,
    CreateNewNote,
    AddTaskToNote,
    MarkTheNote,
    AddVideoToNote,
    NoResultFound,
    NewPublication,
    SetNotInLight,
    needFunctionalCookies,

    //CUPBOARD IMG-------------------------
    CUPBOARD_CLOSED,
    CUPBOARD_OPENED,
    //-------------------------------------

    //HELP IMG
    HELP_CREATE_FOLDER,
    HELP_EDIT_FOLDER,
    HELP_SET_IN_FAVORIS,
    HELP_SHOW_FOLDRR_FAVORIS,
    HELP_SHOW_ONLYMINE_FAVORIS,
    //-------------------------------------
};

//=============================
const DB_IMG: I_KEY_VALUE_DATA[] = [
    {
        key: ENU_IMG.ShowPendingTask,
        value: "Affichage des Taches Prioritaires ...",
        data: IMG_PATH + "SHOWPENDINGTASKS.png"
    },
    {
        key: ENU_IMG.ShowNavigation,
        value: "Affichage des dernières navigations ...",
        data: IMG_PATH + "SHOWLASTNAVITATION.png"
    },
    {
        key: ENU_IMG.AddNewFolder,
        value: "Organisez vos notes à l'aide des carnets...",
        data: IMG_PATH + "CREATENEWBOOK.png"
    },
    {
        key: ENU_IMG.EditNewGroup,
        value: "Editez le titre du carnet...",
        data: IMG_PATH + "EDITNEWBOOK.png"
    },
    {
        key: ENU_IMG.SaveNewGroup,
        value: "Sauvegardez les changements...",
        data: IMG_PATH + "SAVEBOOK.png"
    },
    {
        key: ENU_IMG.FavoritsGroup,
        value: "Mettez vos carnet favoris en évidence...",
        data: IMG_PATH + "SETFOLDERTOFAVORIT.png"
    },
    {
        key: ENU_IMG.NoTypeNoteFound,
        value: "Ajoutez un intercalaire...",
        data: IMG_PATH + "NOTYPENOTE.png"
    },
    {
        key: ENU_IMG.cantCreateNote,
        value: "Pour créer des notes, commencez par créer des intercalaires (types de note)...",
        data: IMG_PATH + "NOTETYPENOTEFOUND.png"
    },
    {
        key: ENU_IMG.NeedCreateTypeBefor,
        value: "Ajouter un intercalaire pour commencer ...",
        data: IMG_PATH + "NOTYPENOTEFORTAG.png"
    },
    {
        key: ENU_IMG.NeedUpdateTypeNote,
        value: "Ajustez le titre et la couleur",
        data: IMG_PATH + "NEEDUPDATETYPENOTE.png"
    },
    {
        key: ENU_IMG.SaveChangeOfTypeNote,
        value: "Sauvegardez les changements",
        data: IMG_PATH + "SAVECHANGEOFTYPENOTE.png"
    },
    {
        key: ENU_IMG.BookStruct,
        value: "Les intercalaires d'un carnet servent à organiser les notes. Ces dernières sont affichées dans la ligne de temps, de la plus récente à la plus ancienne...",
        data: IMG_PATH + "NONOTESFOUND.png"
    },
    {
        key: ENU_IMG.CreateNewNote,
        value: "Pour créer une note, cliquez sur le bouton 'Ajouter' dans le coins suppérieur gauche de la zone courante ....",
        data: IMG_PATH + "CREATENEWNOTE.png"
    },
    {
        key: ENU_IMG.AddTaskToNote,
        value: "Pour ajouter une tache à la note cliquez sur le bouton 'Nouvelle tache' situé sous la zone 'corps de la note' ....",
        data: IMG_PATH + "ADDTASKTONOTE.png"
    },
    {
        key: ENU_IMG.MarkTheNote,
        value: "Marquez les notes importantes ou favorites, à l'aide du menu contextuel de la note ....",
        data: IMG_PATH + "MARKTHENOTE.png"
    },
    {
        key: ENU_IMG.AddVideoToNote,
        value: "Marquez une vidéo dans la note à l'aide de la chaine : VDEO:[URL];",
        data: IMG_PATH + "ADDVIDEOTONOTE.png"
    },
    {
        key: ENU_IMG.NoResultFound,
        value: "Oups! on dirait qu'il n'y a rien ici ... un filtre à ajuster ?",
        data: IMG_PATH + "NORESULTFOUND.png"
    },
    {
        key: ENU_IMG.NewPublication,
        value: "Félicitation, vous êtes à jour :)",
        data: IMG_PATH + "NEWPUBLICATION.png"
    },
    {
        key: ENU_IMG.SetNotInLight,
        value: "Mettez la note en évidence à l'aide de son menu contextuel ! ",
        data: IMG_PATH + "SETINLIGHT.png"
    },
    {
        key: ENU_IMG.HELP_CREATE_FOLDER,
        value: "Créer un carnet pour y ranger tous vos intercalaires et vos notes ...",
        data: IMG_PATH + "HELP_CREAT_FOLDER.png"
    },
    {
        key: ENU_IMG.HELP_EDIT_FOLDER,
        value: "Editez le le carnet en le métant en mode 'Edition' ...",
        data: IMG_PATH + "HELP_EDIT_FOLDER.png"
    },
    {
        key: ENU_IMG.HELP_SET_IN_FAVORIS,
        value: "Mise du carnet en favoris ...",
        data: IMG_PATH + "HELP_SET_IN_FAVORIS_FOLDER.png"
    },
    {
        key: ENU_IMG.HELP_SHOW_FOLDRR_FAVORIS,
        value: "Affichage des carnets favoris ...",
        data: IMG_PATH + "HELP_SHOW_FAVORIS_FOLDER.png"
    },
    {
        key: ENU_IMG.HELP_SHOW_ONLYMINE_FAVORIS,
        value: "Affichage des carnets favoris ...",
        data: IMG_PATH + "HELP_SHOW_ONLY_MINE_FOLDER.png"
    },
    {
        key: ENU_IMG.CUPBOARD_CLOSED,
        value: "",
        data: IMG_PATH + "CUPBOARD_CLOSED.png"
    },
    {
        key: ENU_IMG.CUPBOARD_OPENED,
        value: "",
        data: IMG_PATH + "CUPBOARD_OPENED.png"
    },
    {
        key: ENU_IMG.needFunctionalCookies,
        value: "Les cookies de fonctionnement ne sont pas activé. Ces cookies sont nécessaire pour un fonctionnement optimal.",
        data: IMG_PATH + "COOKIESNEEDED.png"
    }
];


export const GETHELPER_IMG = (img: ENU_IMG) => {
    if (DB_IMG.filter(_img => _img.key === img).length > 0)
        return DB_IMG.filter(_img => _img.key === img)[0];
    else
        return undefined;
}


