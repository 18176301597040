import * as React from "react";
import { I_LOADER_PROPS, I_LOADER_PROPS_STATE } from "../../02_Interfaces/ILoader";
import "../../99_Styles/layoutLoader.css";

export class LAYOUT_LOADER extends React.Component<I_LOADER_PROPS, I_LOADER_PROPS_STATE>{

    constructor(props: I_LOADER_PROPS) {
        super(props);
        this.state = {
            opacity: 0
        }

    }


    render(): JSX.Element {
        return <div className="LAYOUTLOADER_ZONE" style={{ opacity: 1}}>
            <div className="LAYOUTLOADER_ZONE_BLANK_BG">
            </div>

            <div className="LAYOUTLOADER_ZONE_BODY">
                <span>{
                    this.props.message
                }</span>
            </div>
        </div>
    }
}