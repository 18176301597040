import * as React from "react";
import { I_BUTTON_PROPS } from "../../02_Interfaces/IBUTTON";
import { ENU_ICON_TYPE } from "../../02_Interfaces/IGLOBAL";
import { GetIconFromType } from "../../04_TOOLS/tools";

import "../../99_Styles/Button.css";
export class APP_BUTTON extends React.Component<I_BUTTON_PROPS, {}>{

    private ref_Button = React.createRef<HTMLDivElement>();

    onChangeBackground = (over: boolean) => {
        if (this.props.buttonProps.hover_backgroundColor !== undefined && this.ref_Button.current !== undefined) {
            (this.ref_Button.current as HTMLDivElement).style.backgroundColor = (!over ? "initial" : this.props.buttonProps.hover_backgroundColor);
        }

        if (this.props.buttonProps.hover_textColor !== undefined && this.ref_Button.current !== undefined) {
            (this.ref_Button.current as HTMLDivElement).style.color = (!over ? "initial" : this.props.buttonProps.hover_textColor);
        }
    }

    render(): JSX.Element {
        return <div
            style={{
                color: this.props.buttonProps.textColor !== undefined ? this.props.buttonProps.textColor : "initial",
                backgroundColor: this.props.buttonProps.backgroundColor as string
            }}
            className="BUTTON_ZONE"
            onMouseOut={() => this.onChangeBackground(false)}
            onMouseOver={() => this.onChangeBackground(true)}
            onClick={()=>this.props.buttonProps.F_BUTTON_ACTION()}
            ref={this.ref_Button}
            title={this.props.buttonProps.message!==undefined?this.props.buttonProps.message:undefined}>
            
            <span>{
                this.props.buttonProps.title
            }</span>

            {
                this.props.buttonProps.message!==undefined &&
                <span dangerouslySetInnerHTML={{__html:GetIconFromType(ENU_ICON_TYPE.information,16,"000000")}}></span>
            }
        </div>
    }
}